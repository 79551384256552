import { Button, Popconfirm, Table, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../../apis";
import Pagination from "../../../../components/pagination";
import {
  DeleteIcon,
  EditIcon,
  PropsFilter,
  RenderTable,
} from "../../../../config";
import AddCaralog from "./lib/add";
import { useHistory, useLocation } from "react-router-dom";
import { Wrapper } from "./style";

const CantentAccessCatelog = () => {
  const history = useHistory();
  const location = useLocation();

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [selectedRow, setSelectedRow] = useState({});
  const [visible, setVisible] = useState(false);

  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);

  const [catalogList, setCatalogList] = useState([]);
  const [catalogLoading, setCatalogLoading] = useState(true);

  const [catalogLocationList, setCatalogLocationList] = useState([]);
  const [catalogLocationLoading, setCatalogLocationLoading] = useState(true);

  const [locationList, setLocationList] = useState([]);
  const [locationLoading, setLocationLoading] = useState(true);

  const [sortFilters, setSortFilters] = useState({
    field_name: "menu_item",
    sort_by: "asc",
  });

  /**
   * This function groups the data by menu_item and sorts it alphabetically by menu_item.
   * It then creates a mapping of the first letter of each menu_item to an array of data.
   * Finally, it sorts the data within each group by location_name and returns a flattened array.
   *
   * @param {Array<Object>} data - The data to group and sort.
   * @return {Array<Object>} The flattened array of data sorted by location_name.
   */
  const makeAData = (data) => {
    // Group the data by menu_item
    const groupedData = data.reduce((acc, item) => {
      (acc[item.menu_item] = acc[item.menu_item] || []).push(item);
      return acc;
    }, {});

    // Sort the groups alphabetically by menu_item
    const sortedGroups = Object.keys(groupedData)
      .sort()
      .reduce((acc, key) => {
        acc[key] = groupedData[key];
        return acc;
      }, {});

    // Create an object to store the groups
    const groups = {};

    // Initialize groups A to Z
    for (let i = 65; i <= 90; i++) {
      groups[String.fromCharCode(i)] = [];
    }
    groups[""] = [];

    // Group the data by the first letter of the menu_item
    Object.entries(sortedGroups).forEach(([menuItem, contents], i) => {
      const firstLetter = menuItem.charAt(0).toUpperCase();
      console.log(firstLetter === "", "firstLetter");

      if (groups[firstLetter]) {
        groups[firstLetter] = Object?.values(
          [...groups[firstLetter], ...contents].flat() || []
        );
      }
    });

    // Remove empty groups
    Object.keys(groups).forEach((key) => {
      if (groups[key].length === 0) {
        delete groups[key];
      }
    });

    // Sort the data within each group by location_name
    const sortByLocationName = Object.entries(groups || {}).reduce(
      (acc, [key, value]) => {
        acc[key] = value?.sort((a, b) => {
          return a.location_name.localeCompare(b.location_name);
        });

        return acc;
      },
      {}
    );

    // Flatten the array and return it
    return Object.values(sortByLocationName || {}).flat();
  };

  const getList = async () => {
    const response = await MakeApiCall(
      `content-access-mapping?isAll=1`,
      "get",
      null,
      true
    );

    if (response?.status) {
      console.log(
        response?.data.records,
        makeAData(response?.data.records || []),
        "response?.data.records"
      );

      setList(makeAData(response?.data.records || []));

      setLoading(false);
    } else {
      // setTotalPage(0);
      setLoading(false);
      message.warning(response?.message);
    }
  };
  const getCatalogList = async (id, status, e) => {
    setCatalogLoading(true);
    const response = await MakeApiCall(
      `content-catalog?isAll=1&field_name=title&sort_by=asc${
        status
          ? ""
          : `&content_location_catalog_id=${e ? "" : id}&metric_id=${e}`
      }`,
      "get",
      null,
      true
    );
    if (response?.status) {
      setCatalogList(response?.data?.records || []);
      setCatalogLoading(false);
    } else {
      setCatalogLoading(false);
      message.warning(response?.message);
    }
  };
  const getLocationCatalogList = async () => {
    setCatalogLocationLoading(true);
    const response = await MakeApiCall(
      `content-location-catalog?isAll=1&status=1`,
      "get",
      null,
      true
    );
    if (response?.status) {
      setCatalogLocationList(response?.data?.records || []);

      setCatalogLocationLoading(false);
    } else {
      setCatalogLocationLoading(false);
      message.warning(response?.message);
    }
  };
  const getLocationList = async (data) => {
    setLocationLoading(true);
    const response = await MakeApiCall(`location?isAll=1`, "get", null, true);

    if (response?.status) {
      setLocationList(response?.data?.records || []);

      setLocationLoading(false);
    } else {
      setTotalPage(0);
      setLocationLoading(false);
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    getList({ ...sortFilters });
    setLocationLoading(true);
    getLocationList();

    // getCatalogList();
    getLocationCatalogList();
    return () => {};
  }, []);

  const sortType = {
    descend: "desc",
    ascend: "asc",
  };

  const DefaultSortType = {
    desc: "descend",
    asc: "ascend",
  };

  // const handleChange = (_, __, sorter) => {
  //   const obj = {
  //     field_name: sortType?.[sorter?.order] ? sorter?.column?.filterIndex : "",
  //     sort_by: sortType?.[sorter?.order] || "",
  //   };
  //   setSortFilters(obj);
  //   getList({ ...obj });
  // };

  const columns = [
    {
      title: "ID",
      align: "center",
      render: (_, __, i) => {
        return <span>{(page - 1) * pageSize + 1 + i}</span>;
      },
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <span>
          <a onClick={() => handleEdit(record)} className=" me-2">
            {EditIcon}
          </a>
          <Popconfirm
            title="Delete the catalog"
            description="Are you sure to delete this catalog?"
            okText="Yes"
            onConfirm={() => handleDelete(record.id)}
            placement="left"
            cancelText="No"
          >
            <a href="#" className=" me-2">
              {DeleteIcon}
            </a>
          </Popconfirm>
        </span>
      ),
    },
    {
      title: "Content Location Name",
      filterIndex: "menu_item",
      render: (e) => {
        return (
          <span>
            {e?.menu_item || e?.metric_name || "-"}&nbsp;-&nbsp;
            {e?.location_name || e?.content_catalog}
            <>
              {e?.content_location_catalog ? (
                <>&nbsp;-&nbsp;{e?.content_location_catalog}</>
              ) : (
                ""
              )}
            </>
          </span>
        );
      },
    },
    {
      title: "Content Catalog Name",
      filterIndex: "content_catalog",
      render: (e) => {
        return (
          <span>
            {/* {e?.content_catalog_id}&nbsp;-&nbsp; */}
            {e?.content_type === "1" ? "Info" : "Video"}&nbsp;-&nbsp;
            {e?.content_catalog}
          </span>
        );
      },
    },
    {
      title: "Created (ET)",
      dataIndex: "created_at",
      key: "created_at",
      filterIndex: "created_at",
      render: (e) => {
        return moment(new Date(parseInt(e) * 1000))
          .tz("America/New_York")
          .format("MM/DD/YYYY hh:mm A");
      },
    },
    {
      title: "Last Updated (ET)",
      dataIndex: "updated_at",
      key: "updated_at",
      filterIndex: "updated_at",
      render: (e) => {
        return moment(new Date(parseInt(e) * 1000))
          .tz("America/New_York")
          .format("MM/DD/YYYY hh:mm A");
      },
    },
    // {
    //   title: "Created by",
    //   dataIndex: "created_by",
    //   key: "created_by",
    // },
    // {
    //   title: "Modified By",
    //   dataIndex: "updated_by",
    //   key: "updated_by",
    // },
  ];

  // Function to handle the Add button
  const handleAdd = () => {
    history.push(
      `${location?.pathname}${location?.search}${
        location?.search ? "&" : "?"
      }viewType=add`
    );
  };
  // Functions to handle edit and delete actions
  const handleEdit = (row) => {
    history.push(
      `${location?.pathname}${location?.search}${
        location?.search ? "&" : "?"
      }viewType=edit&id=${row?.id}`
    );
  };

  const deleteRow = async (id) => {
    const response = await MakeApiCall(
      `content-access-mapping/${id}`,
      "delete",
      null,
      true
    );

    if (response?.status) {
      message.destroy();
      setList((pre) => pre.filter((d) => d?.id !== id));
      message.success(response?.message);
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };

  const handleDelete = (id) => {
    message.loading("Loading...", 0);

    // Implement your delete logic here
    deleteRow(id);
  };

  const pageType = new URLSearchParams(location.search)?.get("viewType");
  const selectedID = new URLSearchParams(location.search)?.get("id");

  const updateUrl = (url) => {
    const urlObj = new URL("http://example.com" + url);
    urlObj.searchParams.delete("viewType");
    urlObj.searchParams.delete("id");
    return urlObj.pathname + urlObj.search;
  };

  const updatedUrl = updateUrl(`${location?.pathname}${location?.search}`);

  if (pageType) {
    return (
      <AddCaralog
        // visible={visible}
        setList={setList}
        catalogList={catalogList}
        catalogLoading={catalogLoading}
        locationList={locationList}
        locationLoading={locationLoading}
        catalogLocationList={catalogLocationList}
        catalogLocationLoading={catalogLocationLoading}
        // selectedRow={selectedRow}
        pageType={pageType}
        id={selectedID}
        getCatalogList={(e, r, f) => {
          setCatalogList([]);
          getCatalogList(e, r, f);
        }}
        setVisible={() => {
          // setVisible(false);
          history.push(updatedUrl);
          // setSelectedRow({});
          setLoading(true);
          getList({ ...sortFilters });
        }}
      />
    );
  }

  return (
    <Wrapper id="kt_content_container" className="custom-ui">
      <div className="row g-2">
        <div className="col-xxl-12">
          <div className="card card-xxl-stretch">
            <div className="card-header">
              <div className="card-title">Content Access Mapping</div>
              <div className="card-toolbar">
                <a onClick={handleAdd} className="add-btn ">
                  <svg
                    width={16}
                    height={16}
                    className="me-2"
                    fill="#ffffff"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M15 2.016H9v6.987H2v6h7v6.987h6v-6.987h7v-6h-7V2.016Z" />
                  </svg>
                  Add
                </a>
              </div>
            </div>
            <div className="card-body pt-0 pb-0">
              {console.log(list, "list")}
              <Table
                dataSource={list}
                // onChange={handleChange}
                fixed={true}
                sticky={{
                  offsetHeader: "0px",
                }}
                columns={columns?.map((d) => ({
                  ...d,
                  ...(d?.filterIndex ? PropsFilter(d?.filterIndex) : ""),
                  render: (props, row, index) =>
                    RenderTable(props, row, index, d),
                }))}
                loading={loading}
                pagination={{
                  size: "default",
                  showSizeChanger: true,
                }}
                rowClassName={(record, index) => {
                  return index % 2 === 0 ? "even-row" : "odd-row";
                }}
                bordered
                size="small"
                scroll={{ x: "max-content" }}
              />
            </div>
            <div className="card-footer d-none pt-0 pb-5">
              {/* <Pagination
                loading={loading}
                pageSize={pageSize}
                page={page}
                totalPage={totalPage}
                onPerPage={(e) => {
                  setLoading(true);
                  setPageSize(e);
                  setPage(1);
                  getList({
                    page: 1,
                    pageSize: e,
                    ...sortFilters,
                  });
                }}
                onPageNo={(e) => {
                  setLoading(true);
                  setPage(e);
                  getList({
                    page: e,
                    ...sortFilters,
                  });
                }}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default CantentAccessCatelog;
