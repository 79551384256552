import {
  Breadcrumb,
  Button,
  Dropdown,
  Input,
  Menu,
  Popconfirm,
  Spin,
  Table,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import AddCouponCodeManager from "./lib/add";
import { Wrapper } from "./style";
import { MakeApiCall } from "../../../apis";
import Pagination from "../../../components/pagination";
import { DeleteIcon, EditIcon, PropsFilter } from "../../../config";

const CouponCodeManager = () => {
  const [addView, setAddView] = useState(false);

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [selectedRow, setSelectedRow] = useState({});

  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);

  const [search_key, setSearch_key] = useState("");

  const [sortFilters, setSortFilters] = useState({
    field_name: null,
    sort_by: null,
  });

  const menu = (
    <Menu>
      <Menu.Item key="1">Copy</Menu.Item>
      <Menu.Item key="2">CSV</Menu.Item>
      <Menu.Item key="3">Excel</Menu.Item>
      <Menu.Item key="4">PDF</Menu.Item>
      <Menu.Item key="5">Print</Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      align: "center",
      key: "id",
      render: (text, record, index) => (page - 1) * pageSize + 1 + index,
    },
    {
      title: "Action",
      align: "center",
      render: (d, record) => (
        <div className="actions">
          <div className="d-flex justify-content-center flex-shrink-0 ">
            <a
              onClick={() => {
                setSelectedRow(d);
                setAddView(true);
              }}
              className=" me-2"
            >
              {EditIcon}
            </a>
            <Popconfirm
              title="Delete the Coupon code"
              description={`Are you sure to delete this ${d?.coupon_code}?`}
              placement="left"
              onConfirm={() => {
                removeRow(d?.id);
              }}
              okText="Yes"
            >
              <a href="#" className=" me-2">
                {DeleteIcon}
              </a>
            </Popconfirm>
          </div>
        </div>
      ),
    },
    {
      title: "Partner name",
      dataIndex: "partner_name",
      key: "partner_name",
      filterIndex: "partner_name",
      render: (text) => text || "-",
    },
    {
      title: "Coupon code",
      dataIndex: "coupon_code",
      key: "coupon_code",
      filterIndex: "coupon_code",
      render: (text) => text || "-",
    },
    {
      title: "Amount (%)",
      dataIndex: "coupon_amount",
      key: "coupon_amount",
      align: 'right',
      filterIndex: "coupon_amount",
      render: (text) => `${text || 0}`,
    },
    {
      title: "Applies to",
      dataIndex: "applies_to",
      key: "applies_to",
      filterIndex: "applies_to",
    },
    {
      title: "Expires",
      dataIndex: "expiry_date",
      key: "expiry_date",
      filterIndex: "expiry_date",
    },
  ];

  const getList = async (data) => {
    // `coupon-code?page=${data?.page || page || 1}&per-page=${
    //   data?.pageSize || pageSize
    // }&field_name=${data?.field_name || ""}&sort_by=${
    //   data?.sort_by || ""
    // }&coupon_code=${data?.search_key || ""}`,
    const response = await MakeApiCall(
      `coupon-code?isAll=1`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setList(response?.data || []);
      // setTotalPage(response?.data?.pagination?.totalCount || 0);
      setLoading(false);
    } else {
      // setTotalPage(0);
      setLoading(false);
      message.warning(response?.message);
    }
  };
  const addRow = async (data) => {
    const response = await MakeApiCall(`coupon-code`, "post", data, true);

    if (response?.status) {
      setPage(1);
      getList({ page: 1, search_key });
      setSelectedRow({});
      setAddView(false);
    } else {
      setSelectedRow({});
      setAddView(false);
      message.warning(response?.message);
    }
  };
  const editRow = async (data) => {
    const response = await MakeApiCall(
      `coupon-code/${selectedRow?.id}`,
      "put",
      data,
      true
    );

    if (response?.status) {
      setPage(1);
      getList({ page: 1, search_key });
      setSelectedRow({});
      setAddView(false);
    } else {
      setSelectedRow({});
      setAddView(false);
      message.warning(response?.message);
    }
  };
  const removeRow = async (id) => {
    const response = await MakeApiCall(
      `coupon-code/${id}`,
      "delete",
      null,
      true
    );

    if (response?.status) {
      setPage(1);
      setLoading(true);
      getList({
        page: 1,
        search_key,
      });
    } else {
      message.error(response?.message);
    }
  };

  // const sortType = {
  //   descend: "desc",
  //   ascend: "asc",
  // };
  // const DefaultSortType = {
  //   desc: "descend",
  //   asc: "ascend",
  // };
  // const PropsFilter = (type) => ({
  //   defaultSortOrder:
  //     type === sortFilters?.field_name
  //       ? DefaultSortType?.[sortFilters?.sort_by]
  //       : [],
  //   sorter: () => {},
  // });
  // const handleChange = (_, __, sorter) => {
  //   const obj = {
  //     field_name: sortType?.[sorter?.order] ? sorter?.column?.filterIndex : "",
  //     sort_by: sortType?.[sorter?.order] || "",
  //   };
  //   setSortFilters(obj);
  //   getList({ ...obj, search_key });
  // };

  const ClearSearch = async () => {
    setLoading(true);
    setSearch_key("");
    getList({ ...sortFilters, search_key: "" });
  };

  useEffect(() => {
    setLoading(true);
    getList({ search_key });
    return () => {};
  }, []);

  return (
    <Wrapper
      id="kt_content_container"
      className="container-fluid custom-ui mt-5"
    >
      <div className="card">
        <div className="card-header">
          <div className="card-title">
            <Input
              size="middle"
              style={{ width: "200px" }}
              placeholder="Search by Coupon code..."
              allowClear
              onChange={(e) => {
                setSearch_key((prev) => {
                  if (!e.target.value && prev) {
                    ClearSearch();
                  }
                  return e.target.value;
                });
              }}
            />
            <button
              onClick={() => {
                setLoading(true);
                getList({ ...sortFilters, search_key });
              }}
              className="btn btn-sm ms-3 btn-search"
            >
              Search
            </button>
          </div>
          <div className="card-toolbar">
            <Dropdown overlay={menu} placement="bottom" arrow>
              <div
                style={{
                  background: "#00B660",
                  borderRadius: 6,
                  padding: "6px 15px",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    color: "#FAFAFB",
                    fontSize: 13,

                    fontWeight: 500,
                    letterSpacing: 0.1,
                    wordWrap: "break-word",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={20}
                    height={20}
                    viewBox="0 0 24 24"
                    fill="none"
                    className="me-3"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M13 18.9656L13.3892 18.6653C13.8265 18.328 14.4544 18.409 14.7918 18.8463C15.1291 19.2836 15.0481 19.9115 14.6108 20.2489L12.6277 21.7787C12.5675 21.8274 12.5025 21.8682 12.4342 21.901C12.3808 21.928 12.309 21.9545 12.234 21.9725C12.1375 21.9951 12.0448 22.0033 11.953 21.9989C11.812 21.9924 11.6786 21.9567 11.5588 21.8976C11.4931 21.8654 11.4305 21.8257 11.3723 21.7787L9.38919 20.2489C8.9519 19.9115 8.87088 19.2836 9.20823 18.8463C9.54557 18.409 10.1735 18.328 10.6108 18.6653L11 18.9656V8.45711C11 7.90482 11.4477 7.45711 12 7.45711C12.5523 7.45711 13 7.90482 13 8.45711V18.9656ZM12.5 2C15.0609 2 17.2376 3.76105 17.8369 6.17236C20.2436 6.77356 22 8.95407 22 11.518C22 14.3327 19.8828 16.6868 17.1101 16.9939C16.5612 17.0547 16.0669 16.659 16.0061 16.1101C15.9453 15.5612 16.341 15.0669 16.8899 15.0061C18.6514 14.8109 20 13.3114 20 11.518C20 9.71741 18.6408 8.21401 16.8706 8.02783C16.4009 7.97843 16.0298 7.60718 15.9806 7.13748C15.7947 5.36183 14.2947 4 12.5 4C10.7037 4 9.20292 5.36415 9.01891 7.14171C8.96154 7.69596 8.46222 8.0964 7.90874 8.03205C7.77394 8.01638 7.63757 8.00847 7.5 8.00847C5.56748 8.00847 4 9.57926 4 11.518C4 13.3114 5.34862 14.8109 7.11011 15.0061C7.65903 15.0669 8.05473 15.5612 7.99392 16.1101C7.93311 16.659 7.43882 17.0547 6.88989 16.9939C4.11715 16.6868 2 14.3327 2 11.518C2 8.57497 4.30383 6.17018 7.20435 6.01629C7.85706 3.68561 9.99449 2 12.5 2Z"
                      fill="white"
                    />
                  </svg>
                  Export
                </div>
              </div>
            </Dropdown>

            <a
              className="add-btn ms-3"
              onClick={() => {
                setAddView(true);
                setSelectedRow({});
              }}
            >
              <svg
                width={16}
                height={16}
                className="me-2"
                fill="#ffffff"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M15 2.016H9v6.987H2v6h7v6.987h6v-6.987h7v-6h-7V2.016Z" />
              </svg>
              Add Coupon
            </a>
          </div>
        </div>
        <div className="card-body pt-0">
          {loading ? (
            <div className="h-200px d-flex align-items-center justify-content-center">
              <Spin />
            </div>
          ) : (
            <div className="table-responsive">
              <Table
                columns={columns?.map((d) => ({
                  ...d,
                  ...(d?.filterIndex ? PropsFilter(d?.filterIndex) : ""),
                }))}
                className="mt-5"
                dataSource={list}
                rowClassName={(record, index) => {
                  return index % 2 === 0 ? "even-row" : "odd-row";
                }}
                bordered
                size="small"
                scroll={{ x: "max-content" }}
                rowKey="id"
                pagination={{
                  size: "default",
                  showSizeChanger: true,
                }}
              />
            </div>
          )}

          {/* <Pagination
            loading={loading}
            pageSize={pageSize}
            page={page}
            totalPage={totalPage}
            onPerPage={(e) => {
              setLoading(true);
              setPageSize(e);
              setPage(1);
              getList({
                page: 1,
                pageSize: e,
                ...sortFilters,
                search_key,
              });
            }}
            onPageNo={(e) => {
              setLoading(true);
              setPage(e);
              getList({
                page: e,
                ...sortFilters,
                search_key,
              });
            }}
          /> */}
        </div>
      </div>
      {addView && (
        <AddCouponCodeManager
          visible={addView}
          selectedRow={selectedRow}
          onCancel={() => setAddView(false)}
          onSubmit={(e) => {
            if (Object.keys(selectedRow).length === 0) {
              addRow(e);
            } else {
              editRow(e);
            }
          }}
        />
      )}
    </Wrapper>
  );
};

export default CouponCodeManager;
