import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Table,
  Segmented,
  theme,
  message,
  Dropdown,
  Alert,
  DatePicker,
  Input,
  Tooltip,
  Modal,
  Empty,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { MakeApiCall } from "../../../../apis";
import Pagination from "../../../../components/pagination";
import { TableLoading } from "../../../../components/table-animation";
import { ENDPOINT, NumberWithCommas, RenderTable } from "../../../../config";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import moment from "moment";
import InfoVideo from "../../../../components/header-icons";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import Icons from "../../../../components/icons";
import {
  DeleteCModal,
  ErrorModal,
  OnOkDeleteModal,
  SuccessModal,
} from "../../../../components/upload-modals";
import DatePickerView from "./date-picker-view";
const { useToken } = theme;
dayjs.extend(quarterOfYear);
const Sqp = ({ locationData }) => {
  const CommaAndFloat = (e) => {
    return (e || 0).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };
  const Comma = (e) => {
    return (e || 0).toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  const brand_columns = [
    {
      title: "Year",
      dataIndex: "select_year",
      key: "select_year",
    },
    {
      title: "Week Number",
      dataIndex: "week_number",
      key: "week_number",
      align: "right",
      width: 160,
      render: (e) => {
        return e;
      },
    },

    {
      title: "Month Number",
      dataIndex: "month_number",
      key: "month_number",
      width: 160,
      render: (e) => {
        return e || "-";
      },
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      render: (e) => {
        return e || "-";
      },
    },
    {
      title: "Search Query",
      dataIndex: "search_query",
      key: "search_query",
      width: 400,
      render: (e) => {
        return (
          <div
            title={e}
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              width: "400px",
            }}
          >
            {e}
          </div>
        );
      },
    },
    {
      title: "Search Query Score",
      dataIndex: "search_query_score",
      key: "search_query_score",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Search Query Volume",
      dataIndex: "searchQueryVolume",
      key: "searchQueryVolume",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Impressions: Total Count",
      dataIndex: "search_query_volume",
      key: "search_query_volume",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Impressions: Brand Count",
      dataIndex: "impression_brand_count",
      key: "impression_brand_count",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Impressions: Brand Share %",
      dataIndex: "impression_brand_share_percentage",
      key: "impression_brand_share_percentage",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Clicks: Total Count",
      dataIndex: "clicks_total_count",
      key: "clicks_total_count",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Clicks: Click Rate (%)",
      dataIndex: "clicks_rate_percentage",
      key: "clicks_rate_percentage",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "SIP IQ Score",
      dataIndex: "sip_iq_score",
      key: "sip_iq_score",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Clicks: Brand Count",
      dataIndex: "clicks_brand_count",
      key: "clicks_brand_count",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Clicks: Brand Share %",
      dataIndex: "clicks_brand_share_percentage",
      key: "clicks_brand_share_percentage",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Clicks: Price (Median)",
      dataIndex: "clicks_price_median",
      key: "clicks_price_median",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Clicks: Brand Price (Median)",
      dataIndex: "clicks_brand_price_median",
      key: "clicks_brand_price_median",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Clicks: Same Day Shipping Speed",
      dataIndex: "clicks_same_day_shipping_speed",
      key: "clicks_same_day_shipping_speed",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Clicks: 1D Shipping Speed",
      dataIndex: "clicks_1d_shipping_speed",
      key: "clicks_1d_shipping_speed",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Clicks: 2D Shipping Speed",
      dataIndex: "clicks_2d_shipping_speed",
      key: "clicks_2d_shipping_speed",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "SIP Click Score",
      dataIndex: "sip_click_score",
      key: "sip_click_score",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Cart Adds: Total Count",
      dataIndex: "cart_adds_total_count",
      key: "cart_adds_total_count",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Cart Adds: Cart Add Rate (%)",
      dataIndex: "cart_add_rate_percentage",
      key: "cart_add_rate_percentage",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Cart Adds: Brand Count",
      dataIndex: "cart_add_brand_count",
      key: "cart_add_brand_count",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Cart Adds: Brand Share (%)",
      dataIndex: "cart_add_brand_share_percentage",
      key: "cart_add_brand_share_percentage",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Cart Adds: Price (Median)",
      dataIndex: "cart_add_price_median",
      key: "cart_add_price_median",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Cart Adds: Brand Price (Median)",
      dataIndex: "cart_adds_brand_price_median",
      key: "cart_adds_brand_price_median",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Cart Adds: Same Day Shipping Speed",
      dataIndex: "cart_adds_same_day_shipping_speed",
      key: "cart_adds_same_day_shipping_speed",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Cart Adds: 1D Shipping Speed",
      dataIndex: "clicks_1d_shipping_speed",
      key: "clicks_1d_shipping_speed",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Cart Adds: 2D Shipping Speed",
      dataIndex: "clicks_2d_shipping_speed",
      key: "clicks_2d_shipping_speed",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "SIP Cart Score",
      dataIndex: "sip_cart_score",
      key: "sip_cart_score",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Purchases: Total Count",
      dataIndex: "purchases_total_count",
      key: "purchases_total_count",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Purchases: Purchase Rate (%)",
      dataIndex: "purchase_rate_percentage",
      key: "purchase_rate_percentage",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Purchases: Brand Count",
      dataIndex: "purchases_brand_count",
      key: "purchases_brand_count",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Purchases: Brand Share (%)",
      dataIndex: "purchases_brand_price_median",
      key: "purchases_brand_price_median",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Purchases: Price (Median)",
      dataIndex: "purchases_price_median",
      key: "purchases_price_median",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Purchases: Brand Price (Median)",
      dataIndex: "purchases_brand_price_median",
      key: "purchases_brand_price_median",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Purchases: Same Day Shipping Speed",
      dataIndex: "purchases_same_day_shipping_speed",
      key: "purchases_same_day_shipping_speed",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Purchases: 1D Shipping Speed",
      dataIndex: "purchases_1d_shipping_speed",
      key: "purchases_1d_shipping_speed",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Purchases: 2D Shipping Speed",
      dataIndex: "purchases_2d_shipping_speed",
      key: "purchases_2d_shipping_speed",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "SIP Purchase Score",
      dataIndex: "sip_purchase_score",
      key: "sip_purchase_score",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Reporting Date",
      dataIndex: "reporting_date",
      key: "reporting_date",
    },
  ];
  const asin_columns = [
    {
      title: "Year",
      dataIndex: "select_year",
      key: "select_year",
    },
    {
      title: "Week Number",
      dataIndex: "week_number",
      key: "week_number",
      width: 160,
    },

    {
      title: "Month Number",
      dataIndex: "month_number",
      key: "month_number",
      width: 160,
      render: (e) => {
        return e || "-";
      },
    },
    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
      render: (e) => {
        return e || "-";
      },
    },
    {
      title: "Search Query",
      dataIndex: "search_query",
      key: "search_query",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Search Query Score",
      dataIndex: "search_query_score",
      key: "search_query_score",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Search Query Volume",
      dataIndex: "searchQueryVolume",
      key: "searchQueryVolume",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Impressions: Total Count",
      dataIndex: "search_query_volume",
      key: "search_query_volume",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Impressions: ASIN Count",
      dataIndex: "impression_asin_count",
      key: "impression_asin_count",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Impressions: ASIN Share %",
      dataIndex: "impression_asin_share_percentage",
      key: "impression_asin_share_percentage",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Clicks: Total Count",
      dataIndex: "clicks_total_count",
      key: "clicks_total_count",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Clicks: Click Rate %",
      dataIndex: "clicks_rate_percentage",
      key: "clicks_rate_percentage",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "SIP IQ Score",
      dataIndex: "sip_iq_score",
      key: "sip_iq_score",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Clicks: ASIN Count",
      dataIndex: "clicks_asin_count",
      key: "clicks_asin_count",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Clicks: ASIN Share %",
      dataIndex: "clicks_asin_share_percentage",
      render: (e) => {
        return CommaAndFloat(e);
      },
      key: "clicks_asin_share_percentage",
    },
    {
      title: "Clicks: Price (Median)",
      dataIndex: "clicks_price_median",
      render: (e) => {
        return CommaAndFloat(e);
      },
      key: "clicks_price_median",
    },
    {
      title: "Clicks: ASIN Price (Median)",
      dataIndex: "clicks_asin_price_median",
      render: (e) => {
        return CommaAndFloat(e);
      },
      key: "clicks_asin_price_median",
    },
    {
      title: "Clicks: Same Day Shipping Speed",
      dataIndex: "clicks_same_day_shipping_speed",
      key: "clicks_same_day_shipping_speed",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Clicks: 1D Shipping Speed",
      dataIndex: "clicks_1d_shipping_speed",
      key: "clicks_1d_shipping_speed",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Clicks: 2D Shipping Speed",
      dataIndex: "clicks_2d_shipping_speed",
      key: "clicks_2d_shipping_speed",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "SIP Click Score",
      dataIndex: "sip_click_score",
      render: (e) => {
        return CommaAndFloat(e);
      },
      key: "sip_click_score",
    },
    {
      title: "Cart Adds: Total Count",
      dataIndex: "cart_adds_total_count",
      key: "cart_adds_total_count",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Cart Adds: Cart Add Rate %",
      dataIndex: "cart_add_rate_percentage",
      render: (e) => {
        return CommaAndFloat(e);
      },
      key: "cart_add_rate_percentage",
    },
    {
      title: "Cart Adds: ASIN Count",
      dataIndex: "cart_add_asin_count",
      key: "cart_add_asin_count",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Cart Adds: ASIN Share %",
      render: (e) => {
        return CommaAndFloat(e);
      },
      dataIndex: "cart_add_asin_share_percentage",
      key: "cart_add_asin_share_percentage",
    },
    {
      title: "Cart Adds: Price (Median)",
      dataIndex: "cart_add_price_median",
      render: (e) => {
        return CommaAndFloat(e);
      },
      key: "cart_add_price_median",
    },
    {
      title: "Cart Adds: ASIN Price (Median)",
      dataIndex: "cart_adds_asin_price_median",
      key: "cart_adds_asin_price_median",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Cart Adds: Same Day Shipping Speed",
      dataIndex: "cart_adds_same_day_shipping_speed",
      key: "cart_adds_same_day_shipping_speed",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Cart Adds: 1D Shipping Speed",
      dataIndex: "clicks_1d_shipping_speed",
      key: "clicks_1d_shipping_speed",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Cart Adds: 2D Shipping Speed",
      dataIndex: "clicks_2d_shipping_speed",
      key: "clicks_2d_shipping_speed",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "SIP Cart Score",
      dataIndex: "sip_cart_score",
      key: "sip_cart_score",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Purchases: Total Count",
      dataIndex: "purchases_total_count",
      key: "purchases_total_count",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Purchases: Purchase Rate %",
      dataIndex: "purchase_rate_percentage",
      key: "purchase_rate_percentage",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Purchases: ASIN Count",
      dataIndex: "purchases_asin_count",
      key: "purchases_asin_count",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Purchases: ASIN Share %",
      dataIndex: "purchases_asin_price_median",
      key: "purchases_asin_price_median",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Purchases: Price (Median)",
      dataIndex: "purchases_price_median",
      key: "purchases_price_median",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Purchases: ASIN Price (Median)",
      dataIndex: "purchases_asin_price_median",
      key: "purchases_asin_price_median",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Purchases: Same Day Shipping Speed",
      dataIndex: "purchases_same_day_shipping_speed",
      key: "purchases_same_day_shipping_speed",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Purchases: 1D Shipping Speed",
      dataIndex: "purchases_1d_shipping_speed",
      key: "purchases_1d_shipping_speed",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Purchases: 2D Shipping Speed",
      dataIndex: "purchases_2d_shipping_speed",
      key: "purchases_2d_shipping_speed",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "SIP Purchase Score",
      dataIndex: "sip_purchase_score",
      key: "sip_purchase_score",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Reporting Date",
      dataIndex: "reporting_date",
      key: "reporting_date",
    },
  ];
  const fileInputRef = useRef(null);
  const [tableLoading, setTableLoading] = useState(true);

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [openDropdown, setOpenDropdown] = useState(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({
    search_key: null,
    start_date: dayjs()
      .subtract(1, "month")
      .startOf("month")
      .format("YYYY-MM-DD"),
    end_date: dayjs().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
  });
  const [sortFilters, setSortFilters] = useState({
    field_name: null,
    sort_by: null,
  });

  const [messageView, setMessageView] = useState({
    type: null,
    message: "",
  });

  const [file, setFile] = useState({
    fileName: null,
    file: null,
  });

  const [type, setType] = useState("Brand View");

  const presetRanges_ = [
    {
      label: "Yesterday",
      value: [dayjs().subtract(1, "day"), dayjs().subtract(1, "day")],
    },
    { label: "This Week", value: [dayjs().startOf("week"), dayjs()] },
    {
      label: "This Month",
      value: [dayjs().startOf("month"), dayjs().endOf("month")],
    },
    {
      label: "Last Month",
      value: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
    },
    {
      label: "This Quarter",
      value: [dayjs().startOf("quarter"), dayjs().endOf("quarter")],
    },
    {
      label: "This Year",
      value: [dayjs().startOf("year"), dayjs().endOf("year")],
    },
    {
      label: "Last Year",
      value: [
        dayjs().subtract(1, "year").startOf("year"),
        dayjs().subtract(1, "year").endOf("year"),
      ],
    },
  ];
  const presetRanges = presetRanges_.map((range) => {
    const start = range.value[0];
    let end = range.value[1];

    // Check if the end date is in the future
    if (end.isAfter(dayjs())) {
      end = dayjs(); // Replace with the current date
    }

    return {
      ...range,
      value: [start, end],
    };
  });

  const segmentTypes = {
    "Brand View": "brand_wise",
    "ASIN View": "asin_wise",
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };

  const downloadExcel = (exportList, name) => {
    // Create a new workbook
    const wb = XLSX.utils.book_new();

    const changeKeyList = exportList.map((item) => {
      // Destructure the item, rename the key, and return a new object
      const { status, created_at, updated_at, ...rest } = item;
      return { ...rest };
    });
    // Add a worksheet to the workbook
    const ws = XLSX.utils.json_to_sheet(changeKeyList);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

    // Convert the workbook to a binary Excel file (binary string)
    const excelBinaryString = XLSX.write(wb, {
      bookType: "xlsx",
      type: "binary",
    });

    // Convert the binary string to a Blob
    const blob = new Blob([s2ab(excelBinaryString)], {
      type: "application/octet-stream",
    });

    // Create a download link and trigger the download
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${name || "sqp-export"}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getList = async (data) => {
    try {
      if (data?.is_export !== "1") {
        setTableLoading(true);
      }

      const response = await MakeApiCall(
        `sqp?page=${data?.page || page || 1}&search_query=${
          data?.search_key || ""
        }&per-page=${data?.pageSize || pageSize}&field_name=${
          data?.field_name || ""
        }&sort_by=${data?.sort_by || ""}&sqp_type=${
          segmentTypes?.[data?.type || type]
        }${`&start_date=${
          data?.start_date || filters?.start_date || ""
        }&end_date=${data?.end_date || filters?.end_date || ""}`}&is_export=${
          data?.is_export || "0"
        }`,
        "get",
        null,
        true
      );

      if (response?.status) {
        if (data?.is_export === "1") {
          message.destroy();
          downloadExcel(
            response?.data?.records,
            segmentTypes?.[data?.type || type]
          );

          return;
        }
        message.destroy();
        setList(response?.data?.records || []);
        setTotalPage(response?.data?.pagination?.totalCount || 0);
        setTableLoading(false);
        setLoading(false);
      } else {
        if (data?.is_export === "1") {
          return;
        }
        message.destroy();
        setLoading(false);
        setTableLoading(false);
        setTotalPage(0);
        ErrorModal(response?.message);
      }
    } catch (error) {
      message.destroy();
    }
  };

  useEffect(() => {
    setLoading(true);
    getList({ ...sortFilters, ...filters });
    return () => {};
  }, []);

  const ImportSQP = async (data) => {
    const getToken = () => localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${getToken()}`);

    var formdata = new FormData();
    formdata.append("sqp_type", data?.sqp_type);
    formdata.append(
      "sqp_data_file",
      data?.uploadedFile,
      data?.uploadedFile?.name
    );

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(ENDPOINT + "sqp/import", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        message.destroy();
        if (result?.status === true) {
          fileInputRef.current.value = null;
          setFile({
            fileName: null,
            file: null,
          });
          setFile({
            fileName: null,
            file: null,
          });
          setPage(1);
          setLoading(true);
          getList({ page: 1, ...sortFilters, ...filters });

          SuccessModal();
          setOpenDropdown(false);
        } else {
          fileInputRef.current.value = null;
          setFile({
            fileName: null,
            file: null,
          });
          ErrorModal();
        }
      })
      .catch((error) => {
        message.destroy();
        fileInputRef.current.value = null;
        setFile({
          fileName: null,
          file: null,
        });
        setMessageView({
          type: "error",
          message: error?.message,
        });
      });
  };

  const DeleteSQP = async (data) => {
    try {
      const response = await MakeApiCall(
        `sqp/delete-all`,
        "post",
        {
          sqp_ids: data?.map((d) => d?.id),
        },
        true
      );
      if (response?.status === true) {
        setSelectedRowKeys([]);

        OnOkDeleteModal(response?.message || "");
        setLoading(true);
        getList({ ...sortFilters, ...filters });
      } else if (response?.status === false) {
        ErrorModal(response?.message || "");
      }
    } catch (error) {}
  };
  const { token } = useToken();
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const menuStyle = {
    boxShadow: "none",
  };
  const sortType = {
    descend: "desc",
    ascend: "asc",
  };
  const DefaultSortType = {
    desc: "descend",
    asc: "ascend",
  };
  const PropsFilter = (type) => ({
    defaultSortOrder:
      type === sortFilters?.field_name
        ? DefaultSortType?.[sortFilters?.sort_by]
        : [],
    sorter: () => {},
  });
  const handleChange = (_, __, sorter) => {
    const obj = {
      field_name: sortType?.[sorter?.order] ? sorter?.columnKey : "",
      sort_by: sortType?.[sorter?.order] || "",
    };
    setSortFilters(obj);
    getList({ ...obj, ...filters });
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRows);
    },
    selectedRowKeys: selectedRowKeys?.map((d) => d?.id), // This binds the selected keys with the component state
  };

  const ExportBTN = (
    <a
      className="me-5"
      style={{
        background: "#00B660",
        borderRadius: 6,
        padding: "6px 15px",
        cursor: "pointer",
      }}
      onClick={() => {
        message.destroy();
        message.loading("Loading...", 0);
        getList({
          is_export: "1",
          ...sortFilters,
          ...filters,
        });
      }}
    >
      <div
        style={{
          color: "#FAFAFB",
          fontSize: 13,

          fontWeight: 500,
          letterSpacing: 0.1,
          wordWrap: "break-word",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={20}
          height={20}
          viewBox="0 0 24 24"
          fill="none"
          className="me-3"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 18.9656L13.3892 18.6653C13.8265 18.328 14.4544 18.409 14.7918 18.8463C15.1291 19.2836 15.0481 19.9115 14.6108 20.2489L12.6277 21.7787C12.5675 21.8274 12.5025 21.8682 12.4342 21.901C12.3808 21.928 12.309 21.9545 12.234 21.9725C12.1375 21.9951 12.0448 22.0033 11.953 21.9989C11.812 21.9924 11.6786 21.9567 11.5588 21.8976C11.4931 21.8654 11.4305 21.8257 11.3723 21.7787L9.38919 20.2489C8.9519 19.9115 8.87088 19.2836 9.20823 18.8463C9.54557 18.409 10.1735 18.328 10.6108 18.6653L11 18.9656V8.45711C11 7.90482 11.4477 7.45711 12 7.45711C12.5523 7.45711 13 7.90482 13 8.45711V18.9656ZM12.5 2C15.0609 2 17.2376 3.76105 17.8369 6.17236C20.2436 6.77356 22 8.95407 22 11.518C22 14.3327 19.8828 16.6868 17.1101 16.9939C16.5612 17.0547 16.0669 16.659 16.0061 16.1101C15.9453 15.5612 16.341 15.0669 16.8899 15.0061C18.6514 14.8109 20 13.3114 20 11.518C20 9.71741 18.6408 8.21401 16.8706 8.02783C16.4009 7.97843 16.0298 7.60718 15.9806 7.13748C15.7947 5.36183 14.2947 4 12.5 4C10.7037 4 9.20292 5.36415 9.01891 7.14171C8.96154 7.69596 8.46222 8.0964 7.90874 8.03205C7.77394 8.01638 7.63757 8.00847 7.5 8.00847C5.56748 8.00847 4 9.57926 4 11.518C4 13.3114 5.34862 14.8109 7.11011 15.0061C7.65903 15.0669 8.05473 15.5612 7.99392 16.1101C7.93311 16.659 7.43882 17.0547 6.88989 16.9939C4.11715 16.6868 2 14.3327 2 11.518C2 8.57497 4.30383 6.17018 7.20435 6.01629C7.85706 3.68561 9.99449 2 12.5 2Z"
            fill="white"
          />
        </svg>
        Export
      </div>
    </a>
  );
  return (
    <div className="card custom-ui">
      <div className="card-header">
        <div className="card-title">
          Search Query Performance
          <InfoVideo data={locationData} title="sqp" className={"ms-3"} />
          <Segmented
            onChange={(e) => {
              getList({
                page: 1,
                field_name: null,
                sort_by: null,
                type: e,
                ...filters,
              });
              setPage(1);
              setLoading(true);
              setSortFilters({
                field_name: null,
                sort_by: null,
              });
              setType(e);
            }}
            className="ms-5"
            value={type}
            options={["Brand View", "ASIN View"]}
          />
          <Input
            onPressEnter={() => {
              getList({
                ...filters,
                ...sortFilters,
                page: page,
                pageSize: pageSize,
              });
            }}
            value={filters?.search_key}
            allowClear
            onChange={(e) => {
              setFilters((prev) => {
                if (!e.target.value && prev) {
                  getList({
                    ...{
                      ...prev,
                      search_key: e.target.value,
                    },
                    ...sortFilters,
                    page: page,
                    pageSize: pageSize,
                  });
                }
                return {
                  ...prev,
                  search_key: e.target.value,
                };
              });
            }}
            placeholder="Search Query"
            className="w-175px ms-3 "
          />
          <button
            onClick={() => {
              getList({
                ...filters,
                ...sortFilters,
                page: page,
                pageSize: pageSize,
              });
            }}
            className="btn btn-sm ms-3 btn-search me-3"
            style={{ padding: "5px 15px" }}
          >
            <svg
              width={15}
              height={15}
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeWidth={2}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m21 21-2.243-2.247-2.243-2.247" />
              <path d="M19 10.5a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0Z" />
            </svg>
          </button>
          <DatePickerView
            onChange={(e) => {
              console.log(e, "eeeee");

              const obj = {
                ...filters,
                start_date: dayjs(e?.start_date)?.format("YYYY-MM-DD"),
                end_date: dayjs(e?.end_date)?.format("YYYY-MM-DD"),
              };
              getList({
                ...obj,
                ...sortFilters,
                page: page,
                pageSize: pageSize,
              });
              setFilters(obj);
            }}
            value={[filters?.start_date, filters?.end_date]}
          />
          {/* <DatePicker.RangePicker
            allowClear
            // presets={presetRanges}
            value={
              filters?.start_date && filters?.end_date
                ? [
                    dayjs(filters?.start_date, "YYYY-MM-DD"),
                    dayjs(filters?.end_date, "YYYY-MM-DD"),
                  ]
                : []
            }
            onChange={(e) => {
              const obj = {
                ...filters,
                start_date: dayjs(e?.[0])?.format("YYYY-MM-DD"),
                end_date: dayjs(e?.[1])?.format("YYYY-MM-DD"),
              };
              getList({
                ...obj,
                ...sortFilters,
                page: page,
                pageSize: pageSize,
              });
              setFilters(obj);
            }}
            className="w-250px me-3"
            disabledDate={(current) => {
              return current && current > moment().endOf("day");
            }}
          /> */}
        </div>
        <div className="card-toolbar">
          {ExportBTN}
          <Dropdown
            menu={{
              items: [],
            }}
            trigger={["click"]}
            className="me-5"
            open={openDropdown}
            onOpenChange={(e) => {
              setOpenDropdown(e);
              if (!e) {
                fileInputRef.current.value = null;
                setFile({
                  fileName: null,
                  file: null,
                });
              }
            }}
            placement="bottomRight"
            dropdownRender={(menu) => (
              <div style={contentStyle}>
                {React.cloneElement(menu, {
                  style: menuStyle,
                })}

                <div className="px-7 py-5">
                  <div
                    className="mb-10 position-relative"
                    style={{
                      border: "1px solid #c9c9c9",
                      padding: "8px 30px 8px 8px",
                      borderRadius: "10px",
                    }}
                  >
                    <input
                      // className="form-control form-control-sm form-control-solid"
                      // style={{
                      //   backgroundColor: "#b8e7ff",
                      // }}
                      placeholder
                      type="file"
                      accept="text/csv"
                      value={file?.fileName}
                      ref={fileInputRef}
                      onChange={(e) => {
                        setFile({
                          fileName: e.target.value,
                          file: e.target.files?.[0],
                        });
                      }}
                      id="formFile"
                    />
                    <i
                      onClick={() => {
                        fileInputRef.current.value = null;
                        setFile({
                          fileName: null,
                          file: null,
                        });
                      }}
                      className="ki-outline ki-cross-square fs-1 "
                      style={{
                        position: "absolute",
                        right: "4px",
                        top: "11px",
                        bottom: "0px",
                        color: "red",
                        cursor: "pointer",
                      }}
                    />
                  </div>

                  <div className="d-flex justify-content-end">
                    <Button
                      type="primary"
                      // className="btn btn-sm btn-dark"
                      onClick={() => {
                        const obj = {
                          uploadedFile: file?.file,
                          sqp_type:
                            type === "Brand View" ? "brand_wise" : "asin_wise",
                        };

                        message.destroy();
                        message.loading("Loading...", 0);
                        ImportSQP(obj);
                      }}
                    >
                      Upload
                    </Button>
                  </div>
                  {/*end::Actions*/}
                </div>
              </div>
            )}
          >
            <Button onClick={(e) => e.preventDefault()} type="dashed">
              Upload
            </Button>
          </Dropdown>

          <Button
            onClick={() => {
              DeleteCModal(null, () => DeleteSQP(selectedRowKeys));
            }}
            danger
            className="ms-5"
            type="dashed"
          >
            Delete {selectedRowKeys?.length === list?.length ? "All" : ""}
          </Button>
        </div>
      </div>

      <div className="card-body pt-0">
        {loading ? (
          <TableLoading
            id="test-table"
            columns={[1, 2, 3, 4, 5]}
            checkBoxVal={true}
            actions={[1, 2]}
          />
        ) : list?.length === 0 ? (
          <Empty />
        ) : (
          <div className="">
            <Table
              dataSource={list}
              fixed={true}
              sticky={{
                offsetHeader: "0px",
              }}
              loading={tableLoading}
              onChange={handleChange}
              rowKey="id"
              rowSelection={rowSelection}
              scroll={{ x: "max-content" }}
              columns={
                type === "ASIN View"
                  ? asin_columns?.map((d) => ({
                      ...d,
                      ...PropsFilter(d?.dataIndex),
                      render: (props, row, index) =>
                        RenderTable(props, row, index, d),
                    }))
                  : brand_columns?.map((d) => ({
                      ...d,
                      ...PropsFilter(d?.dataIndex),
                      render: (props, row, index) =>
                        RenderTable(props, row, index, d),
                    }))
              }
              pagination={false}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "even-row" : "odd-row";
              }}
              bordered
              size="small"
            />
          </div>
        )}
        <Pagination
          loading={loading}
          pageSize={pageSize}
          page={page}
          totalPage={totalPage}
          onPerPage={(e) => {
            setLoading(true);
            setPageSize(e);
            setPage(1);
            getList({
              page: 1,
              pageSize: e,
              ...sortFilters,
              ...filters,
            });
          }}
          onPageNo={(e) => {
            setLoading(true);
            setPage(e);
            getList({
              page: e,
              ...filters,
              ...sortFilters,
            });
          }}
        />
      </div>
    </div>
  );
};

export default Sqp;
