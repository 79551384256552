import React, { useContext, useState } from "react";
import { Wrapper } from "./style";
import { MakeApiCall } from "../../../../apis";
import { Table, Tooltip, message, Tag, Select, Breadcrumb, Empty } from "antd";
import "moment-timezone";
import { useEffect } from "react";
import {
  ClockCircleOutlined,
  SyncOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import Pagination from "../../../../components/pagination";
import moment from "moment";
import { TableLoading } from "../../../../components/table-animation";
import { RenderTable, numberformat } from "../../../../config";
import { GlobalContext } from "../../../../common-context";
import CredentialsError from "../../../credential-page";
import InfoVideo from "../../../../components/header-icons";
import { useHistory, useLocation } from "react-router-dom";

const CentralLog = ({ menusList, pageTitle }) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const [filters, setFilters] = useState({
    event_type: null,
    event_name: null,
    event_status: null,
    marketplace_type: "amazon",
  });

  console.log("====================================");
  const urlParams = new URLSearchParams(location?.search);
  // const keys = [];
  // for (const key of urlParams.keys()) {
  //   keys.push({ key, value: urlParams.get(key) });
  // }
  console.log(urlParams.get("event_status"), "keys");
  console.log("====================================");

  const [marketplaceLoading, setMarketplaceLoading] = useState(true);
  const [marketplaceList, setMarketplaceList] = useState([
    {
      label: "Amazon",
      value: "amazon",
    },
    // {
    //   label: "Walmart",
    //   value: "walmart",
    // },
  ]);

  const [eventTypeList, setEventTypeList] = useState([]);
  const [eventTypeLoading, setEventTypeLoading] = useState(true);

  const [eventNameList, setEventNameList] = useState([]);
  const [eventNameLoading, setEventNameLoading] = useState(true);

  const getList = async (data) => {
    const response = await MakeApiCall(
      `user/system-event-process?page=${page}&event_type=${
        filters?.event_type || ""
      }&event_name=${filters?.event_name || ""}&event_status=${
        data?.event_status || filters?.event_status || ""
      }&marketplace_type=${filters?.marketplace_type || ""}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setList(response?.data?.records);
      setPageSize(response?.data?.pagination?.page_size);
      setTotalPage(response?.data?.pagination?.totalCount);
      setLoading(false);
    } else {
      message.warning(response?.message);
      setLoading(false);
    }
  };

  const getEventNameList = async (data) => {
    const response = await MakeApiCall(
      `event-name-list?marketplace_type=${data?.marketplace_type || ""}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setEventNameList(
        Object?.entries(response?.data)?.map(([key, value]) => ({
          label: key,
          value: value,
        })) || []
      );
      setEventNameLoading(false);
    } else {
      setEventNameList([]);
      setEventNameLoading(false);
      message.warning(response?.message);
    }
  };
  const getEventTypeList = async (data) => {
    const response = await MakeApiCall(
      `event-type-list?marketplace_type=${data?.marketplace_type || ""}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setEventTypeList(
        response?.data?.map((d) => ({ label: d, value: d })) || []
      );
      setEventTypeLoading(false);
    } else {
      setEventTypeLoading(false);
      message.warning(response?.message);
    }
  };
  const getMarketplaceList = async () => {
    const response = await MakeApiCall(`sales-channel`, "get", null, true);
    if (response?.status) {
      // setMarketplaceList(response?.data?.sales_channel || []);
      setMarketplaceLoading(false);
    } else {
      setMarketplaceLoading(false);
      message.warning(response?.message);
    }
  };
  const [locationData, setLocationData] = useState({});
  const getLocationData = async (data) => {
    const response = await MakeApiCall(
      `menu-content-data?menu_item_id=14`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setLocationData(response?.data);
    } else {
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    getLocationData();
    return () => {};
  }, []);

  useEffect(() => {
    setMarketplaceLoading(true);
    getMarketplaceList();

    getEventNameList();
    setEventNameLoading(true);

    getEventTypeList();
    setEventTypeLoading(true);
    return () => {};
  }, []);
  const contextValue = useContext(GlobalContext);

  useEffect(() => {
    if (urlParams.get("event_status")) {
      setFilters({
        event_type: null,
        event_name: null,
        event_status: filters?.event_status || urlParams.get("event_status"),
        marketplace_type: "amazon",
      });
      getList({
        event_status: filters?.event_status || urlParams.get("event_status"),
      });
    }
  }, [urlParams.get("event_status")]);
  useEffect(() => {
    if (!filters?.event_status && urlParams.get("event_status")) {
      return;
    }
    getList();
    return () => {};
  }, [page]);

  const onPageNo = (e) => {
    setLoading(true);
    setList([]);
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
  };

  const checkDateValidation = (date) => {
    if (moment(date).isValid()) {
      return date;
    }
    return false;
  };
  const columns = [
    {
      title: "ID",
      align: "center",
      width: 100,
      ellipsis: true,
      render: (_, __, i) => {
        return <span>{(page - 1) * pageSize + 1 + i}</span>;
      },
    },
    {
      title: "Date & Time Log created (ET)",
      width: 280,
      render: (item) => {
        return (
          <div>
            <span>
              {moment(new Date(item.created_at * 1000))
                .tz("America/New_York")
                .format("MMM DD, YYYY hh:mm A")}
            </span>
          </div>
        );
      },
    },
    {
      title: "Event Date (PT)",
      width: 240,
      render: (item) => {
        if (
          item?.event_type === "SP API Report" ||
          item?.event_type === "Financial API"
        ) {
          return (
            <>
              <div
                className="d-flex"
                style={
                  {
                    // justifyItems: "center",
                    // justifyContent: "start",
                    // height: "55px",
                  }
                }
              >
                <span>
                  {checkDateValidation(
                    new Date(
                      JSON.parse(item?.event_details)?.[
                        item?.event_type === "Financial API"
                          ? "start_date"
                          : "data_start_time"
                      ]
                    )
                  )
                    ? moment(
                        new Date(
                          JSON.parse(item?.event_details)?.[
                            item?.event_type === "Financial API"
                              ? "start_date"
                              : "data_start_time"
                          ]
                        )
                      ).format("MMM DD, YYYY")
                    : moment(
                        new Date(
                          JSON.parse(item?.event_details)?.[
                            item?.event_type === "Financial API"
                              ? "data_start_time"
                              : "data_start_time"
                          ]
                        )
                      ).format("MMM DD, YYYY")}
                </span>
                to
                <br />
                <span>
                  {checkDateValidation(
                    new Date(
                      JSON.parse(item?.event_details)?.[
                        item?.event_type === "Financial API"
                          ? "end_date"
                          : "data_end_time"
                      ]
                    )
                  )
                    ? moment(
                        new Date(
                          JSON.parse(item?.event_details)?.[
                            item?.event_type === "Financial API"
                              ? "end_date"
                              : "data_end_time"
                          ]
                        )
                      ).format("MMM DD, YYYY")
                    : moment(
                        new Date(
                          JSON.parse(item?.event_details)?.[
                            item?.event_type === "Financial API"
                              ? "data_end_time"
                              : "data_end_time"
                          ]
                        )
                      ).format("MMM DD, YYYY")}
                </span>
                <br />
              </div>
            </>
          );
        } else if (
          item?.event_type === "SKU Wise" ||
          item?.event_type === "Brand Wise"
        ) {
          return (
            <>
              <div
                className="d-flex"
                style={
                  {
                    // justifyItems: "center",
                    // justifyContent: "start",
                    // height: "55px",
                  }
                }
              >
                <span>
                  {moment(
                    new Date(JSON.parse(item?.event_details)?.["startDate"])
                  ).format("MMM DD, YYYY")}
                </span>
                to
                <br />
                <span>
                  {moment(
                    new Date(JSON.parse(item?.event_details)?.["endDate"])
                  ).format("MMM DD, YYYY")}
                </span>
                <br />
              </div>
            </>
          );
        } else {
          return moment(new Date(item?.event_date)).format("MMM DD, YYYY");
        }
      },
    },
    {
      title: "Event Name",
      width: 230,

      render: (item) => {
        return (
          <Tooltip rule>
            <span>
              {item?.event_type === "Advertising Report" ? (
                item?.event_details ? (
                  <div className="d-flex">
                    <span>{item?.event_name || "N/A"}</span>
                    <span>
                      ({JSON.parse(item?.event_details)?.record_type || "N/A"})
                    </span>
                  </div>
                ) : (
                  "N/A" || "N/A"
                )
              ) : (
                item?.event_name || "N/A"
              )}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: "Event Type",
      width: 180,
      render: (item) => {
        return <span>{item?.event_type || "N/A"}</span>;
      },
    },

    {
      title: "Event Status",
      width: 150,
      render: (item) => {
        return (
          <Tag
            color={
              item.event_status === "PENDING"
                ? "warning"
                : item.event_status === "IN_PROGRESS"
                ? "processing"
                : item.event_status === "DONE"
                ? "success"
                : item.event_status === "FAILED"
                ? "error"
                : item.event_status === "FATAL"
                ? "error"
                : item.event_status === "CANCELLED"
                ? "error"
                : "error"
            }
            icon={
              item.event_status === "PENDING" ? (
                <ClockCircleOutlined />
              ) : item.event_status === "IN_PROGRESS" ? (
                <SyncOutlined spin />
              ) : item.event_status === "DONE" ? (
                <CheckCircleOutlined />
              ) : item.event_status === "FAILED" ? (
                <CloseCircleOutlined />
              ) : item.event_status === "FATAL" ? (
                <CloseCircleOutlined />
              ) : item.event_status === "CANCELLED" ? (
                <CloseCircleOutlined />
              ) : (
                <CloseCircleOutlined />
              )
            }
          >
            {item.event_status || "N/A"}
          </Tag>
        );
      },
    },
    {
      title: "Priority",
      width: 120,
      render: (item) => {
        return (
          <>
            {item?.priority == 1 ? (
              <div className="d-flex align-items-center">
                <div
                  className="priorityColorDot me-2"
                  style={{ background: "#ff0000" }}
                ></div>
                High
              </div>
            ) : item?.priority == 2 ? (
              <div className="d-flex align-items-center">
                <div
                  className="priorityColorDot me-2"
                  style={{ background: "#faad14" }}
                ></div>
                Medium
              </div>
            ) : (
              <div className="d-flex align-items-center">
                <div
                  className="priorityColorDot me-2"
                  style={{ background: "#52c41a" }}
                ></div>
                Low
              </div>
            )}
          </>
        );
      },
    },
    {
      title: "Execution Time",
      width: 180,
      render: (item) => {
        return <span>{numberformat(item?.execution_time || "0")}</span>;
      },
    },
    {
      title: "Retry Count",
      width: 180,
      render: (item) => {
        return <span>{item?.retry_count || "0"}</span>;
      },
    },
    {
      title: "Marketplace",
      width: 200,
      render: (item) => {
        return <span>{item?.marketplace || "N/A"}</span>;
      },
    },
    {
      title: "Updated At (ET)",
      width: 220,
      render: (item) => {
        return (
          <div>
            <span>
              {moment(new Date(item.updated_at * 1000))
                .tz("America/New_York")
                .format("MMM DD, YYYY hh:mm A")}
            </span>
          </div>
        );
      },
    },
    {
      title: "Report Requested",
      width: 200,
      render: (d) => {
        return (
          <Tag
            color={d.status === 0 ? "processing" : "success"}
            icon={
              (d.status === 0) === "processing" ? (
                <ClockCircleOutlined />
              ) : (
                <CheckCircleOutlined />
              )
            }
          >
            {d.status === 0 ? "Pending" : "Done"}
          </Tag>
        );
      },
    },
    {
      title: "Reason",
      width: 250,
      render: (d) => {
        return (
          <Tooltip title={d?.note}>
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "250px",
              }}
            >
              {d?.note || "N/A"}
            </div>
          </Tooltip>
        );
      },
    },
  ];

  const userCentralLogEventStatus = [
    {
      label: "PENDING",
      value: "PENDING",
    },
    {
      label: "IN_PROGRESS",
      value: "IN_PROGRESS",
    },
    {
      label: "DONE",
      value: "DONE",
    },
    {
      label: "FAILED",
      value: "FAILED",
    },
    {
      label: "FATAL",
      value: "FATAL",
    },
    {
      label: "CANCELLED",
      value: "CANCELLED",
    },
    {
      label: "NOT_FOUND",
      value: "NOT_FOUND",
    },
  ];

  const { lws, sp } = contextValue?.data?.credentialsStatus;

  if (
    (lws !== 1 || sp !== 1) &&
    menusList?.find((d) => d?.url === "central-log")?.is_restricted === 1
  ) {
    return <CredentialsError type="central-log" />;
  }

  return (
    <Wrapper
      id="kt_content_container"
      className="custom-ui container-fluid pt-5"
    >
      <>
        {/*begin::Row*/}
        <div className="row gy-5 g-xl-5">
          {/*begin::Col*/}
          <div className="col-xxl-12">
            {/*begin::Tables Widget 9*/}
            <div className="card card-xxl-stretch mb-5 mb-xl-8">
              {/*begin::Header*/}
              <div className="card-header ">
                <h3 className="card-title">
                  {pageTitle}
                  <InfoVideo
                    data={locationData}
                    title={"central_log"}
                    className={"ms-3"}
                  />
                </h3>
                <div className="card-toolbar gap-2">
                  {" "}
                  <Select
                    loading={eventTypeLoading}
                    options={eventTypeList}
                    className="w-200px"
                    size="large"
                    allowClear
                    placeholder="Select Event Type"
                    onChange={(e) => {
                      setLoading(true);
                      setList([]);

                      setFilters({
                        ...filters,
                        event_type: e,
                      });
                      getList({
                        event_status:
                          filters?.event_status ||
                          urlParams.get("event_status"),
                      });
                    }}
                    value={filters?.event_type || null}
                  />
                  <Select
                    options={eventNameList}
                    loading={eventNameLoading}
                    className="w-200px"
                    allowClear
                    size="large"
                    placeholder="Select Event Name"
                    onChange={(e) => {
                      setLoading(true);
                      setList([]);
                      setFilters({
                        ...filters,
                        event_name: e,
                      });
                      getList({
                        event_status:
                          filters?.event_status ||
                          urlParams.get("event_status"),
                      });
                    }}
                    value={filters?.event_name || null}
                  />
                  <Select
                    onChange={(e) => {
                      setLoading(true);
                      setList([]);
                      setFilters({
                        ...filters,
                        event_status: e,
                      });
                      getList({
                        event_status: e || urlParams.get("event_status"),
                      });
                    }}
                    value={filters?.event_status || null}
                    className="w-200px"
                    allowClear
                    options={userCentralLogEventStatus}
                    size="large"
                    placeholder="Select Event Status"
                  />
                </div>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              <div className="card-body pt-0 pb-0">
                {loading ? (
                  <TableLoading
                    id="test-table"
                    columns={[1, 2, 3, 4, 5]}
                    checkBoxVal={true}
                    actions={[1, 2]}
                  />
                ) : list?.length === 0 ? (
                  <Empty />
                ) : (
                  <div className="">
                    <Table
                      columns={columns?.map((d) => ({
                        ...d,
                        render: (props, row, index) =>
                          RenderTable(props, row, index, d),
                      }))}
                      fixed={true}
                      sticky={{
                        offsetHeader: "0px",
                      }}
                      dataSource={list}
                      rowKey="id"
                      scroll={{
                        x: "max-content",
                      }}
                      loading={loading}
                      pagination={false}
                      rowClassName={(record, index) => {
                        return index % 2 === 0 ? "even-row" : "odd-row";
                      }}
                      bordered
                      size="small"
                    />
                  </div>
                )}

                <Pagination
                  loading={loading || list?.length === 0}
                  pageSize={pageSize}
                  page={page}
                  totalPage={totalPage}
                  onPerPage={onPerPage}
                  onPageNo={onPageNo}
                />
              </div>
              {/*begin::Body*/}
            </div>
            {/*end::Tables Widget 9*/}
          </div>
          {/*end::Col*/}
        </div>
        {/*end::Row*/}
      </>
    </Wrapper>
  );
};

export default CentralLog;
