import { DatePicker } from "antd";
import dayjs from "dayjs";
import React, { useRef, useState } from "react";

export default function DatePickerView({ onChange, value }) {
  const disableDate = "2020-01-01";

  const Id = `DatePickerView-${Math.random().toString(36).substring(7)}`;
  const [yearSelected, setYearSelected] = useState(dayjs().year());
  const generateMonthRange = (month, year) => {
    const startOfMonth = dayjs()
      .set("year", year || dayjs().year())
      .month(month)
      .startOf("month");
    const endOfMonth = dayjs()
      .set("year", year || dayjs().year())
      .month(month)
      .endOf("month");
    return [startOfMonth, endOfMonth];
  };
  const [isOpenCal, setIsOpenCal] = useState(false);
  const rangePickerRef = useRef(null);

  const [all_filter, set_all_filters] = useState({
    start_date: dayjs(value?.[0], "YYYY-MM-DD"),
    end_date: dayjs(value?.[1], "YYYY-MM-DD"),
  });
  const months = [
    "JAN",
    "JULY",
    "FEB",
    "AUG",
    "MAR",
    "SEP",
    "APR",
    "OCT",
    "MAY",
    "NOV",
    "JUN",
    "DEC",
  ];
  const monthsIndex = [
    "0",
    "6",
    "1",
    "7",
    "2",
    "8",
    "3",
    "9",
    "4",
    "10",
    "5",
    "11",
  ];
  const presetRanges = months.map((month, index) => ({
    label: month,
    value: generateMonthRange(monthsIndex?.[index], yearSelected),
    filterKey: month.toUpperCase(),
  }));

  const handleDateRangeChange = (value, [start, end]) => {
    const selectedPresetRange = presetRanges.find(
      (range) =>
        range.value[0].isSame(start, "day") && range.value[1].isSame(end, "day")
    );
    return selectedPresetRange?.filterKey || "custom";
  };

  console.log(Id, "Idc");
  console.log(Id, "Idf");
  console.log(Id, "Idr");
  console.log(Id, "Idw");
  console.log(Id, "Id1");

  return (
    <div id={Id}>
      <DatePicker.RangePicker
        className="ant_common_input me-3"
        id="argo_date_picker"
        presets={presetRanges}
        allowClear={false}
        style={{ width: "290px" }}
        onPanelChange={(e, i, r) => {
          const antPickerInputs = document
            ?.getElementById(Id)
            ?.querySelectorAll(".ant-picker-input");
          const index = Array.from(antPickerInputs)?.findIndex(
            (d) => d?.className === "ant-picker-input ant-picker-input-active"
          );
          const year = dayjs(e[index]).year();
          setYearSelected(year);
        }}
        dropdownClassName={"createDateRangePicker"}
        showWeek
        calendars={1}
        format={"MMM DD, YYYY"}
        value={[all_filter?.start_date, all_filter?.end_date]}
        disabledDate={(current) => {
          // Disable dates before January 1, 2023 and after the current date
          return current && (current < dayjs(disableDate) || current > dayjs());
        }}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        ref={rangePickerRef}
        onCalendarChange={(event, value, info) => {
          const [startYear, endYear] = value.map((d) => dayjs(d).year());
          const [disableStartYear, disableEndYear] = disableDate.split("-");

          if (
            startYear < parseInt(disableStartYear) ||
            endYear > dayjs().year()
          )
            return;

          const filterKey = handleDateRangeChange(value, event);
          if (filterKey === "custom") {
            const filters_ = all_filter;
            filters_.start_date = dayjs(event?.[0]).startOf("week");
            filters_.end_date = dayjs(event?.[0]).endOf("week");

            set_all_filters({ ...filters_ });
            setIsOpenCal(false);
            onChange({ ...filters_ });
          } else {
            const filters_ = all_filter;
            filters_.start_date = event?.[0];
            filters_.end_date = event?.[1];
            set_all_filters({ ...filters_ });
            onChange({ ...filters_ });
          }
          if (rangePickerRef.current) {
            rangePickerRef.current.blur();
          }
        }}
        open={isOpenCal}
        onOpenChange={(e) => {
          setIsOpenCal(e);
        }}
      />
    </div>
  );
}
