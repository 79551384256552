import {
  Button,
  DatePicker,
  Modal,
  Segmented,
  Select,
  Table,
  Tabs,
  Tag,
  Watermark,
  message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Wrapper } from "./style";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import dayjs from "dayjs";
import { PremiumContent } from "../premium-content";
import { GlobalContext } from "../../../common-context";
import { MakeApiCall } from "../../../apis";
import CredentialsError from "../../credential-page";

const AdvertisingDashboard = ({ menusList }) => {
  const [selectedTab, setSelectedTab] = useState("Month");
  const contextValue = useContext(GlobalContext);
  const [date, setDate] = useState(dayjs());
  const SellerStatus = [
    {
      title: "Paid Sales",
      value: "990631.01",
      key: "active_sellers",
      subTitle: "Units Sold",
      subValue: "45",
      suffix: "",
      subSuffix: "",
      prefix: "$",
      subPrefix: "",
    },
    {
      title: "Advertising Cost",
      key: "premium_sellers",
      value: "441783.9545",
      suffix: "",
      prefix: "$",
    },
    {
      title: "ACOS",
      key: "deactivated_sellers",
      value: "44.60",
      suffix: "%",
      prefix: "",
    },
    {
      title: "ROAS",
      key: "deactivated_sellers",
      value: "2.24",
      suffix: "",
      prefix: "",
    },

    {
      title: "TACOS",
      key: "deactivated_sellers",
      value: "22.54",
      suffix: "%",
      prefix: "",
    },
    {
      title: "Daily Spends",
      key: "deactivated_sellers",
      value: "22.54",
      suffix: "",
      prefix: "$",
    },
  ];
  function formatNumberWithCommas(number) {
    return number.toLocaleString("en-US", { maximumFractionDigits: 2 });
  }

  const columns = {
    Week: [
      {
        title: "",
        width: 200,
        dataIndex: "key",
        key: "key",
      },
      {
        title: "Week 14",
        dataIndex: "week11",
        key: "week11",
      },
      {
        title: "Week 13",
        dataIndex: "week12",
        key: "week12",
      },
      {
        title: "Week 12",
        dataIndex: "week13",
        key: "week13",
      },
      {
        title: "Week 11",
        dataIndex: "week14",
        key: "week14",
      },
    ],
    Month: [
      {
        title: "",
        dataIndex: "key",
        width: 200,
        key: "key",
      },
      {
        title: "Month 4",
        dataIndex: "month11",
        key: "month11",
      },
      {
        title: "Month 3",
        dataIndex: "month12",
        key: "month12",
      },
      {
        title: "Month 2",
        dataIndex: "month13",
        key: "month13",
      },
      {
        title: "Month 1",
        dataIndex: "month14",
        key: "month14",
      },
    ],
  };

  const data = [
    { key: "ACOS", week11: "", week12: "", week13: "", week14: "" },
    { key: "ROAS", week11: "", week12: "", week13: "", week14: "" },
    { key: "TACOS", week11: "", week12: "", week13: "", week14: "" },
  ];

  const data_table = [
    {
      type: "Sponsored Products",
      last_month: "990631.01",
      pr_share: "88",
      this_month_actual: "445783.95",
      month_pre: "",
      this_month_projected: "668675.93",
      pr_share_2: "",
      expected_variance: "-321955.08",
      expected_variance_pr: "33",
      this_year: "11887572.12",
      pr_share_3: "88",
    },
    {
      type: "Sponsored Brands",
      last_month: "2539.93",
      pr_share: "0",
      this_month_actual: "1142.9685",
      month_pre: "",
      this_month_projected: "1714.45",
      pr_share_2: "",
      expected_variance: "-825.48",
      expected_variance_pr: "33",
      this_year: "30479.16",
      pr_share_3: "0",
    },
    {
      type: "Sponsored Display",
      last_month: "129926.07",
      pr_share: "12",
      this_month_actual: "58466.7315",
      month_pre: "",
      this_month_projected: "87700.10",
      pr_share_2: "",
      expected_variance: "-42225.97",
      expected_variance_pr: "33",
      this_year: "1559112.84",
      pr_share_3: "12",
    },
    {
      type: "Sponsored Television",
      last_month: "2539.93",
      pr_share: "0",
      this_month_actual: "1142.9685",
      month_pre: "",
      this_month_projected: "1714.45",
      pr_share_2: "",
      expected_variance: "-825.48",
      expected_variance_pr: "33",
      this_year: "30479.16",
      pr_share_3: "0",
    },
  ];
  const total_paid_sales = [
    {
      type: "Total Paid Sales",
      last_month: "1125636.94",
      pr_share: "100",
      this_month_actual: "506536.62",
      month_pre: "0",
      this_month_projected: "758090.48",
      pr_share_2: "",
      expected_variance: "-365006.53",
      expected_variance_pr: "",
      this_year: "13477164.12",
      pr_share_3: "100",
    },
  ];

  const data_table_adCost = [
    {
      type: "Sponsored Products",
      last_month: "990631.01",
      pr_share: "88",
      this_month_actual: "445783.95",
      month_pre: "43",
      this_month_projected: "668675.93",
      pr_share_2: "43",
      expected_variance: "-321955.08",
      expected_variance_pr: "33",
      this_year: "11887572.12",
      pr_share_3: "88",
    },
    {
      type: "Sponsored Brands",
      last_month: "2539.93",
      pr_share: "0",
      this_month_actual: "1142.9685",
      month_pre: "82",
      this_month_projected: "1714.45",
      pr_share_2: "82",
      expected_variance: "-825.48",
      expected_variance_pr: "33",
      this_year: "30479.16",
      pr_share_3: "0",
    },
    {
      type: "Sponsored Display",
      last_month: "129926.07",
      pr_share: "12",
      this_month_actual: "58466.7315",
      month_pre: "22",
      this_month_projected: "87700.10",
      pr_share_2: "22",
      expected_variance: "-42225.97",
      expected_variance_pr: "33",
      this_year: "1559112.84",
      pr_share_3: "12",
    },
    {
      type: "Sponsored Television",
      last_month: "2539.93",
      pr_share: "0",
      this_month_actual: "1142.9685",
      month_pre: "67",
      this_month_projected: "1714.45",
      pr_share_2: "67",
      expected_variance: "-825.48",
      expected_variance_pr: "33",
      this_year: "30479.16",
      pr_share_3: "0",
    },
  ];
  const total_paid_sales_adCost = [
    {
      type: "Total Advertising Costs",
      last_month: "1125636.94",
      pr_share: "100",
      this_month_actual: "506536.62",
      month_pre: "53",
      this_month_projected: "758090.48",
      pr_share_2: "53",
      expected_variance: "-365006.53",
      expected_variance_pr: "",
      this_year: "13477164.12",
      pr_share_3: "100",
    },
  ];

  const errorIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={17}
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2927 5.49996C12.7119 5.49996 12.945 5.98493 12.6831 6.31231L8.39011 11.6786C8.18995 11.9288 7.8094 11.9288 7.60924 11.6786L3.31622 6.31231C3.05431 5.98493 3.2874 5.49996 3.70665 5.49996L12.2927 5.49996Z"
        fill="#E31715"
      />
    </svg>
  );

  const successIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={17}
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.70633 11.5C3.28708 11.5 3.05399 11.015 3.31589 10.6877L7.60891 5.32138C7.80908 5.07117 8.18962 5.07117 8.38978 5.32138L12.6828 10.6877C12.9447 11.015 12.7116 11.5 12.2924 11.5L3.70633 11.5Z"
        fill="#00B55D"
      />
    </svg>
  );

  const getValueShow = (value) => {
    try {
      if (value == 0) {
        return <b style={{ color: "#000" }}>{value}%</b>;
      } else if (parseFloat(value?.split(",")?.join("")) < 0) {
        return (
          <b style={{ color: "red" }}>
            {errorIcon}
            {value}%
          </b>
        );
      }
      return (
        <b style={{ color: "green" }}>
          {successIcon}
          {value}%
        </b>
      );
    } catch (error) {}
  };
  const getUpdatedPCA = async () => {
    const response = await MakeApiCall(
      `funnel/premium-content-status`,
      "get",
      null,
      true
    );

    if (response?.status) {
      const obj = {
        sp: response?.data?.amazon_connected,
        lws: response?.data?.amazon_ads_connected,
      };
      const user_ = {
        ...JSON.parse(localStorage.getItem("user")),
        pca_connected: response?.data?.pca_connected,
        pca_current_stage: response?.data?.pca_current_stage,
        pca_close_status: response?.data?.pca_close_status,
      };
      localStorage.setItem("user", JSON.stringify(user_));
      contextValue.updateCommonGlobalVal({
        user_: user_,
        credentialsStatus: obj,
      });
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      getUpdatedPCA();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  const PCAUpdate = async (data, message_) => {
    const response = await MakeApiCall(
      `seller/pca-stage/${contextValue?.data?.user_?.id}`,
      "put",
      data,
      true
    );

    if (response?.status) {
      message.destroy();
      const obj = {
        sp: response?.data?.amazon_connected,
        lws: response?.data?.amazon_ads_connected,
      };
      const user = {
        ...JSON.parse(localStorage.getItem("user")),
        pca_connected: response?.data?.pca_connected,
        pca_current_stage: response?.data?.pca_current_stage,
        pca_close_status: response?.data?.pca_close_status,
      };
      localStorage.setItem("user", JSON.stringify(user));
      contextValue.updateCommonGlobalVal({
        user_: user,
        credentialsStatus: obj,
      });
      if (message_) {
        Modal.success({
          content: message_,
          centered: true,
        });
      }
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };

  const columnsFilterTop = {
    [`Last Month`]: "last_month",
    [`%Share`]: "pr_share",
    [`This Month Actual`]: "this_month_actual",
    [`_`]: "month_pre",
    [`This Month Projected`]: "this_month_projected",
    [`%Share  `]: "pr_share_2",
    [`Expected Variance`]: "expected_variance",
    [`  `]: "expected_variance_pr",
    [`This Year`]: "this_year",
    [`%Share `]: "pr_share_3",
  };
  const columnsFilterAdsCost = {
    [`Last Month`]: "last_month",
    [`%Share`]: "pr_share",
    [`This Month Actual`]: "this_month_actual",
    [`ACOS`]: "month_pre",
    [`This Month Projected`]: "this_month_projected",
    [`ACOS `]: "pr_share_2",
    [`Expected Variance`]: "expected_variance",
    [`  `]: "expected_variance_pr",
    [`This Year`]: "this_year",
    [`%Share `]: "pr_share_3",
  };

  const items = [
    {
      key: "1",
      label: "Sponsored Products",
      children: null,
    },
    {
      key: "2",
      label: "Sponsored Brands",
      children: null,
    },
    {
      key: "3",
      label: "Sponsored Brand Video",
      children: null,
    },
    {
      key: "4",
      label: "Sponsored Display",
      children: null,
    },
  ];

  const acosData = {
    spProductsACOS: [10, 12, 11, 13, 14, 13, 15, 14, 13, 12, 11, 10],
    spBrandsACOS: [9, 11, 10, 12, 13, 12, 11, 10, 9, 10, 11, 12],
    spDisplayACOS: [15, 14, 15, 16, 17, 16, 15, 14, 13, 14, 15, 16],
    spTelevisionACOS: [20, 19, 18, 17, 18, 19, 20, 19, 18, 17, 16, 15],
    tacos: [8, 7, 9, 8, 9, 8, 7, 6, 7, 8, 7, 6],
  };

  const salesData = {
    spProductsSales: [
      12000, 14000, 15000, 13000, 18000, 16500, 14000, 17000, 19000, 20000,
      21000, 22000,
    ],
    spBrandsSales: [
      8000, 9000, 7000, 8500, 8800, 9200, 8000, 9300, 10000, 11000, 12000, 9500,
    ],
    spDisplaySales: [
      4000, 4500, 4800, 4600, 4700, 4900, 5200, 5400, 5500, 5300, 5600, 5900,
    ],
    spTelevisionSales: [
      2000, 2200, 2400, 2300, 2100, 2500, 2700, 2600, 2800, 2900, 3000, 3100,
    ],
    spProductsAdCosts: [
      5000, 5300, 5500, 5200, 5400, 5600, 5700, 5800, 5900, 6000, 6100, 6200,
    ],
    spBrandsAdCosts: [
      3000, 3200, 3400, 3100, 3300, 3500, 3600, 3700, 3800, 3900, 4000, 4100,
    ],
    spDisplayAdCosts: [
      2000, 2100, 2200, 2000, 2300, 2400, 2500, 2600, 2700, 2800, 2900, 3000,
    ],
    spTelevisionAdCosts: [
      1000, 1100, 1200, 1150, 1300, 1400, 1450, 1500, 1550, 1600, 1650, 1700,
    ],
  };
  const options = {
    chart: {
      zoomType: "xy",
    },
    title: {
      text: "",
    },
    xAxis: [
      {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        crosshair: true,
      },
    ],
    yAxis: [
      {
        // Primary yAxis for columns
        labels: {
          format: "${value}",
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
        title: {
          text: "Sales",
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
        stackLabels: {
          enabled: true,
          style: {
            fontWeight: "bold",
            color:
              // theme
              (Highcharts.defaultOptions.title.style &&
                Highcharts.defaultOptions.title.style.color) ||
              "gray",
          },
        },
      },
      {
        // Secondary yAxis for lines
        title: {
          text: "ACOS",
          style: {
            color: Highcharts.getOptions().colors[0],
          },
        },
        labels: {
          format: "{value} %",
          style: {
            color: Highcharts.getOptions().colors[0],
          },
        },
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
    },
    legend: {
      // layout: "horizontal",
      // align: "center",
      // x: 120,
      // verticalAlign: "bottom",
      // y: 100,
      // floating: false,
      // backgroundColor:
      //   Highcharts.defaultOptions.legend.backgroundColor || // theme
      //   "rgba(255,255,255,0.25)",
    },
    series: [
      {
        name: "Sp Products Sales",
        type: "column",
        data: salesData.spProductsSales,
        tooltip: {
          valuePrefix: "$",
        },
      },
      {
        name: "Sp Brands Sales",
        type: "column",
        data: salesData.spBrandsSales,
        tooltip: {
          valuePrefix: "$",
        },
      },
      {
        name: "Sp Display Sales",
        type: "column",
        data: salesData.spDisplaySales,
        tooltip: {
          valuePrefix: "$",
        },
      },
      {
        name: "Sp Television Sales",
        type: "column",
        data: salesData.spTelevisionSales,
        tooltip: {
          valuePrefix: "$",
        },
      },
      {
        name: "Sp Products Ad Costs",
        type: "column",
        data: salesData.spProductsAdCosts,
        tooltip: {
          valuePrefix: "$",
        },
      },
      {
        name: "Sp Brands Ad Costs",
        type: "column",
        data: salesData.spBrandsAdCosts,
        tooltip: {
          valuePrefix: "$",
        },
      },
      {
        name: "Sp Display Ad Costs",
        type: "column",
        data: salesData.spDisplayAdCosts,
        tooltip: {
          valuePrefix: "$",
        },
      },
      {
        name: "Sp Television Ad Costs",
        type: "column",
        data: salesData.spTelevisionAdCosts,
        tooltip: {
          valuePrefix: "$",
        },
      },

      {
        name: "SP Products ACOS",
        type: "spline",
        yAxis: 1,
        data: acosData?.spProductsACOS,
        tooltip: {
          valueSuffix: " %",
        },
      },
      {
        name: "SP Brands ACOS",
        type: "spline",
        yAxis: 1,
        data: acosData?.spBrandsACOS,
        tooltip: {
          valueSuffix: " %",
        },
      },
      {
        name: "SP Display ACOS",
        type: "spline",
        yAxis: 1,
        data: acosData?.spDisplayACOS,
        tooltip: {
          valueSuffix: " %",
        },
      },
      {
        name: "SP Television ACOS",
        type: "spline",
        yAxis: 1,
        data: acosData?.spTelevisionACOS,
        tooltip: {
          valueSuffix: " %",
        },
      },
      {
        name: "TACOS",
        type: "spline",
        yAxis: 1,
        data: acosData?.tacos,
        tooltip: {
          valueSuffix: " %",
        },
      },
    ],
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: false,
        },
      },
    },
    // ... (other options)
  };

  const presetRanges_ = [
    {
      label: "Yesterday",
      value: [dayjs().subtract(1, "day"), dayjs().subtract(1, "day")],
    },
    { label: "This Week", value: [dayjs().startOf("week"), dayjs()] },
    {
      label: "This Month",
      value: [dayjs().startOf("month"), dayjs().endOf("month")],
    },
    {
      label: "Last Month",
      value: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
    },
    {
      label: "This Quarter",
      value: [dayjs().startOf("quarter"), dayjs().endOf("quarter")],
    },
    {
      label: "This Year",
      value: [dayjs().startOf("year"), dayjs().endOf("year")],
    },
    {
      label: "Last Year",
      value: [
        dayjs().subtract(1, "year").startOf("year"),
        dayjs().subtract(1, "year").endOf("year"),
      ],
    },
  ];

  const { lws, sp } = contextValue?.data?.credentialsStatus;
  if (
    (lws !== 1 || sp !== 1) &&
    menusList?.find((d) => d?.url === "ads-360")?.is_restricted === 1
  ) {
    return <CredentialsError type="ads-360" />;
  }
  return (
    <Wrapper
      id="kt_content_container"
      className="custom-ui container-fluid mt-5"
    >
      <div id="kt_app_toolbar" className="app-toolbar mb-5 ">
        <div
          id="kt_app_toolbar_container"
          className="app-container d-flex flex-stack"
        >
          <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
            <DatePicker
              className="w-150px"
              onChange={(e) => setDate(e)}
              value={date}
            />

            <div className="page-header-info mt-2">
              <div className="discription-text">
                Ads-360 Metrics and Trends for{" "}
                {dayjs(date).format("MM/DD/YYYY")}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row g-4">
        {SellerStatus?.map((d, i) => (
          <div key={i} className="col-auto min-w-250px">
            <div
              className="card"
              style={{ background: "#FFF", borderRadius: "10px" }}
            >
              <div className="card-body  py-4 d-flex align-items-center justify-content-between">
                <div>
                  <div className=" text-gray-900 fs-5 mt-3 mb-2">
                    {d?.title}
                  </div>
                  <div className="text-gray-900 fw-semibold fs-2 ">
                    {d?.prefix}
                    {formatNumberWithCommas(parseFloat(d?.value))}
                    {d?.suffix}
                  </div>
                </div>
                <div className="d-grid" style={{ justifyItems: "end" }}>
                  {d?.subTitle && (
                    <>
                      <div className="fs-5 text-primary mt-2 mb-2">
                        {d?.subTitle}
                      </div>
                      <div className="text-gray-700 text-primary fw-semibold fs-4 ">
                        {d?.subPrefix}
                        {formatNumberWithCommas(parseFloat(d?.subValue))}
                        {d?.subSuffix}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div id="kt_app_toolbar" className="app-toolbar mb-5 mt-5 ">
        <div
          id="kt_app_toolbar_container"
          className="app-container d-flex flex-stack"
        >
          <div className="page-title d-flex flex-row justify-content-center flex-wrap me-3 ">
            <h1 className="page-heading me-3 d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
              Performance Tracker
            </h1>
            <Segmented
              options={["Month", "Week"]}
              value={selectedTab}
              onChange={(value) => {
                setSelectedTab(value);
              }}
            />
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <Table
            columns={columns?.[selectedTab]}
            dataSource={data}
            rowClassName={(record, index) => {
              return index % 2 === 0 ? "even-row" : "odd-row";
            }}
            bordered
            size="small"
            pagination={false}
            scroll={{ x: "max-content" }}
          />
        </div>
      </div>
      <div className="card mt-5">
        <div className="card-body">
          <div className="table-responsive">
            <table className=" align-middle table-row-dashed fs-6 gy-3 dataTable no-footer table-fixed">
              <thead>
                <tr>
                  <th />
                  {Object?.entries(columnsFilterTop)?.map(([key, value], i) => {
                    return (
                      <th
                        key={i}
                        style={{
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                        className={`${key} text-end  pe-3 py-3`}
                      >
                        {key}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-1" colSpan={9} />
                </tr>
                <tr>
                  <td
                    style={{ background: "transparent" }}
                    className="d-flex align-items-center fixed-column justify-content-start"
                  >
                    <h4
                      style={{
                        marginBottom: "0px",
                        color: "#515151",
                        fontWeight: 500,
                      }}
                    >
                      Paid Sales
                    </h4>
                  </td>
                  <td colSpan={9}></td>
                </tr>
                <tr>
                  <td className="p-1" colSpan={9} />
                </tr>
                {data_table?.map((d, i) => (
                  <tr key={i}>
                    <td
                      className="text-end text-hover-primary cursor-pointer d-flex justify-content-end fixed-column "
                      style={{ borderBottom: "1px solid #E5E5E5" }}
                    >
                      <div className="d-flex align-items-center">
                        <span>{d?.type}</span>
                      </div>
                    </td>
                    {Object?.entries(columnsFilterTop)?.map(
                      ([key, value], i) => {
                        return (
                          <td
                            key={i}
                            className="text-end pe-3"
                            style={{
                              borderBottom: `${
                                value?.includes("pr_share") ||
                                value?.includes("month_pre") ||
                                value === "expected_variance_pr"
                                  ? "1px solid #00000000"
                                  : "1px solid #E5E5E5"
                              }`,
                            }}
                          >
                            {value === "expected_variance_pr"
                              ? getValueShow(d?.[value])
                              : d?.[value]
                              ? formatNumberWithCommas(parseFloat(d?.[value])) +
                                (value?.includes("pr_share") ? "%" : "")
                              : ""}
                          </td>
                        );
                      }
                    )}
                  </tr>
                ))}
                {total_paid_sales?.map((d, i) => (
                  <tr key={i}>
                    <td
                      className="text-end text-hover-primary cursor-pointer d-flex justify-content-end fixed-column "
                      style={{
                        borderBottom: "1px solid #E5E5E5",
                        background: "#e4f3ff",
                        color: "#015986",
                        fontWeight: 500,
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <span>{d?.type}</span>
                      </div>
                    </td>
                    {Object?.entries(columnsFilterTop)?.map(
                      ([key, value], i) => {
                        return (
                          <td
                            key={i}
                            className="text-end pe-3"
                            style={{
                              background: "#e4f3ff",
                              color: "#015986",
                              fontWeight: 500,
                              borderBottom: `${
                                value?.includes("pr_share") ||
                                value?.includes("month_pre") ||
                                value === "expected_variance_pr"
                                  ? "1px solid #00000000"
                                  : "1px solid #E5E5E5"
                              }`,
                            }}
                          >
                            {key === "__"
                              ? ""
                              : d?.[value]
                              ? formatNumberWithCommas(parseFloat(d?.[value])) +
                                (value?.includes("pr_share") || key === "_"
                                  ? "%"
                                  : "")
                              : ""}
                          </td>
                        );
                      }
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="table-responsive mt-5">
            <table className=" align-middle table-row-dashed fs-6 gy-3 dataTable no-footer table-fixed">
              <thead>
                <tr>
                  <th />
                  {Object?.entries(columnsFilterAdsCost)?.map(
                    ([key, value], i) => {
                      return (
                        <th
                          key={i}
                          style={{
                            fontWeight: 500,
                            fontSize: "14px",
                          }}
                          className={`${key} text-end  pe-3 py-3`}
                        >
                          {key}
                        </th>
                      );
                    }
                  )}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-1" colSpan={9} />
                </tr>
                <tr>
                  <td
                    style={{ background: "transparent" }}
                    className="d-flex align-items-center fixed-column justify-content-start"
                  >
                    <h4
                      style={{
                        marginBottom: "0px",
                        color: "#515151",
                        fontWeight: 500,
                      }}
                    >
                      Advertising Cost
                    </h4>
                  </td>
                  <td colSpan={9}></td>
                </tr>
                <tr>
                  <td className="p-1" colSpan={9} />
                </tr>
                {data_table_adCost?.map((d, i) => (
                  <tr key={i}>
                    <td
                      className="text-end text-hover-primary cursor-pointer d-flex justify-content-end fixed-column "
                      style={{ borderBottom: "1px solid #E5E5E5" }}
                    >
                      <div className="d-flex align-items-center">
                        <span>{d?.type}</span>
                      </div>
                    </td>
                    {Object?.entries(columnsFilterAdsCost)?.map(
                      ([key, value], i) => {
                        return (
                          <td
                            key={i}
                            className="text-end pe-3"
                            style={{
                              borderBottom: `${
                                value?.includes("pr_share") ||
                                value?.includes("month_pre") ||
                                value === "expected_variance_pr"
                                  ? "1px solid #00000000"
                                  : "1px solid #E5E5E5"
                              }`,
                            }}
                          >
                            {value === "expected_variance_pr"
                              ? getValueShow(d?.[value])
                              : d?.[value]
                              ? formatNumberWithCommas(parseFloat(d?.[value])) +
                                (value?.includes("pr_share") ||
                                key === "_" ||
                                key === "ACOS" ||
                                key === "ACOS_"
                                  ? "%"
                                  : "")
                              : ""}
                          </td>
                        );
                      }
                    )}
                  </tr>
                ))}
                {total_paid_sales_adCost?.map((d, i) => (
                  <tr key={i}>
                    <td
                      className="text-end text-hover-primary cursor-pointer d-flex justify-content-end fixed-column "
                      style={{
                        borderBottom: "1px solid #E5E5E5",
                        background: "#e4f3ff",
                        color: "#015986",
                        fontWeight: 500,
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <span>{d?.type}</span>
                      </div>
                    </td>
                    {Object?.entries(columnsFilterAdsCost)?.map(
                      ([key, value], i) => {
                        return (
                          <td
                            key={i}
                            className="text-end pe-3"
                            style={{
                              background: "#e4f3ff",
                              color: "#015986",
                              fontWeight: 500,
                              borderBottom: `${
                                value?.includes("pr_share") ||
                                value?.includes("month_pre") ||
                                value === "expected_variance_pr"
                                  ? "1px solid #00000000"
                                  : "1px solid #E5E5E5"
                              }`,
                            }}
                          >
                            {key === "__"
                              ? ""
                              : d?.[value]
                              ? formatNumberWithCommas(parseFloat(d?.[value])) +
                                (value?.includes("pr_share") || key === "_"
                                  ? "%"
                                  : "")
                              : ""}
                          </td>
                        );
                      }
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="card mt-5 p-5">
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
      {PremiumContent({
        contextValue: contextValue,
        PCAUpdate: (e, _) => PCAUpdate(e, _),
      })}
      {contextValue?.data?.user_?.pca_connected === 1 ? (
        <div className="performance_part">
          <div id="kt_app_toolbar" className="app-toolbar mb-5 mt-5 ">
            <div
              id="kt_app_toolbar_container"
              className="app-container d-flex flex-stack"
            >
              <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
                <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                  Advertising Performance
                </h1>
              </div>
              <div>
                <Tabs type="line" defaultActiveKey="1" items={items} />
              </div>
            </div>
          </div>

          <div className="d-flex gap-3">
            <Select className="w-200px" size="middle" placeholder="Placement" />
            <Select
              className="w-200px"
              size="middle"
              placeholder="Targetting"
            />
            <Select className="w-200px" size="middle" placeholder="Medium" />
            <Select
              className="w-200px"
              size="middle"
              placeholder="Landing Page"
            />
          </div>

          <div className="d-flex  mt-5 gap-4" style={{ overflow: "auto" }}>
            {[14, 13, 12, 11]?.map((d, i) => (
              <>
                <div className="card " style={{ minWidth: "390px" }}>
                  <div className="card-header  mt-5" style={{ minHeight: 0 }}>
                    <div className="card-title">Week {d}</div>
                  </div>
                  <div className="card-body py-3 px-0">
                    <div className="box-content px-5 mx-5">
                      <div className="row">
                        <div className="col-12 d-grid">
                          <label>Paid Sales</label>
                          <b>$32</b>
                        </div>
                        <div className="col-12 d-grid mt-3">
                          <label>Unit Sold</label>
                          <b>30</b>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 d-grid text-end">
                          <label>ACOS</label>
                          <b>
                            <Tag color="red" bordered={false}>
                              {-21}%
                            </Tag>
                          </b>
                        </div>
                        <div className="col-12 d-grid text-end mt-3">
                          <label>ROAS</label>
                          <b>0.00</b>
                        </div>
                      </div>
                    </div>
                    <div
                      className="box-content px-5 mx-5"
                      style={{ background: "#edf1f5" }}
                    >
                      <div className="row">
                        <div className="col-12 d-grid">
                          <label>Advertising Cost</label>
                          <b>$0</b>
                        </div>
                        <div className="col-12 d-grid mt-3">
                          <label>Viewable Impressions</label>
                          <b>0</b>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 d-grid text-end">
                          <label>TACOS</label>
                          <b>
                            <Tag color="green" bordered={false}>
                              54%
                            </Tag>
                          </b>
                        </div>
                        <div className="col-12 d-grid mt-3 text-end">
                          <label>Clicks</label>
                          <b>0.00</b>
                        </div>
                      </div>
                    </div>

                    <div className="box-content px-5 mx-5">
                      <div className="row">
                        <div className="col-12 d-grid">
                          <label>Avg CTR</label>
                          <b>0%</b>
                        </div>
                        <div className="col-12 d-grid mt-3">
                          <label>Avg CPC</label>
                          <b>0.00</b>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 d-grid text-end">
                          <label>Avg CPA</label>
                          <b>23.00</b>
                        </div>
                        <div className="col-12 d-grid mt-3 text-end">
                          <label>Avg Impression Share</label>
                          <b>0%</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      ) : (
        <Watermark content="Sample">
          <img src={`/assets/media/ads360.png`} style={{ width: "100%" }} />
        </Watermark>
      )}
    </Wrapper>
  );
};

export default AdvertisingDashboard;
