import {
  Checkbox,
  Input,
  Modal,
  Segmented,
  Table,
  Tabs,
  Tag,
  Tooltip,
  message,
} from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { MakeApiCall } from "../../../apis";
import { GlobalContext } from "../../../common-context";
import Icons from "../../../components/icons";
import Pagination from "../../../components/pagination";
import {
  configModal,
  DeleteIcon,
  EditIcon,
  RenderTable,
} from "../../../config";
import EditPage from "./edit-page";
import { Wrapper } from "./style";

const Sellers = () => {
  const history = useHistory();
  const [modal, contextHolder] = Modal.useModal();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState("1");
  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editPage, setEditPage] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const contextValue = useContext(GlobalContext);
  const [filters, setFilters] = useState({
    company_name: "",
    connected: "amazonConnected",
    connected_status: "1",
  });
  const [sortFilters, setSortFilters] = useState({
    field_name: null,
    sort_by: null,
  });

  const queryParams = new URLSearchParams(location.search);
  const defaultActiveKey = queryParams.get("tabs") || "1";
  const defaultSubActiveKey = queryParams.get("subTab") || "1";

  const getList = async (data) => {
    const response = await MakeApiCall(
      `sellers?seller_name=${data?.company_name || ""}&connected=${
        data?.connected || ""
      }&connected_status=${data?.connected_status}&page=${
        data?.page
      }&per-page=${data?.pageSize}&field_name=${
        data?.field_name || ""
      }&sort_by=${data?.sort_by || ""}`,
      "get",
      null,
      true
    );
    if (response?.status) {
      setList(response?.data?.records || []);
      setTotalPage(response?.data?.pagination?.totalCount || 0);
      setLoading(false);
    } else {
      setTotalPage(0);
      setLoading(false);
      message.warning(response?.message);
    }
  };

  const UpdateAction = async (data) => {
    const {
      id,
      amazon_seller_id,
      company_name,
      address1,
      address2,
      address3,
      city,
      state,
      zip_code,
      country,
      status,
    } = data;

    const response = await MakeApiCall(
      `seller/${id}`,
      "put",
      {
        amazon_seller_id,
        company_name,
        address1,
        address2,
        address3,
        city,
        state,
        zip_code,
        country,
        status,
      },
      true
    );
    if (response?.status) {
      history.push(`/sellers`);
      message.success(response?.message);
      getList({
        ...filters,
        page: page,
        pageSize: pageSize,
      });
      setLoading(true);
    } else {
      message.warning(response?.message);
    }
  };

  const messageView = async (title, message) => {
    const confirmed = await modal.warning(configModal(title, message));
  };

  const PCAUpdate = async (data, id) => {
    const response = await MakeApiCall(
      `seller/pca-stage/${id}`,
      "put",
      data,
      true
    );

    if (response?.status) {
      message.destroy();
      contextValue.updateCommonGlobalVal({
        user_: {
          ...JSON.parse(localStorage.getItem("user")),
          pca_connected: response?.data?.pca_connected,
          pca_current_stage: response?.data?.pca_current_stage,
          pca_close_status: response?.data?.pca_close_status,
        },
      });
      getList({
        ...filters,
        page: page,
        pageSize: pageSize,
      });
      await modal.success(configModal("Success", response?.message));
    } else {
      message.destroy();
      messageView("Warning", response?.message);
    }
  };
  const findKey = {
    1: "amazonConnected",
    2: "amazonAdsConnected",
    3: "pcaConnected",
  };

  const sortType = {
    descend: "desc",
    ascend: "asc",
  };
  const DefaultSortType = {
    desc: "descend",
    asc: "ascend",
  };
  const PropsFilter = (type) => ({
    defaultSortOrder:
      type === sortFilters?.field_name
        ? DefaultSortType?.[sortFilters?.sort_by]
        : [],
    sorter: () => {},
  });
  const handleChange = (_, __, sorter) => {
    const obj = {
      field_name: sortType?.[sorter?.order] ? sorter?.column?.filterIndex : "",
      sort_by: sortType?.[sorter?.order] || "",
    };
    setSortFilters(obj);
    setLoading(true);
    getList({
      ...filters,
      page: page,
      pageSize: pageSize,
      ...obj,
    });
  };

  useEffect(() => {
    setFilters({
      ...filters,
      connected: findKey?.[defaultActiveKey] || "amazonConnected",
      connected_status: defaultSubActiveKey || "1",
    });
    setSelectedTab(defaultActiveKey);
    getList({
      ...filters,
      connected: findKey?.[defaultActiveKey] || "amazonConnected",
      connected_status: defaultSubActiveKey || "1",
      page: page,
      pageSize: pageSize,
    });
    setLoading(true);
    return () => {};
  }, []);

  const columns = [
    {
      title: "ID",

      align: "center",
      render: (text, record, index) => (page - 1) * pageSize + 1 + index,
    },
    {
      title: "Action",

      align: "center",

      render: (d) => (
        <>
          <div
            className=" d-flex justify-content-around "
            style={{ width: "100%" }}
          >
            <a
              onClick={() => {
                history.push(`/sellers?user-id=${d?.id}`);
                history.push(
                  `${location?.pathname}${location?.search}${
                    location?.search ? "&" : "?"
                  }user-id=${d?.id}`
                );
              }}
              className=" me-2"
            >
              {EditIcon}
            </a>
            <Tooltip title="API is pending">
              <a href="#" className="">
                {DeleteIcon}
              </a>
            </Tooltip>
          </div>
        </>
      ),
    },
    {
      title: "Seller Name",
      dataIndex: "seller_name",
      key: "seller_name",
      filterIndex: "seller_name",
      render: (e) => {
        return e || "-";
      },
    },
    {
      title: "Address",
      // filterIndex: "city",
      render: (e) => {
        return (
          <div className="d-grid">
            <span>Address 1&nbsp;:&nbsp;{e?.address1 || "-"}</span>
            <span>Address 2&nbsp;:&nbsp;{e?.address2 || "-"}</span>
            <span>Address 3&nbsp;:&nbsp;{e?.address3 || "-"}</span>
            <span>City &nbsp;:&nbsp;{e?.city || "-"}</span>
            <span>State &nbsp;:&nbsp;{e?.state || "-"}</span>
            <span>Country &nbsp;:&nbsp;{e?.country || "-"}</span>
            <span>Zip &nbsp;:&nbsp;{e?.zip_code || "-"}</span>
          </div>
        );
      },
    },
    {
      title: "Created At (ET)",
      // filterIndex: "created_at",
      render: (d) => {
        return (
          <div>
            {moment(new Date(parseInt(d.created_at) * 1000))
              .tz("America/New_York")
              .format("MM/DD/YYYY hh:mm A")}
          </div>
        );
      },
    },
    {
      title: "Primary User",
      // filterIndex: "first_name",
      render: (e) => {
        return (
          <div className="d-grid">
            <span>
              Name&nbsp;:&nbsp;{e?.first_name || "-"}&nbsp;{e?.last_name || "-"}
            </span>
            <span>Email&nbsp;:&nbsp;{e?.email || "-"}</span>
            <span>Coupon Code&nbsp;:&nbsp;{e?.coupon_code || "-"}</span>
          </div>
        );
      },
    },
    {
      title: "Partner",
      dataIndex: "partner",
      key: "partner",
      // filterIndex: "partner",
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   align: "center",
    //   render: (text) => (
    //     <Tag color={text === "Active" ? "green" : "volcano"}>{text}</Tag>
    //   ),
    // },
    // {
    //   title: "Connections",
    //   dataIndex: "connections",
    //   key: "connections",
    //   align: "center",
    //   render: (e) => {
    //     return Array(e)
    //       ?.fill(1)
    //       ?.map((d, i) => (
    //         <i key={i} className="ki-duotone ki-electricity text-success fs-1">
    //           <span className="path1" />
    //           <span className="path2" />
    //           <span className="path3" />
    //           <span className="path4" />
    //           <span className="path5" />
    //           <span className="path6" />
    //           <span className="path7" />
    //           <span className="path8" />
    //           <span className="path9" />
    //           <span className="path10" />
    //         </i>
    //       ));
    //   },
    // },
    {
      title: "Connections",

      align: "center",
      render: (e) => {
        return (
          <div className="d-grid gap-2" style={{ justifyItems: "start" }}>
            <div>
              <Checkbox checked={e?.amazon_connected === 1} />
              <span className="ms-2">SellerCentral</span>
            </div>
            <div>
              <Checkbox checked={e?.amazon_ads_connected === 1} />
              <span className="ms-2">Advertising API</span>
            </div>
            <div>
              <Checkbox checked={e?.pca_connected === 1} />
              <span className="ms-2">Premium Access</span>
            </div>
          </div>
        );
      },
    },
  ];

  const columnsPremium = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      align: "center",
    },
    {
      title: "Action",

      align: "center",

      render: (d) => (
        <>
          <div
            className=" d-flex justify-content-around "
            style={{ width: "100%" }}
          >
            <a
              onClick={() => {
                history.push(`/sellers?user-id=${d?.id}`);
                history.push(
                  `${location?.pathname}${location?.search}${
                    location?.search ? "&" : "?"
                  }user-id=${d?.id}`
                );
              }}
              className=" me-2"
            >
              {EditIcon}
            </a>
            <Tooltip title="API is pending">
              <a href="#" className="">
                {DeleteIcon}
              </a>
            </Tooltip>
          </div>
        </>
      ),
    },
    {
      title: "Seller Name",
      dataIndex: "seller_name",
      key: "seller_name",
      filterIndex: "seller_name",
    },
    {
      title: "Address",

      render: (e) => {
        return (
          <div className="d-grid">
            <div className="d-flex">Address1: {e?.address1}</div>
            <div className="d-flex">Address2: {e?.address2}</div>
            <div className="d-flex">Address3: {e?.address3}</div>
          </div>
        );
      },
    },

    {
      title: "Created At (ET)",
      align: "left",

      render: (d) => {
        return (
          <div>
            {moment(new Date(parseInt(d.created_at) * 1000))
              .tz("America/New_York")
              .format("MM/DD/YYYY hh:mm A")}
          </div>
        );
      },
    },
    {
      title: "Primary User",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Partner",
      dataIndex: "partner",
      key: "partner",
    },
    {
      title: "Status",
      dataIndex: "pca_current_stage",
      key: "pca_current_stage",
      align: "center",
      render: (text) =>
        text ? (
          <Tag color={"blue"} bordered={false}>
            {text}
          </Tag>
        ) : (
          <Tag color={"blue"} bordered={false}>
            New Seller, Pending SIP Invite
          </Tag>
        ),
    },
    {
      title: "Action",
      dataIndex: "pca_current_stage",
      key: "pca_current_stage",
      align: "center",
      render: (text, row) => {
        if (!text) return <span>-</span>;

        return (
          <div>
            {(text === "SIP Invite Sent, Pending Acceptance" ||
              text === "Permissions Assigned, Pending Verification") && (
              <span
                onClick={(e) => {
                  message.destroy();
                  message.loading("Loading...", 0);
                  const obj = {
                    pca_current_stage:
                      text === "SIP Invite Sent, Pending Acceptance"
                        ? "SIP Invite Accepted, Pending Permissions" //
                        : "Permissions Verified, PCA is activated", // right
                    pca_connected:
                      text === "SIP Invite Sent, Pending Acceptance"
                        ? 2 //
                        : 1, // right,
                    pca_close_status: 0,
                    action_type:
                      text === "SIP Invite Sent, Pending Acceptance"
                        ? "adminAccepted" //
                        : "accessCompleted", // right
                  };
                  PCAUpdate(obj, row?.id);
                }}
              >
                <Icons type="check" />
              </span>
            )}

            <svg
              width={24}
              onClick={(e) => {
                message.destroy();
                message.loading("Loading...", 0);
                const obj = {
                  pca_current_stage:
                    text === "Permissions Verified, PCA is activated"
                      ? "SIP Invite Accepted, Pending Permissions"
                      : text === "Permissions Assigned, Pending Verification" ||
                        text === "Permissions Verified, PCA is activated"
                      ? "SIP Invite Accepted, Pending Permissions"
                      : text === "Permissions Verified, PCA is activated"
                      ? ""
                      : "",

                  action_type:
                    text === "Permissions Verified, PCA is activated"
                      ? "disConnected" //"adminAccepted"
                      : "",
                  pca_connected:
                    text === "Permissions Verified, PCA is activated" ? 3 : 2,
                  pca_close_status: 0,
                };
                PCAUpdate(obj, row?.id);
              }}
              className="ms-2 cursor-pointer"
              height={24}
              fill="#065ad8"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M7.942 3.791c.3.3.3.785 0 1.084L6.184 6.633H14.3a6.9 6.9 0 1 1 0 13.8H8.167a.767.767 0 0 1 0-1.533H14.3a5.367 5.367 0 1 0 0-10.733H6.184l1.758 1.758a.767.767 0 0 1-1.084 1.084L3.79 7.942c-.3-.3-.3-.785 0-1.084L6.858 3.79c.3-.3.785-.3 1.084 0Z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        );
      },
    },
    {
      title: "Connections",

      align: "center",
      render: (e) => {
        return (
          <div className="d-grid gap-2" style={{ justifyItems: "start" }}>
            <div>
              <Checkbox checked={e?.amazon_connected === 1} />
              <span className="ms-2">SellerCentral</span>
            </div>
            <div>
              <Checkbox checked={e?.amazon_ads_connected === 1} />
              <span className="ms-2">Advertising API</span>
            </div>
            <div>
              <Checkbox checked={e?.pca_connected === 1} />
              <span className="ms-2">Premium Access</span>
            </div>
          </div>
        );
      },
    },
    // {
    //   title: "Connections",
    //   dataIndex: "connections",
    //   key: "connections",
    //   align: "center",
    //   render: (e) => {
    //     return Array(e)
    //       ?.fill(1)
    //       ?.map((d, i) => (
    //         <i key={i} className="ki-duotone ki-electricity text-success fs-1">
    //           <span className="path1" />
    //           <span className="path2" />
    //           <span className="path3" />
    //           <span className="path4" />
    //           <span className="path5" />
    //           <span className="path6" />
    //           <span className="path7" />
    //           <span className="path8" />
    //           <span className="path9" />
    //           <span className="path10" />
    //         </i>
    //       ));
    //   },
    // },
  ];

  useEffect(() => {
    let interval;
    if (defaultSubActiveKey == 2 || defaultSubActiveKey == 0) {
      interval = setInterval(() => {
        getList({
          ...filters,
          connected: findKey?.[defaultActiveKey] || "amazonConnected",
          connected_status: defaultSubActiveKey || "1",
          page: page,
          pageSize: pageSize,
        });
      }, 10000);
    }

    return () => {
      if (defaultSubActiveKey == 2 || defaultSubActiveKey == 0) {
        clearInterval(interval);
      }
    };
  }, [defaultSubActiveKey]);

  const updateUrl = (url) => {
    const urlObj = new URL("http://example.com" + url);

    urlObj.searchParams.delete("user-id");
    return urlObj.pathname + urlObj.search;
  };

  const updatedUrl = updateUrl(`${location?.pathname}${location?.search}`);

  const searchParams = new URLSearchParams(location.search)?.get("user-id");
  if (searchParams) {
    return (
      <EditPage
        id={searchParams}
        UpdateAction={UpdateAction}
        // setSelectedRow={setSelectedRow}
        onClose={() => history.push(updatedUrl)}
      />
    );
  }

  return (
    <Wrapper
      id="kt_content_container"
      className="custom-ui container-fluid mt-5"
    >
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card" id="menu_filter_listing">
            <div className="card-body ">
              <h5 className="primaryHeading2 text-md-left">Sellers Search</h5>
              <div className="d-flex align-items-end">
                <div className="form-group">
                  <label htmlFor="Company Name">Seller Name </label>
                  <Input
                    size="large"
                    id="seller_name"
                    allowClear
                    name="seller_name"
                    value={filters?.company_name}
                    onPressEnter={() => {}}
                    onChange={(e) => {
                      setFilters((prev) => {
                        if (!e.target.value && prev?.company_name) {
                          setFilters({
                            ...filters,
                            company_name: "",
                          });
                          getList({
                            ...filters,
                            company_name: "",
                            page: page,
                            pageSize: pageSize,
                          });
                          setLoading(true);
                        }
                        return {
                          ...filters,
                          company_name: e.target.value,
                        };
                      });
                    }}
                  />
                </div>

                <button
                  onClick={() => {
                    getList({
                      ...filters,
                      page: page,
                      pageSize: pageSize,
                    });
                    setLoading(true);
                  }}
                  className="btn btn-sm ms-3 btn-search"
                  style={{ position: "relative", top: "-1px" }}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
            <div className="card-header" style={{ minHeight: "50px" }}>
              <div
                className="card-title m-0"
                style={{ alignItems: "flex-end" }}
              >
                <Tabs
                  activeKey={defaultActiveKey}
                  onChange={(e) => {
                    history.push(`/sellers?tabs=${e}&subTab=1`);
                    setFilters({
                      ...filters,
                      connected: findKey?.[e],
                      connected_status: "1",
                    });
                    getList({
                      ...filters,
                      connected: findKey?.[e],
                      connected_status: "1",
                      page: page,
                      pageSize: pageSize,
                    });
                    setLoading(true);
                    setSelectedTab(e);
                  }}
                  centered
                  items={[
                    "SellerCentral",
                    "Advertising API",
                    "Premium Access",
                  ].map((_, i) => {
                    const id = String(i + 1);
                    return {
                      label: <span className="px-4">{_}</span>,
                      key: id,
                    };
                  })}
                />
              </div>
              <div className="card-toolbar">
                <Segmented
                  options={
                    selectedTab === "3"
                      ? [
                          {
                            label: "Active",
                            value: "1",
                          },
                          {
                            label: "Inactive",
                            value: "0",
                          },
                          {
                            label: "Disconnected",
                            value: "3",
                          },
                          {
                            label: "Pending",
                            value: "2",
                          },
                        ]
                      : [
                          {
                            label: "Active",
                            value: "1",
                          },
                          {
                            label: "Inactive",
                            value: "0",
                          },
                          {
                            label: "Disconnected",
                            value: "2",
                          },
                        ]
                  }
                  value={defaultSubActiveKey}
                  onChange={(e) => {
                    history.push(
                      `/sellers?tabs=${
                        queryParams.get("tabs") || "1"
                      }&subTab=${e}`
                    );
                    setFilters({
                      ...filters,
                      connected_status: e,
                    });
                    getList({
                      ...filters,
                      connected_status: e,
                      page: page,
                      pageSize: pageSize,
                    });
                    setLoading(true);
                  }}
                />
              </div>
            </div>

            <div className="card-body pt-0">
              <Table
                dataSource={list}
                fixed={true}
                onChange={handleChange}
                sticky={{
                  offsetHeader: "0px",
                }}
                // columns={selectedTab == 3 ? columnsPremium : columns}
                columns={(selectedTab == 3 ? columnsPremium : columns)?.map(
                  (d) => ({
                    ...d,
                    ...(d?.filterIndex ? PropsFilter(d?.filterIndex) : ""),
                    render: (props, row, index) =>
                      RenderTable(props, row, index, d),
                  })
                )}
                pagination={false}
                rowClassName={(record, index) => {
                  return index % 2 === 0 ? "even-row" : "odd-row";
                }}
                bordered
                size="small"
                loading={loading}
                scroll={{ x: "max-content" }}
              />
            </div>
            <div className="card-footer pt-0 pb-5">
              <Pagination
                loading={loading}
                pageSize={pageSize}
                page={page}
                totalPage={totalPage}
                onPerPage={(e) => {
                  setLoading(true);
                  setPageSize(e);
                  setPage(1);
                  getList({
                    ...filters,
                    page: 1,
                    pageSize: e,
                  });
                }}
                onPageNo={(e) => {
                  setLoading(true);
                  setPage(e);

                  getList({
                    ...filters,
                    page: e,
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {contextHolder}
    </Wrapper>
  );
};

export default Sellers;
