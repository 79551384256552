import { Button, Spin, Tabs, message } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import AsinPerformance from "./lib/asin-performance";
import COGS from "./lib/cogs";
import ExternalTraffic from "./lib/external-traffic";
import Financial from "./lib/financial";
import Items from "./lib/items";
import Orders from "./lib/orders";
import SbCampaigns from "./lib/sb-campaigns";
import SbPurchases from "./lib/sb-purchases";
import SdCampaigns from "./lib/sd-campaigns";
import SpCampaigns from "./lib/sp-campaigns";
import SpPurchases from "./lib/sp-purchases";
import SpTargeting from "./lib/sp-targeting";
import Sqp from "./lib/sqp";
import { Wrapper } from "./style";
import { GlobalContext } from "../../../common-context";
import CredentialsError from "../../credential-page";
import SbTargeting from "./lib/sb-targeting";
import SdTargeting from "./lib/sd-targeting";
import { MakeApiCall } from "../../../apis";
import SPCampaignPlacement from "./lib/sp-campaign-placement";
import SBCampaignPlacement from "./lib/sb-campaign-placement";
import SearchCatalogPerformance from "./lib/search-catalog-performance";
import TopSearchTerms from "./lib/top-search-terms";
import { useHistory, useLocation } from "react-router-dom";

const { TabPane } = Tabs;
const DataSources = ({ menusList }) => {
  const location = useLocation();
  const history = useHistory();

  const queryParams = new URLSearchParams(location.search);
  const defaultActiveKey = queryParams.get("tabs") || "1";

  const contextValue = useContext(GlobalContext);
  const [locationData, setLocationData] = useState({});
  const getLocationData = async (data) => {
    const response = await MakeApiCall(
      `menu-content-data?menu_item_id=12`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setLocationData(response?.data);
    } else {
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    getLocationData();
    return () => {};
  }, []);

  const [tabsTotalWidth, setTabsTotalWidth] = useState(0);
  const [translateX, setTranslateX] = useState(0);

  useEffect(() => {
    const calculateTabsWidth = () => {
      setTranslateX(0);
      setTabsTotalWidth(
        document.getElementsByClassName("ant-tabs-nav-list")?.[0]?.clientWidth -
          document.getElementsByClassName("ant-tabs-nav-wrap")?.[0]?.clientWidth
      );
    };

    // Calculate width on initial render
    setTimeout(() => {
      calculateTabsWidth();
    }, 500);

    // Optionally, you can add an event listener to recalculate on window resize
    window.addEventListener("resize", calculateTabsWidth);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", calculateTabsWidth);
    };
  }, []);

  const handleLeftButtonClick = () => {
    setTranslateX(0);
  };

  const handleRightButtonClick = () => {
    setTranslateX((prev) => {
      return -tabsTotalWidth;
    });
  };

  useEffect(() => {
    // Apply the transform style to the ant-tabs-nav-list element
    const navList = document.querySelector(".ant-tabs-nav-list");
    if (navList) {
      navList.style.transform = `translate(${translateX}px, 0px)`;
    }
  }, [translateX]);

  const { lws, sp } = contextValue?.data?.credentialsStatus;

  if (
    (lws !== 1 || sp !== 1) &&
    menusList?.find((d) => d?.url === "data-sources")?.is_restricted === 1
  ) {
    return <CredentialsError type="data-sources" />;
  }
  return (
    <Wrapper
      className="scroll-show px-10"
      style={{ height: "calc(100vh - 208px)", overflow: "auto" }}
    >
      <Tabs
        onChange={(e) => {
          history.push(`/data-sources?tabs=${e}`);
        }}
        type="line"
        defaultActiveKey={defaultActiveKey}
        tabPosition="top"
        moreIcon={
          <i
            className="ki-duotone ki-to-left ms-1 fs-3"
            style={{
              transform: "rotate(26deg)",
              position: "relative",
              top: "2px",
              left: "6px",
            }}
          />
        }
        tabBarExtraContent={{
          left: (
            <Button onClick={handleLeftButtonClick} type="link">
              <span
                style={{
                  transform: "rotate(180deg)",
                }}
                className="svg-icon svg-icon-primary svg-icon-1hx"
              >
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.3"
                    d="M12.9 10.7L3 5V19L12.9 13.3C13.9 12.7 13.9 11.3 12.9 10.7Z"
                    fill="currentColor"
                  />
                  <path
                    d="M21 10.7L11.1 5V19L21 13.3C22 12.7 22 11.3 21 10.7Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </Button>
          ),
          right: (
            <Button onClick={handleRightButtonClick} type="link">
              <span className="svg-icon svg-icon-primary svg-icon-1hx">
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.3"
                    d="M12.9 10.7L3 5V19L12.9 13.3C13.9 12.7 13.9 11.3 12.9 10.7Z"
                    fill="currentColor"
                  />
                  <path
                    d="M21 10.7L11.1 5V19L21 13.3C22 12.7 22 11.3 21 10.7Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </Button>
          ),
        }}
        tabBarGutter={16}
      >
        {(lws === 1 ||
          menusList?.find((d) => d?.url === "data-sources")?.is_restricted ===
            0) && (
          <>
            <TabPane tab="SP Campaigns" key="1">
              <SpCampaigns locationData={locationData} />
            </TabPane>
            <TabPane tab="SB Campaigns" key="5">
              <SbCampaigns locationData={locationData} />
            </TabPane>
            <TabPane tab="SD Campaigns" key="7">
              <SdCampaigns locationData={locationData} />
            </TabPane>

            <TabPane tab="SP Targeting" key="2">
              <SpTargeting locationData={locationData} />
            </TabPane>
            <TabPane tab="SB Targeting" key="17">
              <SbTargeting locationData={locationData} />
            </TabPane>
            <TabPane tab="SD Targeting" key="18">
              <SdTargeting locationData={locationData} />
            </TabPane>
            <TabPane tab="SP Purchases" key="3">
              <SpPurchases locationData={locationData} />
            </TabPane>
            <TabPane tab="SB Purchases" key="4">
              <SbPurchases locationData={locationData} />
            </TabPane>
            <TabPane tab="SP Campaigns Placement" key="19">
              <SPCampaignPlacement locationData={locationData} />
            </TabPane>
            <TabPane tab="SB Campaigns Placement" key="20">
              <SBCampaignPlacement locationData={locationData} />
            </TabPane>
          </>
        )}
        {(sp === 1 ||
          menusList?.find((d) => d?.url === "data-sources")?.is_restricted ===
            0) && (
          <>
            <TabPane tab="Items" key="8">
              <Items locationData={locationData} />
            </TabPane>
            <TabPane tab="ASIN Performance" key="9">
              <AsinPerformance locationData={locationData} />
            </TabPane>

            <TabPane tab="External Traffic" key="12">
              <ExternalTraffic locationData={locationData} />
            </TabPane>
            <TabPane tab="Financial" key="13">
              <Financial locationData={locationData} />
            </TabPane>
            <TabPane tab="Orders" key="14">
              <Orders locationData={locationData} />
            </TabPane>
            <TabPane tab="SQP" key="15">
              <Sqp locationData={locationData} />
            </TabPane>
            <TabPane tab="Search Catalog Performance" key="21">
              <SearchCatalogPerformance locationData={locationData} />
            </TabPane>
            <TabPane tab="Top Search Terms" key="22">
              <TopSearchTerms locationData={locationData} />
            </TabPane>
            <TabPane tab="COGS" key="16">
              <COGS locationData={locationData} />
            </TabPane>
          </>
        )}
      </Tabs>
    </Wrapper>
  );
};

export default DataSources;
