import { Checkbox, DatePicker, Empty, Spin, Tag, Tooltip, message } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { MakeApiCall } from "../../../../apis";
import { GlobalContext } from "../../../../common-context";
import { TableLoading } from "../../../../components/table-animation";
import PlayView from "../../../../components/video";
import { numberformat, updatePrevDates } from "../../../../config";
import CredentialsError from "../../../credential-page";
import { Wrapper } from "../style";
import StackedBarLineChart from "./StackedBarLineChart";
import CardsView from "./cards";
import { GraphMetric } from "./financial";
import Config from "./lib/config";
import LineItemsSales from "./line-items-sales";
import Sales360OrderGraph from "./sales-360-order";
import Sales360Pie from "./sales-360-pie";
import InfoVideo from "../../../../components/header-icons";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
dayjs.extend(quarterOfYear);
const Sales360 = (props) => {
  const { pageTitle, menusList } = props;
  const [visible, setVisible] = useState(false);
  const chartRef = useRef(null);
  const [viewConfig, setViewConfig] = useState(false);
  const [filters, setFilters] = useState({
    start_date: dayjs().startOf("month").format("YYYY-MM-DD"),
    end_date: dayjs().format("YYYY-MM-DD"),
  });
  const [filters2, setFilters2] = useState({
    start_date: dayjs().startOf("month").format("YYYY-MM-DD"),
    end_date: dayjs().format("YYYY-MM-DD"),
  });
  const [selectedRange2, setSelectedRange2] = useState("This Month");
  const [selectedRange, setSelectedRange] = useState("This Month");
  const [selectedCard, setSelectedCard] = useState({});
  const [restart, setRestart] = useState(true);

  const [tabelLoading, setTabelLoading] = useState(true);
  const [tabelLoading2, setTabelLoading2] = useState(true);

  const [overviewLoading, setOverviewLoading] = useState(true);
  const [overviewFilter, setOverviewFilter] = useState(
    dayjs().subtract(1, "day").format("YYYY-MM-DD")
  );
  const [overviewData, setOverviewData] = useState({});

  const signFind = {
    salesData: "$",
    grossProfitData: "$",
    gpMarginData: "%",
    expensesData: "$",
    totalProfitData: "$",
    netMarginData: "%",
    refundData: "$",
  };

  const a = "100";
  const l = "#F1F1F2";
  const green = "#50cd89";
  const yellow = "#ffc803";
  const red = "#f1416c";
  const [locationData, setLocationData] = useState({});
  const getLocationData = async (data) => {
    const response = await MakeApiCall(
      `menu-content-data?menu_item_id=3`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setLocationData(response?.data);
    } else {
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    getLocationData();
    return () => {};
  }, []);

  const optionsComman = {
    chart: {
      fontFamily: "Montserrat",
      type: "area",
      height: a,
      toolbar: { show: !1 },
    },
    legend: { show: !1 },
    dataLabels: { enabled: !1 },
    fill: { type: "solid", opacity: 0 },
    stroke: { curve: "smooth", show: !0, width: 2 },
    xaxis: {
      axisBorder: { show: !1 },
      axisTicks: { show: !1 },
      labels: { show: !1 },
      crosshairs: {
        position: "front",
        stroke: { width: 1, dashArray: 3 },
      },
      tooltip: {
        enabled: !0,
        formatter: void 0,
        offsetY: 0,
        style: { fontSize: "12px" },
      },
    },
    yaxis: { labels: { show: !1 } },
    states: {
      normal: { filter: { type: "none", value: 0 } },
      hover: { filter: { type: "none", value: 0 } },
      active: {
        allowMultipleDataPointsSelection: !1,
        filter: { type: "none", value: 0 },
      },
    },
    tooltip: {
      enabled: false,
      style: { fontSize: "12px" },
      x: {
        formatter: function (e) {
          return "Feb " + e;
        },
      },
      y: {
        formatter: function (e) {
          return "$" + e + "K";
        },
      },
    },

    grid: {
      borderColor: l,
      strokeDashArray: 4,
      padding: { top: 0, right: -20, bottom: -20, left: -20 },
      yaxis: { lines: { show: !0 } },
    },
    markers: {
      size: 2,
      colors: "#FFF",

      hover: {
        size: 3,
      },
    },
  };

  const getArrowColor = (value) => {
    if (!value || value == 0 || value === "Flat") {
      return "rotateX(92deg)";
    }
    if (value === "FLAT") {
      return "rotateX(92deg)";
    }
    if (value === "Falling") {
      return "rotateX(180deg)";
    }
    if (value === "FALLING") {
      return "rotateX(180deg)";
    }
    if (value === "Rising") {
      return "rotateX(0deg)";
    }
    if (value === "RISING") {
      return "rotateX(0deg)";
    }
    return "rotateX(0deg)";
  };

  const borderColor = {
    1: "#E31715",
    2: "#FDC71E",
    3: "#00B55D",
  };
  const backgroundColor = {
    1: "#FFF6F6",
    2: "#FFFEF6",
    3: "#F6FFFA",
  };

  const getBorderColor = (value) => {
    if (value == 0 || value === "STAGNANT") {
      return 2;
    }
    if (value === "DOWN") {
      return 1;
    }
    if (value === "UP") {
      return 3;
    }
    return 3;
  };

  const signConvert = (value) => {
    return value.replace("$-", "-$");
  };

  const getFooter = (value) => {
    if (value == 0 || value === "Flat") {
      return 2;
    }
    if (value === "FLAT") {
      return 2;
    }
    if (value === "Falling") {
      return 1;
    }
    if (value === "FALLING") {
      return 1;
    }
    if (value === "Rising") {
      return 3;
    }
    if (value === "RISING") {
      return 3;
    }
    return 3;
  };
  const updateChartOptions = (color) => {
    return {
      ...optionsComman,
      stroke: { ...optionsComman.stroke, colors: [color] },
      colors: [color],
      markers: {
        ...optionsComman.markers,
        strokeColors: color,
      },
      xaxis: {
        ...optionsComman?.xaxis,
        crosshairs: {
          ...optionsComman?.xaxis.crosshairs,
          stroke: {
            ...optionsComman?.xaxis.crosshairs.stroke,
            colors: [color],
          },
        },
      },
    };
  };

  const getOp = (value) => {
    switch (value) {
      case 0:
      case "STAGNANT":
        return updateChartOptions(yellow);
      case "DOWN":
        return updateChartOptions(red);
      case "UP":
        return updateChartOptions(green);
      default:
        return updateChartOptions(green); // or any default color
    }
  };

  const graphDataFiltered =
    overviewData?.[selectedCard?.root]?.metric_chart_data?.map(
      (d) => d?.metric_value
    ) || [];
    const graphDataFilteredEma_value =
    overviewData?.[selectedCard?.root]?.metric_chart_data?.map(
      (d) => d?.ema_value
    ) || [];

  const numberformat_ = (num) => {
    // Format number with no decimals
    return num.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const minGraph = Math.min(...([...graphDataFiltered, ...graphDataFilteredEma_value] || []));
  const maxGraph = Math.max(...([...graphDataFiltered, ...graphDataFilteredEma_value] || []));
  const revenu_status = {
    chart: {
      height: 355,

      type: "area",
      toolbar: { show: !1 },
      dropShadow: {
        enabled: !0,
        top: 12,
        left: 0,
        bottom: 0,
        right: 0,
        blur: 2,
        color: "rgba(132, 145, 183, 0.4)",
        opacity: 0.45,
      },
    },
    colors: [
      borderColor?.[
        getBorderColor(overviewData?.[selectedCard?.root]?.ema_trend)
      ],
      "#000",
    ],
    markers: {
      size: 4,
      colors: "#FFF",
      strokeColors:
        borderColor?.[
          getBorderColor(overviewData?.[selectedCard?.root]?.ema_trend)
        ],
      hover: {
        size: 5,
      },
    },
    xaxis: {
      tickAmount: 7,

      labels: {
        rotateAlways: -45,
        offsetX: 10,
        style: {
          fontWeight: "bold",
        },
      },
    },
    dataLabels: { enabled: !1 },
    stroke: {
      show: !0,
      curve: ["straight", "smooth"],
      width: [3, 2],
      dashArray: [0, 4],
      lineCap: "round",
    },
    dataLabels: { enabled: !1 },
    stroke: {
      show: !0,
      curve: "smooth",
      width: [3, 3],
      dashArray: [0, 4],
      lineCap: "round",
    },
    labels:
      overviewData?.[selectedCard?.root]?.metric_chart_data?.map(
        (d) => d?.date || d?.weekNum || d?.weekDate || d?.chartDate
      ) || [],
    yaxis: [
      {
        seriesName: "",
        tickAmount: 7,
        axisTicks: {
          show: true,
        },
        min: minGraph == maxGraph ? 0 : minGraph,
        max: maxGraph === 0 ? 100 : maxGraph,
        labels: {
          formatter: function (_) {
            return (
              (selectedCard?.prefix || "") +
              numberformat_(_) +
              (selectedCard?.suffix || "")
            )?.replace(
              `${selectedCard?.prefix || ""}-`,
              `-${selectedCard?.prefix || ""}`
            );
          },
        },
      },
      {
        seriesName: "EMA",
        tickAmount: 7,
        opposite: true,
        min: minGraph == maxGraph ? 0 : minGraph,
        max: maxGraph === 0 ? 100 : maxGraph,
        axisTicks: {
          show: true,
        },

        title: {
          text: "Path",
          style: {
            fontWeight: 700,
            color: "#065ad8",
            fontSize: "1.15rem",
          },
        },
        labels: {
          formatter: function (_) {
            return (
              (selectedCard?.prefix || "") +
              numberformat_(_) +
              (selectedCard?.suffix || "")
            )?.replace(
              `${selectedCard?.prefix || ""}-`,
              `-${selectedCard?.prefix || ""}`
            );
          },
        },
      },
    ],
    grid: {
      strokeDashArray: 3,
      yaxis: { lines: { show: !1 } },
      xaxis: {
        lines: {
          show: true,
          offsetX: 0,
        },
      },
    },
    legend: { show: !0 },
    fill: {
      type: "gradient",
      gradient: {
        type: "vertical",
        shadeIntensity: 1,
        inverseColors: !1,
        opacityFrom: 0.05,
        opacityTo: 0.05,
        stops: [45, 100],
      },
    },
    tooltip: {
      marker: {
        show: true,
      },
      x: {
        formatter: function (
          value,
          { series, seriesIndex, dataPointIndex, w }
        ) {
          const label = w?.globals?.categoryLabels?.[value - 1] || "";
          return `<strong>${label}</strong>`;
        },
      },
      y: {
        formatter: function (
          value,
          { series, seriesIndex, dataPointIndex, w }
        ) {
          const prefix = selectedCard?.prefix || "";
          const suffix = selectedCard?.suffix || "";

          return signConvert(
            prefix +
              numberformat(parseFloat(parseFloat(value || 0).toFixed(2))) +
              suffix
          );
        },
      },
    },
  };

  const findYear = dayjs(filters?.start_date, "YYYY-MM-DD").format("YYYY");

  const findMonth = dayjs(filters?.start_date, "YYYY-MM-DD").format("MMM YY");
  const currentDate = dayjs(filters?.start_date, "YYYY-MM-DD");
  const lastMonth = currentDate.subtract(1, "month").format("MMM YY");
  const oneYearAgo = currentDate.subtract(1, "year").format("MMM YY");

  const findLastMonth = dayjs(filters?.start_date, "YYYY-MM-DD").format(
    "MMM YY"
  );
  const currentDate_ = dayjs(filters?.start_date, "YYYY-MM-DD");
  const findPrevMonth = currentDate_.subtract(1, "month").format("MMM YY");
  const oneYearAgo_ = currentDate_.subtract(1, "year").format("MMM YY");
  const previousYear = dayjs(filters?.start_date, "YYYY-MM-DD")
    .subtract(1, "year")
    .format("MMM YY");
  const selectedPeriod = `${dayjs(filters?.start_date, "YYYY-MM-DD").format(
    "MMM DD 'YY"
  )} - ${dayjs(filters?.end_date, "YYYY-MM-DD").format("MMM DD 'YY")}`;
  const columnsFilter = {
    This_Month: {
      [`${findMonth} Actual`]: "this_month",
      [`_`]: "this_month_ratio",
      [`${findMonth} Projected`]: "projected_month",
      [`__`]: "projected_month_ratio",
      [lastMonth]: "last_month",
      [`___`]: "last_month_ratio",
      Variance: "variance",
      "Variance Pect": "variance_pect",
      [oneYearAgo]: "this_month_last_year",
      "YoY Variance": "tmly_variance",
      " Variance Pect": "tmly_variance_pect",
    },
    custom: {
      [`${selectedPeriod}`]: "selected_range",
      [`_`]: "selected_range_ratio",
      [`${moment(
        updatePrevDates({
          endDate: filters?.end_date,
          startDate: filters?.start_date,
        })
      ).format("MMM DD 'YY")} - ${dayjs(filters?.start_date, "YYYY-MM-DD")
        .subtract(1, "day")
        .format("MMM DD 'YY")}`]: "previous_period",
      [`__`]: "previous_period_ratio",
      Variance: "variance",
      "Variance Pect": "variance_pect",
    },
    Last_Month: {
      [findLastMonth]: "last_month",
      [`_`]: "last_month_ratio",
      [findPrevMonth]: "previous_month",
      [`___`]: "previous_month_ratio",
      Variance: "variance",
      "Variance Pect": "variance_pect",
      [oneYearAgo_]: "last_month_last_year",

      "YoY Variance": "lmly_variance",
      "Variance Pect ": "lmly_variance_pect",
    },
    Yesterday: {
      Yesterday: "yesterday",
      [`_`]: "yesterday_ratio",
      "Day Before Yesterday ": "day_before_yesterday",
      [`__`]: "day_before_yesterday_ratio",
      Variance: "variance",
      "Variance Pect": "variance_pect",
      "Yesterday Last Year": "yesterday_last_year",

      "YoY Variance": "yly_variance",
      " Variance Pect": "yly_variance_pect",
    },
    This_Week: {
      "This Week Actual": "this_week",
      [`_`]: "this_week_ratio",
      "This Week Projected ": "projected_week",
      [`__`]: "projected_week_ratio",
      "Last Week": "last_week",
      [`___`]: "last_week_ratio",
      Variance: "variance",
      "Variance Pect": "variance_pect",
      "Last Year This Week": "last_year_this_week",
      "YoY Variance": "lytw_variance",
      " Variance Pect": "lytw_variance_pect",
    },
    This_Quarter: {
      "This Quarter Actual": "this_quarter",
      [`_`]: "this_quarter_ratio",
      "This Quarter Projected": "projected_quarter",
      [`__`]: "projected_quarter_ratio",
      "Last Quarter": "last_quarter",
      [`___`]: "last_quarter_ratio",
      Variance: "variance",
      "Variance Pect": "variance_pect",
      "This Quarter Last Year": "this_quarter_last_year",
      "YoY Variance": "tqly_variance",
      " Variance Pect": "tqly_variance_pect",
    },
    Last_Year: {
      [`${findYear} Q1`]: "last_year_q1",
      [`_`]: "last_year_q1_ratio",
      [`${findYear} Q2`]: "last_year_q2",
      [`__`]: "last_year_q2_ratio",
      [`${findYear} Q3`]: "last_year_q3",
      [`___`]: "last_year_q3_ratio",
      [`${findYear} Q4`]: "last_year_q4",
      [`____`]: "last_year_q4_ratio",
      Total: "last_year_total",
    },
    This_Year: {
      [`${findYear} Q1`]: "this_year_q1",
      [`_`]: "this_year_q1_ratio",
      [`${findYear} Q2`]: "this_year_q2",
      [`__`]: "this_year_q2_ratio",
      [`${findYear} Q3`]: "this_year_q3",
      [`___`]: "this_year_q3_ratio",
      [`${findYear} Q4`]: "this_year_q4",
      [`___`]: "this_year_q4_ratio",
      [`${findYear} Total `]: "this_year_total",
      [`${previousYear} Total`]: "last_year_total",
      [`This Year Projected `]: "projected_year",
      [`Variance`]: "variance",
      [`Variance Pect`]: "variance_pect",
    },
  };

  const errorIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={17}
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2927 5.49996C12.7119 5.49996 12.945 5.98493 12.6831 6.31231L8.39011 11.6786C8.18995 11.9288 7.8094 11.9288 7.60924 11.6786L3.31622 6.31231C3.05431 5.98493 3.2874 5.49996 3.70665 5.49996L12.2927 5.49996Z"
        fill="#E31715"
      />
    </svg>
  );

  const successIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={17}
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.70633 11.5C3.28708 11.5 3.05399 11.015 3.31589 10.6877L7.60891 5.32138C7.80908 5.07117 8.18962 5.07117 8.38978 5.32138L12.6828 10.6877C12.9447 11.015 12.7116 11.5 12.2924 11.5L3.70633 11.5Z"
        fill="#00B55D"
      />
    </svg>
  );

  const getValueShow = (value) => {
    try {
      if (value == 0) {
        return <b style={{ color: "#000" }}>{value}%</b>;
      } else if (parseFloat(value?.split(",")?.join("")) < 0) {
        return (
          <b style={{ color: "red" }}>
            {errorIcon}
            {value}%
          </b>
        );
      }
      return (
        <b style={{ color: "green" }}>
          {successIcon}
          {value}%
        </b>
      );
    } catch (error) {}
  };

  const presetRanges_ = [
    {
      label: "Yesterday",
      value: [dayjs().subtract(1, "day"), dayjs().subtract(1, "day")],
    },
    { label: "This Week", value: [dayjs().startOf("week"), dayjs()] },
    {
      label: "This Month",
      value: [dayjs().startOf("month"), dayjs().endOf("month")],
    },
    {
      label: "Last Month",
      value: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
    },
    {
      label: "This Quarter",
      value: [dayjs().startOf("quarter"), dayjs().endOf("quarter")],
    },
    {
      label: "This Year",
      value: [dayjs().startOf("year"), dayjs().endOf("year")],
    },
    {
      label: "Last Year",
      value: [
        dayjs().subtract(1, "year").startOf("year"),
        dayjs().subtract(1, "year").endOf("year"),
      ],
    },
  ];
  const presetRanges = presetRanges_.map((range) => {
    const start = range.value[0];
    let end = range.value[1];

    // Check if the end date is in the future
    if (end.isAfter(dayjs())) {
      end = dayjs(); // Replace with the current date
    }

    return {
      ...range,
      value: [start, end],
    };
  });
  function convertData(one) {
    const outputObject = {};

    const two = Object?.keys(Object.values(one)?.[0])?.map((d) => {
      Object?.keys(one).forEach((key) => {
        const num = Math.round(one?.[key]?.[d]);

        outputObject[key] = num.toLocaleString("en-US", {
          minimumFractionDigits: 0,
        });
      });

      return {
        type: d,
        ...outputObject,
      };
    });

    return two;
  }

  const disabledDate = (current) => {
    // Disable future dates
    return current && current >= moment().startOf("day");
  };
  const [otherStatic, setOtherStatic] = useState({});
  const [otherStatic2, setOtherStatic2] = useState({});
  const sales = ["paid_sales", "organic_sales", "external_sales"];
  const expenses = [
    "sponsored_products",
    "sponsored_brands",
    "sponsored_brands_video",
    "sponsored_display",
    "sponsored_television",
  ];
  const orders = ["order_sales", "unit_sold", "order_count", "aov", "aiv"];
  const [convertedData, setConvertedData] = useState([]);
  const [convertedData2, setConvertedData2] = useState([]);
  const [convertedData3, setConvertedData3] = useState([]);
  const [convertedData4, setConvertedData4] = useState([]);
  const [convertedData5, setConvertedData5] = useState([]);

  const [selectedGraph, setSelectedGraph] = useState([]);

  const [getAllLineItemList, setGetAllLineItemList] = useState([]);
  const [lineItemListLoading, setLineItemListLoading] = useState(false);
  const [selectedMetric, setSelectedMetric] = useState([]);

  const [graphData, setGraphData] = useState({});
  const [graphLoading, setGraphLoading] = useState(true);
  function capitalizeEachWord(str) {
    return str.replace(/\b\w/g, (match) => match.toUpperCase());
  }

  const [filtersLine, setFiltersLine] = useState({
    start_date: dayjs().startOf("month").format("YYYY-MM-DD"),
    end_date: dayjs().format("YYYY-MM-DD"),
  });
  const [selectedRangeLine, setSelectedRangeLine] = useState("This Month");
  const [selectedRow, setSelectedRow] = useState({});
  const [metricSettingList, setMetricSettingList] = useState([]);
  const [metricSettingLoading, setMetricSettingLoading] = useState(true);
  const [metricGraphLoading, setMetricGraphLoading] = useState(true);

  const [cardList, setCardList] = useState([]);

  const getMetricSetting = async (data) => {
    setMetricSettingLoading(true);
    const response = await MakeApiCall(
      `metric-setting?menu_item_id=3`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setMetricSettingList(response?.data);

      setMetricSettingLoading(false);
    } else {
      setMetricSettingLoading(false);

      message.warning(response?.message);
    }
  };
  const saveMetricSetting = async (data) => {
    setMetricSettingLoading(true);
    const response = await MakeApiCall(
      `metric-setting/save`,
      "post",
      data,
      true
    );

    if (response?.status) {
      getOverview();
      setViewConfig(false);
    } else {
      getOverview();

      message.warning(response?.message);
    }
  };
  const getOverview = async (data) => {
    getMetricSetting();
    setOverviewLoading(true);
    const response = await MakeApiCall(
      `sales/get-sales-overview?filter_date=${
        data || overviewFilter || ""
      }&menu_item_id=3`,
      "get",
      null,
      true
    );

    if (response?.status) {
      if (Object.keys(response?.data || {})?.length === 0) {
        setOverviewData({});
        setCardList([]);
        setSelectedCard({});
        setOverviewLoading(false);
        setMetricGraphLoading(false);
        return;
      }

      const cardList__ = Object.entries(response?.data || {})?.reduce(
        (acc, [d, v]) => {
          return [
            ...acc,
            {
              label: v?.metric_name,
              root: d,
              id: v?.metric_id,
              metric_slug: v?.metric_slug,
              prefix:
                v?.metric_unit === "Range$" || v?.metric_unit === "$"
                  ? "$"
                  : "",
              suffix:
                v?.metric_unit === "Range%" || v?.metric_unit === "%"
                  ? "%"
                  : "",
            },
          ];
        },
        []
      );

      setCardList(cardList__);
      setSelectedCard({
        ...cardList__?.[0],
      });
      setMetricGraphLoading(false);
      setOverviewData(response?.data);
      setOverviewLoading(false);
    } else {
      setOverviewData({});
      setCardList([]);
      setSelectedCard({});
      setOverviewLoading(false);
      setMetricGraphLoading(false);
      message.warning(response?.message);
    }
  };
  const getAllLineItemsList = async (data) => {
    setSelectedMetric(data?.metric_name);
    setLineItemListLoading(true);
    try {
      const response = await MakeApiCall(
        `sales/get-line-items?start_date=${
          data?.start_date || filters?.start_date || ""
        }&end_date=${data?.end_date || filters?.end_date || ""}&metric_name=${
          typeof data?.metric_name === "string"
            ? data?.metric_name
            : data?.metric_name?.join(",")
        }`,
        "get",
        null,
        true
      );

      if (response?.status) {
        setGetAllLineItemList(response?.data || []);

        setLineItemListLoading(false);
      } else {
        setGetAllLineItemList([]);
        setLineItemListLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setLineItemListLoading(false);

      message.destroy();
      message.error(error?.message);
    }
  };
  const getGraphData = async (data) => {
    setGraphLoading(true);
    try {
      const response = await MakeApiCall(
        `sales/get-metric-chart?start_date=${
          data?.start_date || filters?.start_date || ""
        }&end_date=${
          data?.end_date || filters?.end_date || ""
        }&selection_type=${
          data?.selection_type?.split(" ")?.join("_")?.toLowerCase() ||
          selectedRange?.split(" ")?.join("_")?.toLowerCase()
        }&menu_item_id=3`,
        "get",
        null,
        true
      );
      if (response?.status) {
        setGraphData(response?.data);
        setSelectedGraph(
          Object.keys(response?.data?.chartData)?.map((d) =>
            capitalizeEachWord(d?.toLowerCase())
          )
        );
        setGraphLoading(false);
      } else if (response?.status === false) {
        setGraphLoading(false);
        message.destroy();
        message.error(response?.message);
      }
    } catch (error) {
      message.destroy();
      message.error(error?.message);
      setGraphLoading(false);
    }
  };
  const getList = async (data) => {
    setTabelLoading(true);
    setOtherStatic({});
    setConvertedData([]);
    setConvertedData2([]);
    try {
      getGraphData(data);
      const response = await MakeApiCall(
        `sales/get-sales-summary?start_date=${
          data?.start_date || filters?.start_date || ""
        }&end_date=${
          data?.end_date || filters?.end_date || ""
        }&selection_type=${
          data?.selection_type?.split(" ")?.join("_")?.toLowerCase() ||
          selectedRange?.split(" ")?.join("_")?.toLowerCase()
        }`,
        "get",
        null,
        true
      );

      if (response?.status) {
        // getAllLineItemsList({
        //   ...data,
        //   metric_name: Object.keys(
        //     Object.values(response?.data || {})?.[0] || {}
        //   ),
        // });

        const one = convertData(response?.data)?.filter((item) =>
          sales.includes(item.type)
        );
        const two = convertData(response?.data)?.filter((item) =>
          expenses.includes(item.type)
        );
        setOtherStatic({
          total_net_sales: convertData(response?.data)?.filter((item) =>
            ["total_net_sales"].includes(item.type)
          )?.[0],
          total_paid_sales: convertData(response?.data)?.filter((item) =>
            ["total_paid_sales"].includes(item.type)
          )?.[0],
          brand_referral_bonus: convertData(response?.data)?.filter((item) =>
            ["brand_referral_bonus"].includes(item.type)
          )?.[0],
        });
        setConvertedData(one);
        setConvertedData2(two);
        setTabelLoading(false);
        // setTableList(response?.data);
      } else {
        setTabelLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setTabelLoading(false);
      setOtherStatic({});
      setConvertedData([]);
      setConvertedData2([]);
      message.destroy();
      message.error(error?.message);
    }
  };
  const getList2 = async (data) => {
    setTabelLoading2(true);
    setOtherStatic2({});
    setConvertedData3([]);
    setConvertedData4([]);
    setConvertedData5([]);
    try {
      const response = await MakeApiCall(
        `sales/get-orders-summary?start_date=${
          data?.start_date || filters2?.start_date || ""
        }&end_date=${
          data?.end_date || filters2?.end_date || ""
        }&selection_type=${
          data?.selection_type?.split(" ")?.join("_")?.toLowerCase() ||
          selectedRange2?.split(" ")?.join("_")?.toLowerCase()
        }`,
        "get",
        null,
        true
      );

      if (response?.status) {
        const one = convertData(response?.data)?.filter((item) =>
          sales.includes(item.type)
        );
        const two = convertData(response?.data)?.filter((item) =>
          expenses.includes(item.type)
        );
        const three = convertData(response?.data)?.filter((item) =>
          orders.includes(item.type)
        );

        setOtherStatic2({
          total_net_sales: convertData(response?.data)?.filter((item) =>
            ["total_net_sales"].includes(item.type)
          )?.[0],
          total_paid_sales: convertData(response?.data)?.filter((item) =>
            ["total_paid_sales"].includes(item.type)
          )?.[0],
        });
        setConvertedData3(one);
        setConvertedData4(two);
        setConvertedData5(three);
        setTabelLoading2(false);
      } else {
        setConvertedData3([]);
        setConvertedData4([]);
        setConvertedData5([]);
        setTabelLoading2(false);
        setOtherStatic2({});
        message.warning(response?.message);
      }
    } catch (error) {
      setConvertedData3([]);
      setConvertedData4([]);
      setConvertedData5([]);
      setTabelLoading2(false);
      setOtherStatic2({});
      message.destroy();
      message.error(error?.message);
    }
  };

  const handleDateRangeChange = (value, dateString) => {
    const selectedPresetRange = presetRanges.filter((range) => {
      return (
        dayjs(range?.value?.[0]).format("YYYY-MMM-DD") === dateString?.[0] &&
        dayjs(range?.value?.[1]).format("YYYY-MMM-DD") === dateString?.[1]
      );
    });

    const date1 = new Date(dateString?.[0]);
    const date2 = new Date(dateString?.[1]);

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = date2 - date1;

    // Convert milliseconds to days
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

    setSelectedRange(
      selectedPresetRange?.length !== 0
        ? selectedPresetRange?.[0]?.label
        : "custom"
    );
    setSelectedRange2(
      selectedPresetRange?.length !== 0
        ? selectedPresetRange?.[0]?.label
        : "custom"
    );
    setSelectedRangeLine(
      selectedPresetRange?.length !== 0
        ? selectedPresetRange?.[0]?.label
        : "custom"
    );
    return selectedPresetRange?.length !== 0
      ? selectedPresetRange?.[0]?.label
      : "custom";
  };
  const handleDateRangeChange2 = (value, dateString) => {
    const selectedPresetRange = presetRanges.filter((range) => {
      return (
        dayjs(range?.value?.[0]).format("YYYY-MMM-DD") === dateString?.[0] &&
        dayjs(range?.value?.[1]).format("YYYY-MMM-DD") === dateString?.[1]
      );
    });

    const date1 = new Date(dateString?.[0]);
    const date2 = new Date(dateString?.[1]);

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = date2 - date1;

    // Convert milliseconds to days
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

    setSelectedRange2(
      selectedPresetRange?.length !== 0
        ? selectedPresetRange?.[0]?.label
        : "custom"
    );

    setSelectedRange(
      selectedPresetRange?.length !== 0
        ? selectedPresetRange?.[0]?.label
        : "custom"
    );
    setSelectedRangeLine(
      selectedPresetRange?.length !== 0
        ? selectedPresetRange?.[0]?.label
        : "custom"
    );
    return selectedPresetRange?.length !== 0
      ? selectedPresetRange?.[0]?.label
      : "custom";
  };

  useEffect(() => {
    setTabelLoading(true);
    getList();

    getOverview();
    getList2();
    return () => {};
  }, []);

  const icons = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15}
      height={16}
      viewBox="0 0 15 16"
      fill="none"
      style={{
        position: "relative",
        top: "-4px",
        left: "0.5px",
      }}
    >
      <path
        d="M3.75 11.125L6.875 8L3.75 4.875M8.125 11.125L11.25 8L8.125 4.875"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const typeGraph = {
    sales_paid_sales: "bar",
    sales_organic_sales: "bar",
    sales_external_sales: "bar",
    sp_sales: "bar",
    sb_sales: "bar",
    sbv_sales: "bar",
    sd_sales: "bar",
    st_sales: "bar",
    units_paid_sales: "line",
    units_organic_sales: "line",
    units_external_sales: "line",
    sp_units: "line",
    sb_units: "line",
    sbv_units: "line",
    sd_units: "line",
    st_units: "line",
  };

  const _ = useRef(null);

  const colorList = [
    {
      key: "#EEC800",
      value: "yellow",
    },
    {
      key: "#0051D4",
      value: "blue",
    },
    {
      key: "#AE35B0",
      value: "purpal",
    },
    {
      key: "#009A9D",
      value: "Expenses",
    },
    {
      key: "#009DE8",
      value: "Profit",
    },

    {
      key: "#7438BA",
      value: "net",
    },
    {
      key: "#F97303",
      value: "Refunds",
    },
    {
      key: "#EEC800",
      value: "yellow",
    },
    {
      key: "#0051D4",
      value: "blue",
    },
    {
      key: "#AE35B0",
      value: "purpal",
    },
    {
      key: "#009A9D",
      value: "Expenses",
    },
    {
      key: "#009DE8",
      value: "Profit",
    },

    {
      key: "#7438BA",
      value: "net",
    },
    {
      key: "#F97303",
      value: "Refunds",
    },
    {
      key: "#009DE8",
      value: "Profit",
    },

    {
      key: "#7438BA",
      value: "net",
    },
    {
      key: "#F97303",
      value: "Refunds",
    },
  ];

  let series = Object?.entries(graphData?.chartData || {})?.map(
    ([key, value], i) => {
      return {
        name: capitalizeEachWord(key?.toLowerCase()),
        type: typeGraph?.[key],
        data: Object?.values(value)?.map((d) => Math.round(d || 0)),
        color: colorList?.map((r) => r?.key)?.[i],
      };
    }
  );
  const lastGraph = {
    series: series,
    options: {
      chart: {
        height: 350,

        type: "line",
        toolbar: { show: !1 },
      },
      stroke: {
        width: [0, 0, 0, 3, 3, 3, 3],
        curve: "smooth",
      },
      legend: {
        show: false,
        position: "left", // Change legend position to left
      },
      colors: series?.map((d) => d?.color),
      dataLabels: {
        enabled: false,
        enabledOnSeries: [1],
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return `$${numberformat(parseFloat(value).toFixed(0))}`;
          },
        },
      },
      tooltip: {
        enabled: true,
        followCursor: true,
        marker: {
          show: true,
        },
        y: {
          formatter: function (
            value,
            { series, seriesIndex, dataPointIndex, w }
          ) {
            const seriesName = lastGraph?.series?.[seriesIndex]?.name;
            const fSign = seriesName?.includes("Sales") ? "$" : "";
            const sSign = "";
            return (fSign + (value || 0) + sSign).replace("$-", "-$");
          },
        },
      },
      xaxis: {
        categories: graphData?.chartDate || [],
        tickPlacement: "on",

        labels: {
          // formatter: function (value, timestamp) {
          //   return dayjs(value, "MMM-DD").date() % 2 !== 0 ? value : "";
          // },
          rotate: -45,
          style: {
            fontSize: "12px",
            transform: "rotate(-45deg)",
          },
        },
      },
    },
  };
  const showMetrix = () => {
    setViewConfig(true);
  };
  const ratioView = (key) => {
    return key === "_" || key === "__" || key === "___" || key === "____";
  };

  const graphList = {
    Sales_paid_sales: "Paid Sales",
    Sales_organic_sales: "Organic Sales",
    Sales_external_sales: "External Sales",
    Sp_sales: "Sponsored Products",
    Sb_sales: "Sponsored Brand",
    Sbv_sales: "Sponsored Brand Video",
    Sd_sales: "Sponsored Display",
    St_sales: "Sponsored Television",

    Units_paid_sales: "Paid Sales (units)",
    Units_organic_sales: "Organic Sales (units)",
    Units_external_sales: "External Sales (units)",
    Sp_units: "Sponsored Products (units)",
    Sb_units: "Sponsored Brand (units)",
    Sbv_units: "Sponsored Brand Video (units)",
    Sd_units: "Sponsored Display (units)",
    St_units: "Sponsored Television (units)",

    Aov: "AOV",
  };

  const contextValue = useContext(GlobalContext);
  const { lws, sp } = contextValue?.data?.credentialsStatus;
  if (
    (lws !== 1 || sp !== 1) &&
    menusList?.find((d) => d?.url === "sales-360")?.is_restricted === 1
  ) {
    return <CredentialsError type="sales-360" />;
  }

  if (Object.keys(selectedRow)?.length !== 0) {
    return (
      <LineItemsSales
        close={() => setSelectedRow(false)}
        selectedRow={selectedRow}
        getAllLineItemList={getAllLineItemList}
        loading={lineItemListLoading}
        selectedMetric={selectedMetric}
        APIAll={getAllLineItemsList}
        setFiltersLine={setFiltersLine}
        filtersLine={filtersLine}
        selectedRangeLine={selectedRangeLine}
        setSelectedRangeLine={setSelectedRangeLine}
      />
    );
  }

  return (
    <Wrapper id="kt_content_container" className="container-fluid mt-2">
      <div className="row">
        <div className="header-title-page">
          <div className="d-flex align-items-center title-text">
            <h2 className="mb-0">{pageTitle}</h2>{" "}
            <InfoVideo
              data={locationData}
              title={"page_heading_s"}
              className={"ms-3"}
            />
          </div>
        </div>
      </div>
      <DatePicker
        value={dayjs(overviewFilter, "YYYY-MM-DD")}
        className="custom-date"
        style={{ width: "140px" }}
        format={"YYYY-MMM-DD"}
        // picker="week"
        disabledDate={disabledDate}
        allowClear={false}
        onChange={(e) => {
          getOverview(dayjs(e).format("YYYY-MM-DD"));
          setOverviewFilter(dayjs(e).format("YYYY-MM-DD"));
        }}
      />
      <div
        style={{
          justifyContent: viewConfig ? "flex-start" : "space-between",
        }}
        className="mb-6 top-bar-page-header mt-4"
      >
        <div className="page-header-info">
          <div className="discription-text">
            Sales 360 Metrics and Trends for{" "}
            {dayjs(overviewFilter, "YYYY-MM-DD").format("MM/DD/YYYY")}
          </div>
        </div>

        <div
          style={{
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: 20,
            display: "flex",
          }}
        >
          <div
            style={{
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 6,
              display: "flex",
            }}
          >
            <div
              style={{
                width: 13,
                height: 13,
                background: "#00B55D",
                borderRadius: 9999,
              }}
            />
            <div
              style={{
                color: "#00B55D",
                fontSize: 14,

                fontWeight: "700",

                wordWrap: "break-word",
              }}
            >
              Growth
            </div>
          </div>
          <div
            style={{
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 6,
              display: "flex",
            }}
          >
            <div
              style={{
                width: 13,
                height: 13,
                background: "#FDC71E",
                borderRadius: 9999,
              }}
            />
            <div
              style={{
                color: "#FDC71E",
                fontSize: 14,

                fontWeight: "700",

                wordWrap: "break-word",
              }}
            >
              Stagnant
            </div>
          </div>
          <div
            style={{
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 6,
              display: "flex",
            }}
          >
            <div
              style={{
                width: 13,
                height: 13,
                background: "#E31715",
                borderRadius: 9999,
              }}
            />
            <div
              style={{
                color: "#E31715",
                fontSize: 14,

                fontWeight: "700",

                wordWrap: "break-word",
              }}
            >
              Decline
            </div>
          </div>
        </div>
      </div>
      <div
        className="row g-5 g-xl-5 Financial-cus-row"
        style={{
          zoom: "90%",
        }}
      >
        <div className=" col-xl-8 mb-5 mb-xl-5 mh-450px overflow-auto">
          <CardsView
            cardList={cardList}
            type="D"
            setSelectedCard={setSelectedCard}
            backgroundColor={backgroundColor}
            getBorderColor={getBorderColor}
            overviewData={overviewData}
            borderColor={borderColor}
            signConvert={signConvert}
            getOp={getOp}
            getFooter={getFooter}
            getArrowColor={getArrowColor}
            selectedCard={selectedCard}
            overviewLoading={overviewLoading}
            setCardList={setCardList}
            setMetricGraphLoading={setMetricGraphLoading}
          />
        </div>

        {GraphMetric(
          selectedCard,
          borderColor,
          getBorderColor,
          overviewData,
          signFind,
          restart,
          revenu_status,
          getArrowColor,
          getFooter,
          showMetrix,
          overviewLoading,
          locationData,
          metricGraphLoading
        )}
      </div>
      <div className="row g-5 g-xl-5  mt-0">
        <div className="col-xxl-6 mt-0 mb-xl-5">
          <div
            className="card card-flush h-xl-100"
            style={{ maxHeight: "400px" }}
          >
            <div className="card-header">
              <div className="card-title">
                <b>Sales Overview</b>
              </div>
            </div>

            <div className="card-body py-9">
              {tabelLoading ? (
                <div
                  style={{
                    height: 200,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Spin />
                </div>
              ) : (
                <Sales360Pie
                  idName="Sales_Overview"
                  data={convertedData?.map((d) => ({
                    category: d?.type
                      ?.split("_")
                      ?.join(" ")
                      .replace(/\b\w/g, (c) => c.toUpperCase()),
                    value: parseFloat(
                      d?.[
                        Object?.values(
                          columnsFilter?.[selectedRange2?.split(" ")?.join("_")]
                        )?.[0]
                      ]
                        ?.split(",")
                        ?.join("")
                    )?.toFixed(0),
                  }))}
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-xl-6 mt-0  mb-xl-5">
          <div
            className="card card-flush h-xl-100"
            style={{ maxHeight: "400px" }}
          >
            <div className="card-header">
              <div className="card-title">
                <b>Paid Sales</b>
              </div>
            </div>
            <div className="card-body py-9">
              {tabelLoading ? (
                <div
                  style={{
                    height: 200,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Spin />
                </div>
              ) : (
                <Sales360Pie
                  idName="Paid_Sales"
                  data={convertedData2?.map((d) => ({
                    category: d?.type
                      ?.split("_")
                      ?.join(" ")
                      .replace(/\b\w/g, (c) => c.toUpperCase()),
                    value: parseFloat(
                      d?.[
                        Object?.values(
                          columnsFilter?.[selectedRange2?.split(" ")?.join("_")]
                        )?.[0]
                      ]
                        ?.split(",")
                        ?.join("")
                    )?.toFixed(0),
                  }))}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="card mt-0">
        <div className="card card-flush h-xl-100">
          <div className="card-header">
            <div className="card-title">
              <b>Sales</b>
            </div>
            <div className="card-toolbar">
              <Tag color="blue-inverse" className="me-2">
                {selectedRange === "custom" ? "Selected period" : selectedRange}
              </Tag>

              <DatePicker.RangePicker
                presets={presetRanges}
                allowClear={false}
                size="middle"
                style={{
                  width: "280px",
                  border: "1px solid #D7D7D7",
                }}
                format={"YYYY-MMM-DD"}
                value={
                  filters?.start_date && filters?.end_date
                    ? [
                        dayjs(filters?.start_date, "YYYY-MM-DD"),
                        dayjs(filters?.end_date, "YYYY-MM-DD"),
                      ]
                    : []
                }
                disabledDate={disabledDate}
                onChange={(e, _) => {
                  const obj = {
                    start_date: e?.[0]
                      ? dayjs(e?.[0]).format("YYYY-MM-DD")
                      : null,
                    end_date: e?.[1]
                      ? dayjs(e?.[1]).format("YYYY-MM-DD")
                      : null,
                  };

                  getList({
                    ...obj,
                    selection_type: handleDateRangeChange(e, _)?.toLowerCase(),
                  });
                  getList2({
                    ...obj,
                    selection_type: handleDateRangeChange2(e, _)?.toLowerCase(),
                  });
                  setFilters(obj);
                  setFilters2(obj);
                  setFiltersLine(obj);
                }}
              />
            </div>
          </div>
          <div className="card-body pt-0">
            {tabelLoading ? (
              <TableLoading
                id="test-table"
                columns={[1, 2, 3, 4, 5]}
                checkBoxVal={true}
                actions={[1, 2]}
              />
            ) : Object?.keys(otherStatic)?.length === 0 ? (
              <Empty />
            ) : (
              <div className="table-responsive">
                <table className=" align-middle table-row-dashed fs-6 gy-3 dataTable no-footer table-fixed">
                  <thead>
                    <tr>
                      <th className="pe-3 py-5 fixed-column" />
                      {Object?.entries(
                        columnsFilter?.[selectedRange?.split(" ")?.join("_")]
                      )?.map(([key, value], i) => {
                        return (
                          <th
                            key={i}
                            style={{
                              fontWeight: 500,
                              fontSize: "14px",
                            }}
                            className={` text-end  pe-3 py-3 ${key}`}
                          >
                            {key?.includes("Pect") ? " " : key}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="p-1" colSpan={9} />
                    </tr>
                    <tr>
                      <td
                        style={{ background: "transparent" }}
                        className="d-flex align-items-center fixed-column justify-content-start"
                      >
                        <h4
                          style={{
                            marginBottom: "0px",
                            color: "#515151",
                            fontWeight: 500,
                          }}
                        >
                          Sales
                        </h4>
                        <InfoVideo
                          data={locationData}
                          title={"sales_amount"}
                          className={"ms-3"}
                        />
                      </td>
                      <td colSpan={9}></td>
                    </tr>
                    <tr>
                      <td className="p-1" colSpan={9} />
                    </tr>
                    {convertedData?.map((d, i) => (
                      <tr key={i}>
                        <td
                          onClick={() => {
                            getAllLineItemsList({
                              metric_name: convertedData?.map((r) => r.type),
                            });

                            setSelectedRow({
                              type: d?.type,
                              columns: "paid",
                              filterType: "source",
                            });
                          }}
                          className="text-end text-hover-primary cursor-pointer d-flex justify-content-end fixed-column "
                          style={{ borderBottom: "1px solid #E5E5E5" }}
                        >
                          <div className="d-flex align-items-center">
                            <span>
                              {d?.type
                                ?.split("_")
                                ?.join(" ")
                                .replace(/\b\w/g, (c) => c.toUpperCase())}
                            </span>
                            <div
                              className="bg-primary ms-2"
                              style={{
                                borderRadius: "100%",
                                postion: "relative",
                                width: "15px",
                                height: "15px",
                              }}
                            >
                              {icons}
                            </div>
                          </div>
                        </td>
                        {Object?.entries(
                          columnsFilter?.[selectedRange?.split(" ")?.join("_")]
                        )?.map(([key, value], i) => {
                          if (ratioView(key)) {
                            return (
                              <td
                                key={i}
                                className="text-end   pe-3"
                                style={{ borderBottom: "none" }}
                              >
                                {d?.[value] == 0 ? "-" : `${d?.[value] || 0}%`}
                              </td>
                            );
                          }
                          return (
                            <td
                              key={i}
                              className="text-end   pe-3"
                              style={{ borderBottom: "1px solid #E5E5E5" }}
                            >
                              {d?.[value] == 0
                                ? "-"
                                : key?.includes("Pect")
                                ? getValueShow(d?.[value] || "-")
                                : signConvert(`$${d?.[value]}`)}
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                    <tr>
                      <td className="p-1" colSpan={9} />
                    </tr>
                    <tr>
                      <td
                        className="text-start fixed-column text-hover-primary cursor-pointer d-flex justify-content-start"
                        style={{
                          background: "#E4F3FF",
                          padding: "5px 10px 5px 10px",
                          color: "#015986",
                          fontSize: "14px",
                          fontWeight: 500,
                        }}
                        onClick={() => {
                          getAllLineItemsList({
                            metric_name: [
                              ...convertedData2?.map((r) => r.type),
                              ...convertedData?.map((r) => r.type),
                            ],
                          });

                          setSelectedRow({
                            type: convertedData?.map((r) => r.type),
                            columns: "paid",
                            filterType: "source",
                          });
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <span>Total Net Sales</span>
                          <div
                            className="bg-primary ms-2"
                            style={{
                              borderRadius: "100%",
                              postion: "relative",
                              width: "15px",
                              height: "15px",
                            }}
                          >
                            {icons}
                          </div>
                        </div>
                      </td>
                      {Object?.entries(
                        columnsFilter?.[selectedRange?.split(" ")?.join("_")]
                      )?.map(([key, value], i) => {
                        if (ratioView(key)) {
                          return (
                            <td
                              key={i}
                              className="text-end   pe-3"
                              style={{
                                background: "#E4F3FF",
                                padding: "5px 10px 5px 10px",
                                color: "#015986",
                                fontWeight: 500,
                              }}
                            ></td>
                          );
                        }
                        return (
                          <td
                            key={i}
                            className="text-end   pe-3"
                            style={{
                              background: "#E4F3FF",
                              padding: "5px 10px 5px 10px",
                              color: "#015986",
                              fontWeight: 500,
                            }}
                          >
                            {otherStatic?.total_net_sales?.[value] == 0
                              ? "-"
                              : key?.includes("Pect")
                              ? getValueShow(
                                  otherStatic?.total_net_sales?.[value] || "0"
                                )
                              : signConvert(
                                  `$${otherStatic?.total_net_sales?.[value]}`
                                )}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <td className="p-1" colSpan={9} />
                    </tr>
                    <tr>
                      <td
                        style={{ background: "transparent" }}
                        className="d-flex align-items-center fixed-column justify-content-start"
                      >
                        <h4
                          style={{
                            marginBottom: "0px",
                            color: "#515151",
                            fontWeight: 500,
                          }}
                        >
                          Paid Sales
                        </h4>
                        <InfoVideo
                          data={locationData}
                          title={"paid_sales_amount"}
                          className={"ms-3"}
                        />
                      </td>
                      <td colSpan={9}></td>
                    </tr>
                    <tr>
                      <td className="p-1" colSpan={9} />
                    </tr>
                    {convertedData2?.map((d, i) => (
                      <tr key={i}>
                        <td
                          onClick={() => {
                            getAllLineItemsList({
                              metric_name: [
                                ...convertedData2?.map((r) => r.type),
                                ...convertedData?.map((r) => r.type),
                              ],
                            });
                            setSelectedRow({
                              type: d?.type,
                              columns: "paid",
                              filterType: "type",
                            });
                          }}
                          className="text-end text-hover-primary cursor-pointer d-flex justify-content-end fixed-column "
                          style={{ borderBottom: "1px solid #E5E5E5" }}
                        >
                          <div className="d-flex align-items-center">
                            <span>
                              {d?.type
                                ?.split("_")
                                ?.join(" ")
                                .replace(/\b\w/g, (c) => c.toUpperCase())}
                            </span>
                            <div
                              className="bg-primary ms-2"
                              style={{
                                borderRadius: "100%",
                                postion: "relative",
                                width: "15px",
                                height: "15px",
                              }}
                            >
                              {icons}
                            </div>
                          </div>
                        </td>
                        {Object?.entries(
                          columnsFilter?.[selectedRange?.split(" ")?.join("_")]
                        )?.map(([key, value], i) => {
                          if (ratioView(key)) {
                            return (
                              <td
                                key={i}
                                className="text-end   pe-3"
                                style={{ borderBottom: "none" }}
                              >
                                {d?.[value] == 0 ? "-" : `${d?.[value] || 0}%`}
                              </td>
                            );
                          }
                          return (
                            <td
                              key={i}
                              className="text-end   pe-3"
                              style={{ borderBottom: "1px solid #E5E5E5" }}
                            >
                              {d?.[value] == 0
                                ? "-"
                                : key?.includes("Pect")
                                ? getValueShow(d?.[value] || "-")
                                : signConvert(`$${d?.[value]}`)}
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                    <tr>
                      <td className="p-1" colSpan={9} />
                    </tr>
                    <tr>
                      <td
                        onClick={() => {
                          getAllLineItemsList({
                            metric_name: [
                              ...convertedData2?.map((r) => r.type),
                              ...convertedData?.map((r) => r.type),
                            ],
                          });
                          setSelectedRow({
                            type: convertedData2?.map((r) => r.type),
                            columns: "paid",
                            filterType: "type",
                          });
                        }}
                        className="text-start fixed-column text-hover-primary cursor-pointer d-flex justify-content-start"
                        style={{
                          background: "#E4F3FF",
                          padding: "5px 10px 5px 10px",
                          color: "#015986",
                          fontSize: "14px",
                          fontWeight: 500,
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <span>Total Paid Sales</span>
                          <div
                            className="bg-primary ms-2"
                            style={{
                              borderRadius: "100%",
                              postion: "relative",
                              width: "15px",
                              height: "15px",
                            }}
                          >
                            {icons}
                          </div>
                        </div>
                      </td>
                      {Object?.entries(
                        columnsFilter?.[selectedRange?.split(" ")?.join("_")]
                      )?.map(([key, value], i) => {
                        if (ratioView(key)) {
                          return (
                            <td
                              key={i}
                              className="text-end   pe-3"
                              style={{
                                background: "#E4F3FF",
                                padding: "5px 10px 5px 10px",
                                color: "#015986",
                                fontWeight: 500,
                              }}
                            ></td>
                          );
                        }
                        return (
                          <td
                            key={i}
                            className="text-end   pe-3"
                            style={{
                              background: "#E4F3FF",
                              padding: "5px 10px 5px 10px",
                              color: "#015986",
                              fontWeight: 500,
                            }}
                          >
                            {otherStatic?.total_paid_sales?.[value] == 0
                              ? "-"
                              : key?.includes("Pect")
                              ? getValueShow(
                                  otherStatic?.total_paid_sales?.[value] || "0"
                                )
                              : signConvert(
                                  `$${otherStatic?.total_paid_sales?.[value]}`
                                )}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <td className="p-1" colSpan={9} />
                    </tr>
                    <tr>
                      <td
                        onClick={() => {
                          getAllLineItemsList({
                            metric_name: "brand_referral_bonus",
                          });
                          setSelectedRow({
                            type: "brand_referral_bonus",
                            columns: "brb",
                            filterType: "campaign_name",
                          });
                        }}
                        className="text-end text-hover-primary cursor-pointer fixed-column d-flex align-items-center justify-content-start "
                        style={{ borderBottom: "1px solid #E5E5E5" }}
                      >
                        <div className="d-flex align-items-center">
                          <span>Brand Referral Bonus</span>
                          <div
                            className="bg-primary ms-2"
                            style={{
                              borderRadius: "100%",
                              postion: "relative",
                              width: "15px",
                              height: "15px",
                            }}
                          >
                            {icons}
                          </div>
                        </div>
                        <InfoVideo
                          data={locationData}
                          title={"brand_referral_bonus"}
                          className={"ms-3"}
                        />
                      </td>
                      {Object?.entries(
                        columnsFilter?.[selectedRange?.split(" ")?.join("_")]
                      )?.map(([key, value], i) => {
                        if (ratioView(key)) {
                          return (
                            <td
                              key={i}
                              className="text-end text-hover-primary cursor-pointer  "
                              style={{ borderBottom: "none" }}
                            ></td>
                          );
                        }
                        return (
                          <td
                            key={i}
                            className="text-end text-hover-primary cursor-pointer  "
                            style={{ borderBottom: "1px solid #E5E5E5" }}
                          >
                            {otherStatic?.brand_referral_bonus?.[value] == 0
                              ? "-"
                              : key?.includes("Pect")
                              ? getValueShow(
                                  otherStatic?.brand_referral_bonus?.[value] ||
                                    "0"
                                )
                              : signConvert(
                                  `$${
                                    otherStatic?.brand_referral_bonus?.[
                                      value
                                    ] || 0
                                  }`
                                )}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <td className="p-1" colSpan={9} />
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row g-5 g-xl-5 mb-5 mb-xl-5 mt-1">
        <div className="col-xxl-6">
          <div className="card card-flush h-xl-100">
            <div className="card-header">
              <div className="card-title">
                <b>Overview of Units Sold</b>
              </div>
            </div>

            <div className="card-body py-9">
              {tabelLoading ? (
                <div
                  style={{
                    height: 200,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Spin />
                </div>
              ) : (
                <Sales360OrderGraph
                  chartID="overview-of"
                  data={convertedData3?.map((d) => ({
                    category: d?.type
                      ?.split("_")
                      ?.join(" ")
                      .replace(/\b\w/g, (c) => c.toUpperCase()),
                    value: parseFloat(
                      d?.[
                        Object?.values(
                          columnsFilter?.[selectedRange2?.split(" ")?.join("_")]
                        )?.[0]
                      ]
                        ?.split(",")
                        ?.join("")
                    )?.toFixed(0),
                  }))}
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="card card-flush h-xl-100">
            <div className="card-header">
              <div className="card-title">
                <b>Paid Campaigns</b>
              </div>
            </div>
            <div className="card-body py-9">
              {tabelLoading ? (
                <div
                  style={{
                    height: 200,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Spin />
                </div>
              ) : (
                <Sales360OrderGraph
                  chartID="paid-campaigns"
                  data={convertedData4?.map((d) => ({
                    category: d?.type
                      ?.split("_")
                      ?.join(" ")
                      .replace(/\b\w/g, (c) => c.toUpperCase()),
                    value: parseFloat(
                      d?.[
                        Object?.values(
                          columnsFilter?.[selectedRange2?.split(" ")?.join("_")]
                        )?.[0]
                      ]
                        ?.split(",")
                        ?.join("")
                    )?.toFixed(0),
                  }))}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card card-flush h-xl-100">
          <div className="card-header">
            <div className="card-title">
              <b>Order Performance</b>
            </div>
            <div className="card-toolbar">
              <Tag color="blue-inverse" className="me-2">
                {selectedRange === "custom" ? "Selected period" : selectedRange}
              </Tag>
              <DatePicker.RangePicker
                presets={presetRanges}
                allowClear={false}
                size="middle"
                style={{
                  width: "280px",
                  border: "1px solid #D7D7D7",
                }}
                format={"YYYY-MMM-DD"}
                value={
                  filters2?.start_date && filters2?.end_date
                    ? [
                        dayjs(filters2?.start_date, "YYYY-MM-DD"),
                        dayjs(filters2?.end_date, "YYYY-MM-DD"),
                      ]
                    : []
                }
                disabledDate={disabledDate}
                onChange={(e, _) => {
                  const obj = {
                    start_date: e?.[0]
                      ? dayjs(e?.[0]).format("YYYY-MM-DD")
                      : null,
                    end_date: e?.[1]
                      ? dayjs(e?.[1]).format("YYYY-MM-DD")
                      : null,
                  };

                  getList({
                    ...obj,
                    selection_type: handleDateRangeChange(e, _)?.toLowerCase(),
                  });
                  getList2({
                    ...obj,
                    selection_type: handleDateRangeChange2(e, _)?.toLowerCase(),
                  });
                  setFilters(obj);
                  setFilters2(obj);
                  setFiltersLine(obj);
                }}
              />
            </div>
          </div>
          <div className="card-body pt-0">
            {tabelLoading2 ? (
              <TableLoading
                id="test-table"
                columns={[1, 2, 3, 4, 5]}
                checkBoxVal={true}
                actions={[1, 2]}
              />
            ) : Object?.keys(otherStatic2)?.length === 0 ? (
              <Empty />
            ) : (
              <div className="table-responsive">
                <table className=" align-middle table-row-dashed fs-6 gy-3 dataTable no-footer table-fixed">
                  <thead>
                    <tr>
                      <th className="  pe-3 py-5 fixed-column" />
                      {Object?.entries(
                        columnsFilter?.[selectedRange2?.split(" ")?.join("_")]
                      )?.map(([key, value], i) => (
                        <th
                          key={i}
                          style={{
                            fontWeight: 500,
                            fontSize: "14px",
                          }}
                          className={`  text-end  pe-3 py-3 ${key}`}
                        >
                          {key?.includes("Pect") ? " " : key}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="p-1" colSpan={9} />
                    </tr>
                    <tr>
                      <td
                        style={{ background: "transparent" }}
                        className="fixed-column"
                      >
                        <h4
                          style={{
                            marginBottom: "0px",
                            color: "#515151",
                            fontWeight: 500,
                          }}
                        >
                          Units Sold
                        </h4>
                      </td>
                      <td colSpan={9}></td>
                    </tr>
                    <tr>
                      <td className="p-1" colSpan={9} />
                    </tr>
                    {convertedData3?.map((d, i) => (
                      <tr key={i}>
                        <td
                          onClick={() => {
                            getAllLineItemsList({
                              metric_name: convertedData3?.map((r) => r.type),
                            });
                            setSelectedRow({
                              type: d?.type,
                              columns: "paid",
                              filterType: "source",
                            });
                          }}
                          className="text-end text-hover-primary cursor-pointer d-flex justify-content-end fixed-column "
                          style={{ borderBottom: "1px solid #E5E5E5" }}
                        >
                          <div className="d-flex align-items-center">
                            <span>
                              {d?.type
                                ?.split("_")
                                ?.join(" ")
                                .replace(/\b\w/g, (c) => c.toUpperCase())}
                            </span>
                            <div
                              className="bg-primary ms-2"
                              style={{
                                borderRadius: "100%",
                                postion: "relative",
                                width: "15px",
                                height: "15px",
                              }}
                            >
                              {icons}
                            </div>
                          </div>
                        </td>

                        {Object?.entries(
                          columnsFilter?.[selectedRange2?.split(" ")?.join("_")]
                        )?.map(([key, value], i) => {
                          if (ratioView(key)) {
                            return (
                              <td
                                key={i}
                                className="text-end   pe-3"
                                style={{ borderBottom: "none" }}
                              >
                                {d?.[value] == 0 ? "-" : `${d?.[value] || 0}%`}
                              </td>
                            );
                          }
                          return (
                            <td
                              key={i}
                              className="text-end   pe-3"
                              style={{ borderBottom: "1px solid #E5E5E5" }}
                            >
                              {d?.[value] == 0
                                ? "-"
                                : key?.includes("Pect")
                                ? getValueShow(d?.[value] || 0 || "0")
                                : signConvert(`${d?.[value] || 0}`)}
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                    <tr>
                      <td className="p-1" colSpan={9} />
                    </tr>
                    <tr>
                      <td
                        className="text-start fixed-column text-hover-primary cursor-pointer d-flex justify-content-start"
                        style={{
                          background: "#E4F3FF",
                          padding: "5px 10px 5px 10px",
                          color: "#015986",
                          fontSize: "14px",
                          fontWeight: 500,
                        }}
                      >
                        <div
                          onClick={() => {
                            getAllLineItemsList({
                              metric_name: convertedData3?.map((r) => r.type),
                            });
                            setSelectedRow({
                              type: convertedData3?.map((r) => r.type),
                              columns: "paid",
                              filterType: "source",
                            });
                          }}
                          className="d-flex align-items-center"
                        >
                          <span>Total Net Sales</span>
                          <div
                            className="bg-primary ms-2"
                            style={{
                              borderRadius: "100%",
                              postion: "relative",
                              width: "15px",
                              height: "15px",
                            }}
                          >
                            {icons}
                          </div>
                        </div>
                      </td>
                      {Object?.entries(
                        columnsFilter?.[selectedRange2?.split(" ")?.join("_")]
                      )?.map(([key, value], i) => {
                        if (ratioView(key)) {
                          return (
                            <td
                              key={i}
                              className="text-end   pe-3"
                              style={{
                                background: "#E4F3FF",
                                padding: "5px 10px 5px 10px",
                                color: "#015986",
                                fontWeight: 500,
                              }}
                            ></td>
                          );
                        }
                        return (
                          <td
                            key={i}
                            className="text-end   pe-3"
                            style={{
                              background: "#E4F3FF",
                              padding: "5px 10px 5px 10px",
                              color: "#015986",
                              fontWeight: 500,
                            }}
                          >
                            {otherStatic2?.total_net_sales?.[value] == 0
                              ? "-"
                              : key?.includes("Pect")
                              ? getValueShow(
                                  otherStatic2?.total_net_sales?.[value] || 0
                                )
                              : signConvert(
                                  `${
                                    otherStatic2?.total_net_sales?.[value] || 0
                                  }`
                                )}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <td className="p-1" colSpan={9} />
                    </tr>
                    <tr>
                      <td
                        style={{ background: "transparent" }}
                        className="fixed-column"
                      >
                        <h4
                          style={{
                            marginBottom: "0px",
                            color: "#515151",
                            fontWeight: 500,
                          }}
                        >
                          Paid Sales
                        </h4>
                      </td>
                      <td colSpan={9}></td>
                    </tr>
                    <tr>
                      <td className="p-1" colSpan={9} />
                    </tr>
                    {convertedData4?.map((d, i) => (
                      <tr key={i}>
                        <td
                          onClick={() => {
                            getAllLineItemsList({
                              metric_name: [
                                ...convertedData4?.map((r) => r.type),
                                ...convertedData3?.map((r) => r.type),
                              ],
                            });
                            setSelectedRow({
                              type: d?.type,
                              columns: "paid",
                              filterType: "type",
                            });
                          }}
                          className="text-end text-hover-primary cursor-pointer d-flex justify-content-end fixed-column "
                          style={{ borderBottom: "1px solid #E5E5E5" }}
                        >
                          <div className="d-flex align-items-center">
                            <span>
                              {d?.type
                                ?.split("_")
                                ?.join(" ")
                                .replace(/\b\w/g, (c) => c.toUpperCase())}
                            </span>
                            <div
                              className="bg-primary ms-2"
                              style={{
                                borderRadius: "100%",
                                postion: "relative",
                                width: "15px",
                                height: "15px",
                              }}
                            >
                              {icons}
                            </div>
                          </div>
                        </td>
                        {Object?.entries(
                          columnsFilter?.[selectedRange2?.split(" ")?.join("_")]
                        )?.map(([key, value], i) => {
                          if (ratioView(key)) {
                            return (
                              <td
                                key={i}
                                className="text-end   pe-3"
                                style={{ borderBottom: "none" }}
                              >
                                {d?.[value] == 0 ? "-" : `${d?.[value] || 0}%`}
                              </td>
                            );
                          }
                          return (
                            <td
                              key={i}
                              className="text-end   pe-3"
                              style={{ borderBottom: "1px solid #E5E5E5" }}
                            >
                              {d?.[value] == 0
                                ? "-"
                                : key?.includes("Pect")
                                ? getValueShow(d?.[value] || 0)
                                : signConvert(`${d?.[value] || 0}`)}
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                    <tr>
                      <td className="p-1" colSpan={9} />
                    </tr>
                    <tr>
                      <td
                        className="text-start fixed-column text-hover-primary cursor-pointer d-flex justify-content-start"
                        style={{
                          background: "#E4F3FF",
                          padding: "5px 10px 5px 10px",
                          color: "#015986",
                          fontSize: "14px",
                          fontWeight: 500,
                        }}
                      >
                        <div
                          onClick={() => {
                            getAllLineItemsList({
                              metric_name: [
                                ...convertedData4?.map((r) => r.type),
                                ...convertedData3?.map((r) => r.type),
                              ],
                            });
                            setSelectedRow({
                              type: convertedData4?.map((r) => r.type),
                              columns: "paid",
                              filterType: "type",
                            });
                          }}
                          className="d-flex align-items-center"
                        >
                          <span>Total Paid Sales</span>
                          <div
                            className="bg-primary ms-2"
                            style={{
                              borderRadius: "100%",
                              postion: "relative",
                              width: "15px",
                              height: "15px",
                            }}
                          >
                            {icons}
                          </div>
                        </div>
                      </td>
                      {Object?.entries(
                        columnsFilter?.[selectedRange2?.split(" ")?.join("_")]
                      )?.map(([key, value], i) => {
                        if (ratioView(key)) {
                          return (
                            <td
                              key={i}
                              className="text-end   pe-3"
                              style={{
                                background: "#E4F3FF",
                                padding: "5px 10px 5px 10px",
                                color: "#015986",
                                fontWeight: 500,
                              }}
                            ></td>
                          );
                        }
                        return (
                          <td
                            key={i}
                            className="text-end   pe-3"
                            style={{
                              background: "#E4F3FF",
                              padding: "5px 10px 5px 10px",
                              color: "#015986",
                              fontWeight: 500,
                            }}
                          >
                            {otherStatic2?.total_paid_sales?.[value] == 0
                              ? "-"
                              : key?.includes("Pect")
                              ? getValueShow(
                                  otherStatic2?.total_paid_sales?.[value] || 0
                                )
                              : signConvert(
                                  `${
                                    otherStatic2?.total_paid_sales?.[value] || 0
                                  }`
                                )}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <td className="p-1" colSpan={9} />
                    </tr>
                    <tr>
                      <td
                        style={{ background: "transparent" }}
                        className="fixed-column"
                      >
                        <h4
                          style={{
                            marginBottom: "0px",
                            color: "#515151",
                            fontWeight: 500,
                          }}
                        >
                          Orders
                        </h4>
                      </td>
                      <td colSpan={9}></td>
                    </tr>
                    <tr>
                      <td className="p-1" colSpan={9} />
                    </tr>
                    {convertedData5?.map((d, i) => (
                      <tr key={i}>
                        <td
                          onClick={() => {
                            getAllLineItemsList({
                              metric_name: convertedData5?.map((r) => r.type),
                            });
                            setSelectedRow({
                              type: d?.type,
                              columns: "order",
                              filterType: "format_order_date",
                            });
                          }}
                          className="text-end text-hover-primary cursor-pointer d-flex justify-content-end fixed-column "
                          style={{ borderBottom: "1px solid #E5E5E5" }}
                        >
                          <div className="d-flex align-items-center">
                            <span>
                              {d?.type === "unit_sold"
                                ? "Units Sold"
                                : d?.type === "aov"
                                ? "AOV"
                                : d?.type === "aiv"
                                ? "AIV"
                                : d?.type
                                    ?.split("_")
                                    ?.join(" ")
                                    .replace(/\b\w/g, (c) => c.toUpperCase())}
                            </span>
                            <div
                              className="bg-primary ms-2"
                              style={{
                                borderRadius: "100%",
                                postion: "relative",
                                width: "15px",
                                height: "15px",
                              }}
                            >
                              {icons}
                            </div>
                          </div>
                        </td>
                        {Object?.entries(
                          columnsFilter?.[selectedRange2?.split(" ")?.join("_")]
                        )?.map(([key, value], i) => {
                          if (ratioView(key)) {
                            return (
                              <td
                                key={i}
                                className="text-end   pe-3"
                                style={{ borderBottom: "none" }}
                              ></td>
                            );
                          }
                          return (
                            <td
                              key={i}
                              className="text-end   pe-3"
                              style={{ borderBottom: "1px solid #E5E5E5" }}
                            >
                              {d?.[value] == 0
                                ? "-"
                                : key?.includes("Pect")
                                ? getValueShow(d?.[value] || "0")
                                : signConvert(
                                    `${
                                      "order_count" === d?.type ||
                                      "unit_sold" === d?.type
                                        ? ""
                                        : "$"
                                    }${d?.[value] || 0}`
                                  )}
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                    <tr>
                      <td className="p-1" colSpan={9} />
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="card mt-5">
        <div
          className="card-body"
          style={{
            filter: graphLoading ? "blur(5px)" : "blur(0)",
            zoom: "110%",
          }}
        >
          {/* lastGraph */}
          <div className="d-flex">
            <div
              className="w-250px"
              style={{ borderRight: "1px dashed #dbdfe9" }}
            >
              <div
                className="mt-5 d-grid"
                style={{
                  overflow: "auto",
                  maxHeight: "309px",
                }}
              >
                <Checkbox.Group
                  onChange={(e) => {
                    setSelectedGraph(e);
                  }}
                  style={{ width: "300px", zoom: "90%" }}
                  className="d-grid"
                  value={selectedGraph}
                >
                  {lastGraph?.series?.map((d, i) => (
                    <Checkbox
                      className={
                        colorList?.find((f) => f?.key === d?.color)?.value
                      }
                      value={d?.name}
                    >
                      <div
                        className="apexcharts-tooltip-series-group apexcharts-active"
                        style={{ display: "flex" }}
                        key={i}
                      >
                        <div
                          className="apexcharts-tooltip-text"
                          style={{
                            fontFamily: "'Montserrat' sans-serif",
                            fontSize: 12,
                          }}
                        >
                          <div className="apexcharts-tooltip-y-group">
                            <span
                              style={{
                                textTransform: "capitalize",
                                color: "#656565",
                              }}
                              className="apexcharts-tooltip-text-y-label"
                            >
                              {graphList?.[d?.name]}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Checkbox>
                  ))}
                </Checkbox.Group>
              </div>
            </div>

            <div style={{ width: "calc(100vw - 300px)", zoom: "90%" }}>
              <StackedBarLineChart
                colorList={colorList}
                updated_data={graphData}
                series={lastGraph?.series}
                selectedGraph={selectedGraph}
              />
            </div>
          </div>
        </div>
      </div>
      {viewConfig && (
        <Config
          metricSettingLoading={metricSettingLoading}
          metricSettingList={metricSettingList}
          onClose={() => setViewConfig(false)}
          onSave={saveMetricSetting}
          open={viewConfig}
          data={[]}
        />
      )}
      <PlayView visible={visible} setVisible={setVisible} />
    </Wrapper>
  );
};

export default Sales360;
