import React, { useEffect, useState } from "react";
import { Wrapper } from "./style";
import { Popconfirm, Table, Tag, message } from "antd";
import {
  DeleteIcon,
  EditIcon,
  PropsFilter,
  RenderTable,
} from "../../../../config";
import Pagination from "../../../../components/pagination";
import { TableLoading } from "../../../../components/table-animation";
import AddData from "./lib";
import { MakeApiCall } from "../../../../apis";
import moment from "moment";

export default function MetricsMenuItemMapping() {
  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);

  const [sortFilters, setSortFilters] = useState({
    field_name: null,
    sort_by: null,
  });

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);

  const [addModal, setAddModal] = useState(false);
  const [modalType, setModalType] = useState("Add");

  const [selectedRow, setSelectedRow] = useState({});

  // const sortType = {
  //   descend: "desc",
  //   ascend: "asc",
  // };
  // const DefaultSortType = {
  //   desc: "descend",
  //   asc: "ascend",
  // };
  // const PropsFilter = (type) => {
  //   return {
  //     defaultSortOrder:
  //       type === sortFilters?.field_name
  //         ? DefaultSortType?.[sortFilters?.sort_by]
  //         : [],
  //     sorter: () => {},
  //   };
  // };
  // const handleChange = (_, __, sorter) => {
  //   const obj = {
  //     field_name: sortType?.[sorter?.order] ? sorter?.column?.filterIndex : "",
  //     sort_by: sortType?.[sorter?.order] || "",
  //   };
  //   setSortFilters(obj);
  //   getList({ ...obj });
  // };

  const getList = async (data) => {
    setLoading(true);
    const response = await MakeApiCall(
      `metric-menu-item?isAll=1`,
      "get",
      null,
      true
    );
    // const response = await MakeApiCall(
    //   `metric-menu-item?page=${data?.page || page || 1}&per-page=${
    //     data?.pageSize || pageSize
    //   }&field_name=${data?.field_name || ""}&sort_by=${data?.sort_by || ""}`,
    //   "get",
    //   null,
    //   true
    // );

    if (response?.status) {
      // setTotalPage(response?.data?.pagination?.totalCount || 0);
      setList(
        response?.data?.records?.map((d) => ({
          ...d,
          metric_name: d?.metricData?.metric_name,
          metric_group: d?.metricData?.metric_group,
        }))
      );
      setLoading(false);
    } else {
      setTotalPage(0);
      setLoading(false);
      message.warning(response?.message);
    }
  };

  const deleteAction = async (id) => {
    const response = await MakeApiCall(
      `metric-menu-item/${id}`,
      "delete",
      null,
      true
    );

    if (response?.status) {
      message.destroy();
      message.success(response?.message);
      setLoading(true);
      setPage(1);
      getList({ page: 1 });
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    getList();
    return () => {};
  }, []);

  const columns = [
    {
      title: "Metric",
      dataIndex: "metric_name",
      filterIndex: "metric_name",
      key: "metric_name",
    },
    {
      title: "Menu Item",
      filterIndex: "menu_item",
      dataIndex: "menu_item",
      key: "menu_item",
    },
    {
      title: "Metric Group",
      filterIndex: "metric_group",
      dataIndex: "metric_group",
      key: "metric_group",
    },
    {
      title: "Created At",
      filterIndex: "created_at",
      render: (d) => {
        return (
          <div>
            {moment(new Date(parseInt(d.created_at) * 1000)).format(
              "MM/DD/YYYY hh:mm A"
            )}
          </div>
        );
      },
    },

    {
      title: "Updated At",
      filterIndex: "updated_at",
      render: (d) => {
        return (
          <div>
            {moment(new Date(parseInt(d.updated_at) * 1000)).format(
              "MM/DD/YYYY hh:mm A"
            )}
          </div>
        );
      },
    },
    {
      title: "Action",
      align: "center",
      render: (text, record) => (
        <span>
          <a
            onClick={() => {
              setAddModal(true);
              setModalType("Update");
              setSelectedRow(record);
            }}
            className="me-2"
          >
            {EditIcon}
          </a>
          {/* <Popconfirm
            title="Delete this Metric Menu Item Mapping"
            description={`Are you sure to delete this ${text?.metricData?.metric_name}?`}
            onConfirm={() => {
              message.destroy();
              message.loading("Loading...", 0);
              deleteAction(text?.id);
            }}
            placement="left"
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <a href="#" className="me-2">
              {DeleteIcon}
            </a>
          </Popconfirm> */}
          {/* <a
              href="#"
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
            >
              <i className="ki-outline ki-row-vertical fs-3 text-dark" />
            </a> */}
        </span>
      ),
    },
  ];

  return (
    <Wrapper
      id="kt_content_container"
      className="custom-ui container-fluid mt-5"
    >
      <div className="card">
        <div className="card-header">
          <div className="card-title"></div>
          <div className="card-toolbar">
            <a
              onClick={() => {
                setAddModal(true);
                setModalType("Add");
              }}
              className="add-btn "
            >
              <svg
                width={16}
                height={16}
                className="me-2"
                fill="#ffffff"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M15 2.016H9v6.987H2v6h7v6.987h6v-6.987h7v-6h-7V2.016Z" />
              </svg>
              Add Mapping
            </a>
          </div>
        </div>
        <div className="card-body pt-0 pb-0">
          {loading ? (
            <TableLoading
              id="test-table"
              columns={[1, 2, 3, 4, 5]}
              checkBoxVal={true}
              actions={[1, 2]}
            />
          ) : (
            <div className="">
              <Table
                // onChange={handleChange}
                fixed={true}
                sticky={{
                  offsetHeader: "0px",
                }}
                columns={columns?.map((d) => ({
                  ...d,
                  ...(d?.filterIndex ? PropsFilter(d?.filterIndex) : ""),
                  render: (props, row, index) =>
                    RenderTable(props, row, index, d),
                }))}
                dataSource={list}
                rowClassName={(record, index) => {
                  return index % 2 === 0 ? "even-row" : "odd-row";
                }}
                bordered
                size="small"
                pagination={{
                  size: "default",
                  showSizeChanger: true,
                }}
                scroll={{ x: "max-content" }}
              />
            </div>
          )}
        </div>
        <div className="card-footer d-none pt-0 pb-5">
          <Pagination
            loading={loading}
            pageSize={pageSize}
            page={page}
            totalPage={totalPage}
            onPerPage={(e) => {
              setLoading(true);
              setPageSize(e);
              setPage(1);
              getList({
                page: 1,
                pageSize: e,
                ...sortFilters,
              });
            }}
            onPageNo={(e) => {
              setLoading(true);
              setPage(e);
              getList({
                page: e,
                ...sortFilters,
              });
            }}
          />
        </div>
      </div>
      {addModal && (
        <AddData
          visible={addModal}
          selectedRow={selectedRow}
          modalType={modalType}
          onClose={() => {
            setSelectedRow({});
            setAddModal(false);
          }}
          callAPI={() => {
            setPage(1);
            getList({ page: 1 });
          }}
        />
      )}
    </Wrapper>
  );
}
