import { DatePicker, Empty, Input, Select, Table, Tag, message } from "antd";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../../apis";
import InfoVideo from "../../../../components/header-icons";
import Pagination from "../../../../components/pagination";
import { TableLoading } from "../../../../components/table-animation";
import ExportFile from "./export-file";
import { ErrorModal } from "../../../../components/upload-modals";
import { RenderTable } from "../../../../config";
dayjs.extend(quarterOfYear);
const Orders = ({ locationData }) => {
  const [list, setList] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [loading, setLoading] = useState(true);

  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [page, setPage] = useState(1);
  const [sortFilters, setSortFilters] = useState({
    field_name: "purchase_date",
    sort_by: "desc",
  });
  const [filters, setFilters] = useState({
    search_key: null,
    status: null,

    start_date: dayjs().startOf("month").format("YYYY-MM-DD"),
    end_date: dayjs().endOf("month").format("YYYY-MM-DD"),
  });

  const CommaAndFloat = (e) => {
    return (e || 0).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };
  const Comma = (e) => {
    return (e || 0).toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  const presetRanges_ = [
    {
      label: "Yesterday",
      value: [dayjs().subtract(1, "day"), dayjs().subtract(1, "day")],
    },
    { label: "This Week", value: [dayjs().startOf("week"), dayjs()] },
    {
      label: "This Month",
      value: [dayjs().startOf("month"), dayjs().endOf("month")],
    },
    {
      label: "Last Month",
      value: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
    },
    {
      label: "This Quarter",
      value: [dayjs().startOf("quarter"), dayjs().endOf("quarter")],
    },
    {
      label: "This Year",
      value: [dayjs().startOf("year"), dayjs().endOf("year")],
    },
    {
      label: "Last Year",
      value: [
        dayjs().subtract(1, "year").startOf("year"),
        dayjs().subtract(1, "year").endOf("year"),
      ],
    },
  ];
  const presetRanges = presetRanges_.map((range) => {
    const start = range.value[0];
    let end = range.value[1];

    // Check if the end date is in the future
    if (end.isAfter(dayjs())) {
      end = dayjs(); // Replace with the current date
    }

    return {
      ...range,
      value: [start, end],
    };
  });
  const columns = [
    {
      title: "ID",
      align: "center",
      width: 150,
      render: (_, __, i) => {
        return <span>{(page - 1) * pageSize + 1 + i}</span>;
      },
    },
    {
      title: "Purchase Date",
      dataIndex: "purchase_date",

      width: 180,
      key: "purchase_date",
      render: (e) => {
        return moment(new Date(e)).format("YYYY-MM-DD");
      },
    },
    {
      title: "Amazon Order ID",
      dataIndex: "amazon_order_id",
      key: "amazon_order_id",
      width: 190,
      render: (e) => {
        if (!e) {
          return "-";
        }
        return e;
      },
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
      width: 240,
      render: (e) => {
        if (!e) {
          return "-";
        }
        return <Tag color="blue">{e}</Tag>;
      },
    },
    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
      width: 160,
      render: (e) => {
        if (!e) {
          return "-";
        }
        return <Tag color="cyan">{e}</Tag>;
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: 110,
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Currency",
      dataIndex: "currency",
      width: 120,
      key: "currency",
      render: (e) => {
        if (!e) {
          return "-";
        }
        return e;
      },
    },
    {
      title: "Item Price",
      dataIndex: "item_price",
      key: "item_price",
      width: 125,
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Fulfillment Channel",
      dataIndex: "fulfillment_channel",
      width: 190,
      key: "fulfillment_channel",
      render: (e) => {
        if (!e) {
          return "-";
        }
        return e;
      },
    },
    {
      title: "Ship Service Level",
      dataIndex: "ship_service_level",
      width: 180,
      key: "ship_service_level",
      render: (e) => {
        if (!e) {
          return "-";
        }
        return e;
      },
    },
    {
      title: "Item Status",
      dataIndex: "item_status",
      key: "item_status",
      width: 140,
      render: (e) => {
        if (!e) {
          return "-";
        }
        return e;
      },
    },
    {
      title: "Order Channel",
      dataIndex: "order_channel",
      key: "order_channel",
      width: 190,
      render: (e) => {
        if (!e) {
          return "-";
        }
        return e;
      },
    },
    {
      title: "URL",
      dataIndex: "url",

      width: 100,
      key: "url",
      render: (e) => {
        if (!e) {
          return "-";
        }
        return e;
      },
    },
    {
      title: "Item Tax",
      dataIndex: "item_tax",
      key: "item_tax",
      width: 110,
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Shipping Price",
      dataIndex: "shipping_price",
      key: "shipping_price",
      width: 160,
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Shipping Tax",
      dataIndex: "shipping_tax",
      key: "shipping_tax",
      width: 160,
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Gift Wrap Price",
      dataIndex: "gift_wrap_price",
      key: "gift_wrap_price",
      width: 160,
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Gift Wrap Tax",
      dataIndex: "gift_wrap_tax",
      key: "gift_wrap_tax",
      width: 160,
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Item Promotion Discount",
      dataIndex: "item_promotion_discount",
      key: "item_promotion_discount",
      width: 230,
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Ship Promotion Discount",
      dataIndex: "ship_promotion_discount",
      key: "ship_promotion_discount",
      width: 230,
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Ship City",
      dataIndex: "ship_city",
      key: "ship_city",
      width: 180,
      render: (e) => {
        if (!e) {
          return "-";
        }
        return e;
      },
    },
    {
      title: "Ship State",
      dataIndex: "ship_state",
      key: "ship_state",
      width: 120,
      render: (e) => {
        if (!e) {
          return "-";
        }
        return e;
      },
    },
    {
      title: "Ship Postal Code",
      dataIndex: "ship_postal_code",
      key: "ship_postal_code",
      width: 170,
      render: (e) => {
        if (!e) {
          return "-";
        }
        return e;
      },
    },
    {
      title: "Ship Country",
      dataIndex: "ship_country",
      key: "ship_country",
      width: 140,
      render: (e) => {
        if (!e) {
          return "-";
        }
        return e;
      },
    },
    {
      title: "Promotion IDS",
      dataIndex: "promotion_ids",
      width: 400,
      key: "promotion_ids",
      render: (e) => {
        if (!e) {
          return "-";
        }
        return e;
      },
    },
    {
      title: "Is Business Order",
      dataIndex: "is_business_order",
      width: 170,
      key: "is_business_order",
      render: (e) => {
        if (!e) {
          return "-";
        }
        return e;
      },
    },
    {
      title: "Purchase Order Number",
      dataIndex: "purchase_order_number",
      width: 250,
      key: "purchase_order_number",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Price Designation",
      dataIndex: "price_designation",
      key: "price_designation",
      width: 190,
      render: (e) => {
        if (!e) {
          return "-";
        }
        return e;
      },
    },
  ];

  const getList = async (data) => {
    setTableLoading(true);
    const response = await MakeApiCall(
      `report/orders?page=${data?.page || page || 1}&per-page=${
        data?.pageSize || pageSize
      }&field_name=${data?.field_name || ""}&sort_by=${
        data?.sort_by || ""
      }&is_export=0&search_key=${data?.search_key || ""}&status=${
        data?.status || ""
      }&start_date=${data?.start_date || ""}&end_date=${data?.end_date || ""}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setTableLoading(false);
      setList(response?.data?.records || []);
      setTotalPage(response?.data?.pagination?.totalCount || 0);
      setLoading(false);
    } else {
      setTableLoading(false);
      setLoading(false);
      setTotalPage(0);
      ErrorModal(response?.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    getList({ ...filters });
    return () => {};
  }, []);

  const getRowClassName = (record, index) => {
    return index % 2 === 0 ? "even-row" : "odd-row";
  };

  const sortType = {
    descend: "desc",
    ascend: "asc",
  };
  const DefaultSortType = {
    desc: "descend",
    asc: "ascend",
  };
  const PropsFilter = (type) => ({
    defaultSortOrder:
      type === sortFilters?.field_name
        ? DefaultSortType?.[sortFilters?.sort_by]
        : [],
    sorter: () => {},
  });
  const handleChange = (_, __, sorter) => {
    const obj = {
      field_name: sortType?.[sorter?.order] ? sorter?.columnKey : "",
      sort_by: sortType?.[sorter?.order] || "",
    };
    setSortFilters(obj);
    getList({ ...obj, ...filters });
  };
  return (
    <div className="card custom-ui">
      <div className="card-header">
        <div className="card-title">
          Orders
          <InfoVideo data={locationData} title={"orders"} className={"ms-3"} />
          <Input
            placeholder="Search by asin, sku"
            onPressEnter={() => {
              getList({
                ...filters,
                ...sortFilters,
              });
            }}
            value={filters?.search_key}
            allowClear
            onChange={(e) => {
              setFilters((prev) => {
                if (!e.target.value && prev) {
                  getList({
                    ...{
                      ...prev,
                      search_key: e.target.value,
                    },
                    ...sortFilters,
                  });
                }
                return {
                  ...prev,
                  search_key: e.target.value,
                };
              });
            }}
            className="w-175px ms-3"
          />
          <button
            onClick={() => {
              getList({
                ...filters,
                ...sortFilters,
              });
            }}
            className="btn btn-sm ms-3 btn-search"
            style={{ padding: "5px 15px" }}
          >
            <svg
              width={15}
              height={15}
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeWidth={2}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m21 21-2.243-2.247-2.243-2.247" />
              <path d="M19 10.5a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0Z" />
            </svg>
          </button>
          <Select
            placeholder="Order Status"
            allowClear={true}
            className="w-150px ms-3"
            value={filters?.status}
            onChange={(e) => {
              setFilters({
                ...filters,
                status: e,
              });
              getList({
                ...filters,
                status: e,
              });
            }}
            options={[
              {
                label: "Cancelled",
                value: "Cancelled",
              },
              {
                label: "Pending",
                value: "Pending",
              },
              {
                label: "Shipped",
                value: "Shipped",
              },
              {
                label: "Shipping",
                value: "Shipping",
              },
            ]}
          />
        </div>

        <div className="card-toolbar">
          <DatePicker.RangePicker
            // allowClear
            presets={presetRanges}
            value={
              filters?.start_date && filters?.end_date
                ? [
                    dayjs(filters?.start_date, "YYYY-MM-DD"),
                    dayjs(filters?.end_date, "YYYY-MM-DD"),
                  ]
                : []
            }
            onChange={(e) => {
              const obj = {
                ...filters,
                start_date: dayjs(e?.[0])?.format("YYYY-MM-DD"),
                end_date: dayjs(e?.[1])?.format("YYYY-MM-DD"),
              };
              getList({ ...obj, ...sortFilters });
              setFilters(obj);
            }}
            className="w-250px me-3"
            disabledDate={(current) => {
              return current && current > moment().endOf("day");
            }}
          />
          <ExportFile
            title="Orders"
            hideTooltip={filters?.start_date && filters?.end_date}
            api={`report/orders?start_date=${
              filters?.start_date || ""
            }&end_date=${filters?.end_date || ""}&is_export=1&field_name=${
              sortFilters?.field_name || ""
            }&sort_by=${sortFilters?.sort_by || ""}&search_key=${
              filters?.search_key || ""
            }&status=${filters?.status || ""}`}
          />
        </div>
      </div>
      <div className="card-body  pt-0">
        {loading ? (
          <TableLoading
            id="test-table"
            columns={[1, 2, 3, 4, 5]}
            checkBoxVal={true}
            actions={[1, 2]}
          />
        ) : list?.length === 0 ? (
          <Empty />
        ) : (
          <div className="">
            <Table
              dataSource={list}
              loading={tableLoading}
              fixed={true}
              sticky={{
                offsetHeader: "0px",
              }}
              scroll={{ x: "max-content" }}
              columns={columns?.map((d) => ({
                ...d,
                ...PropsFilter(d?.dataIndex),
                render: (props, row, index) =>
                  RenderTable(props, row, index, d),
              }))}
              pagination={false}
              onChange={handleChange}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "even-row" : "odd-row";
              }}
              bordered
              size="small"
            />
          </div>
        )}

        <Pagination
          loading={loading}
          pageSize={pageSize}
          page={page}
          totalPage={totalPage}
          onPerPage={(e) => {
            setLoading(true);
            setPageSize(e);
            setPage(1);
            getList({
              page: 1,
              pageSize: e,
              ...sortFilters,
              ...filters,
            });
          }}
          onPageNo={(e) => {
            setLoading(true);
            setPage(e);
            getList({
              page: e,
              ...sortFilters,
              ...filters,
            });
          }}
        />
      </div>
    </div>
  );
};

export default Orders;
